import Cover from "../../component/Cover"
import TextInput from "../../component/TextInput"

import { Button } from "@mui/material"
import { changeCurrentUserPw } from "../../newApiClient"
import { formEvtToJSON } from "../../util"
import { confirmDialogAlert } from "../../component/temp/ConfirmDialog"
import unwrapErrorFromApi from "../../component/UnwrapErrorApi"

export default function PWChange() {
  const changePW = async evt => {
    evt.preventDefault()

    const data = formEvtToJSON(evt)

      if (data?.new_password !== data?.new_password_2) {
      await confirmDialogAlert("New passwords do not match")
      return false
    }

    delete data.new_password_2
    changeCurrentUserPw(data)
      .then(async () => {
        await confirmDialogAlert("Password Changed.")

      })
      .catch(unwrapErrorFromApi)

  }
  return <>

      <h3 className={"noTopPad"}>Change Own Password</h3>
      <Cover className={"shortInput"}>
          <form onSubmit={changePW}>
              <TextInput
          name={"old_password"}
          type={"password"}
          className="shortInput" label={"current password"} />
              <br />
              <br />
              <TextInput
          name={"new_password"}
          type={"password"}
          className="shortInput" label={"new password"} />
              <br />
              <br />
              <TextInput
          name={"new_password_2"}
          type={"password"}
          minLength={8}
          className="shortInput" label={"new password again"} />
              <br />
              <br />
              <Button className={"formSubmit action"} color={"primary"} variant={"outlined"} type={"submit"}>
                  Change Password
              </Button>
          </form>
      </Cover>
      <br /><br />
  </>
}
