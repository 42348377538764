import React from 'react'
import SingleTaskView from "./ui/SingleTaskView";
import {createStore} from "./store";
import MockApiClient from "./MockApiClient";
import Constant from "./constant";
import FormRoot from "./form/FormRoot";

require('multi_task_runner/src/styles/style-override.css');
require('multi_task_runner/src/styles/tab-style.css');
require('multi_task_runner/src/styles/double-dialog.css');

const SINGLE = "SINGLE";
/**
 * Entrypoint for Multi Task Runner UI Library.
 * This Component is for standalone task rendering.
 * @param {boolean} standalone
 * @param {object} schema
 * @param {object} formWidget
 * @param {object} [initialState]
 * @param {function} onChange
 * @param {function} onSubmit
 */
export default class SingleTaskViewRoot extends React.Component {
  constructor(props) {
    super(props);
    this.passProps = props;
    const store = createStore();


    Constant.FormWidgets = {
      ...Constant.FormWidgets,
      SINGLE: (props.FormWidgets || {})
    }

    Constant.ModularText = {
      ...Constant.ModularText,
      ...(props.ModularText || {})
    }

    console.log('ROOT:', Constant.FormWidgets, props.FormWidgets, store);
    store.alertTypes = [
      {
        type: SINGLE,
        category: "hide",
      }
    ]

    store.cameras = [];

    store.schemas = {
      [SINGLE]: props.schema
    }

    store.disableInputChoice = this.props.disableInputChoice || false;

    this.passProps = {
      ...this.passProps,
      store,
      mtrApiClient: this.props.MtrApiClientInstance || (new MockApiClient(props.api || {}))
    }
  }


  render() {
    return <SingleTaskView
      alertTypeId={SINGLE}

      {...this.passProps}
      forceValidate={this.props.forceValidate}
    />
  }
}
