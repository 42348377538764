import React, {useEffect} from "react";
import {FormControl, Select, InputLabel, FormLabel, FormControlLabel, Radio, FormHelperText, RadioGroup} from '@mui/material';
import formDataStore from "../FormDataStore";
import Dispatcher from "../../dispatcher";
import Constant from "../../constant";

export default function EnumSelectRadioButton({isRequired, id, propertyElement, path, value, onChange = () => {}}) {
  const [selected, setSelected] = React.useState(value || formDataStore.getByPath(path));
  const [errorMessage, setErrorMessage] = React.useState(null)

  useEffect(() => {
    const dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED || payload.contentType === Constant.FLUX.FORM_DATA_CHANGED) {
        const newVal = formDataStore.getByPath(path);
        if (newVal) {
          setSelected(newVal);
        }
      }
    })

    return () => {
      Dispatcher.unregister(dispatcher);
    };
  }, [])

  useEffect(() => {
    setSelected(value);
  }, [value])

  const onChange0 = value => {
    setSelected(value);
    onChange(value, false);

    try {
      setErrorMessage(null);
      formDataStore.removeError(path);
      formDataStore.saveToPath(path, value);
    } catch (err) {
      formDataStore.addError(path, err.message)
      setErrorMessage(err.message);
    }
  }

  return (
    <React.Fragment>
      <div className={"enumSelector-root"}>
        <FormControl component="fieldset" fullWidth error={errorMessage}>
          <FormLabel>Select 1 item.</FormLabel>

          <RadioGroup value={selected}>
            <div className={"flex-container"}>
              {
                propertyElement.enum.map((el, idx) =>
                  <div className={"flex flexSelector"} key={idx}>
                    <FormControlLabel
                      control={<Radio value={el} color={"primary"} onChange={() => onChange0(el)}
                                         name={el}/>}
                      label={propertyElement.label ? propertyElement.label[idx] : el}
                    />
                  </div>
                )
              }
            </div>
          </RadioGroup>

          {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}

        </FormControl>
      </div>

    </React.Fragment>
  )
}
