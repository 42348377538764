import Dispatcher from "../dispatcher";
import Constant from "../constant";
import Util from "../util";

class FormDataStore {
  #data = {};
  #error = {};
  #alertType = {};
  displayName = undefined;

  constructor(data) {
    this.reset();
    if (data) {
      this.#data = data;
    }
  }

  reset() {
    this.#data = {};
    this.#error = [];
    this.#alertType = {};
    this.displayName = undefined;
  }

  set data(data) {
    if (!Util.isObjectEqual(data, this.#data)) {
      this.#data = data;
      Dispatcher.dispatch({contentType: Constant.FLUX.FORM_DATA_UPLOADED});
    }
  }

  get data() {
    return this.#data;
  }

  set alertType(newSchema) {
    this.#alertType = newSchema
  };

  get alertType() {
    return this.#alertType
  };

  getByPath(path) {
    if (!path) return null;

    let current = this.#data;

    for (let i = 0; i < path.length; ++i) {
      if (current[path[i]] === undefined || current[path[i]] === null) {
        return undefined;
      } else {
        current = current[path[i]];
      }
    }
    return current;
  }

  /**
   * When there is no value, but there is a default value; use the default value.
   * @attention when the value can be 'false-y', (0,"",[""]) this function cannot be used
   * @todo: create a global _HasChanged handler for all components
   * @param path
   * @param propEl
   */
  saveWhenDefault(path, propEl) {
    if (!this.getByPath(path) && propEl.default) {
      this.saveToPath(path, propEl.default, false);
    }
  }

  saveToPath(path, value, activeChange = true) {
    if (!path) return null;
    let changed = false;
    let storeObj = this.#data;
    for (let i = 0; i < path.length; ++i) {
      if (i < path.length - 1) {
        if (storeObj[path[i]] === undefined || storeObj[path[i]] === null) {
          storeObj[path[i]] = {};
          changed = true;
        }
        storeObj = storeObj[path[i]];
      } else if (i === path.length - 1 && storeObj[path[i]] !== value) {
        storeObj[path[i]] = value;
        changed = true;
      }
    }
    if (activeChange) {
      Dispatcher.dispatch({contentType: Constant.FLUX.FORM_DATA_CHANGED});
    }
  }

  addError(path, message) {
    if (!path) return null;
    this.#error[path] = message;
  }

  removeError(path) {
    if (!path) return null;
    delete this.#error[path];
  }

  get canSubmit() {
    return Object.keys(this.#error).length === 0;
  }

  get errors() {
    return this.#error;
  }

}

const formDataStore = new FormDataStore();

Util.exportInDevelopment(formDataStore, "formDataStore");
export default formDataStore;
