import React from "react";
import {Link} from '@mui/material';

import {Enum} from "jsutil";
import {LearnMore} from "./ui/temporary/LearnMore";

const http_client_config = {
  http_client_config: {
    widget: "HttpClientConfigWrapper",
    options: {
      enabledField: "enabled",
      shortInputs: ["header", "header_value"]
    }
  }
}

const genetec_client_config = {
  genetec_event_config: {
    widget: "OptionalObject",
    options: {
      enabledField: "enabled",
      optionalSubtitle: <React.Fragment>Do not forget to <Link onClick={() => {
        if (window.confirm("When navigating away, current alarm changes will be lost.\nPress OK to confirm.")) {
          window.location = "#/control_panel/Integration";
        }
      }}>set integration settings</Link>.</React.Fragment>
    }
  }
}

const nx_client_config = {
  nx_event_config: {
    widget: "NxClientConfigWrapper",
    options: {
      enabledField: "enabled",
      optionalSubtitle: <React.Fragment>Do not forget to <Link onClick={() => {
        if (window.confirm("When navigating away, current alarm changes will be lost.\nPress OK to confirm.")) {
          window.location = "#/control_panel/Integration";
        }
      }}>set integration settings</Link>.</React.Fragment>
    }
  }
}

const email_notification_config = {
  email_notification_config: {
    widget: "OptionalObject",
    options: {
      enabledField: "enabled",
      optionalSubtitle: <React.Fragment>Do not forget to <Link onClick={() => {
        if (window.confirm("When navigating away, current alarm changes will be lost.\nPress OK to confirm.")) {
          window.location = "#/control_panel/Email";
        }
      }}>set e-mail settings</Link>.</React.Fragment>
    },

  }
}
const smtp_based_integration = {
  smtp_based_integration: {
    widget: "EnumMultiplexer",
    options: {
      enabledField: "enabled",
      optionalSubtitle: <React.Fragment>Do not forget to <Link onClick={() => {
        if (window.confirm("When navigating away, current alarm changes will be lost.\nPress OK to confirm.")) {
          window.location = "#/control_panel/Email";
        }
      }}>set SMTP settings</Link>.</React.Fragment>
    }
  }
}

const bigquery_table_config = {
  bigquery_table_config: {
    widget: "BigQueryWrapper",
    options: {
      enabledField: "enabled"
    }
  }
}

const technical_name = {
  technical_name: {
    widget: "TechnicalName"
  }
};

const
  SINGLE_CAMERA_USE_CASE = {
    input: {
      widget: "SCUI",
      options: {
        allowMultipleCamera: false,
        allowDuplicatedCamera: false,
        filterObjects: ["negative_areas", "positive_areas"],
        scuiProfile: 'uvap-detfilter'
      },
      default: []
    },

    ...http_client_config,
    ...email_notification_config,
    ...genetec_client_config,
    ...nx_client_config,
    ...bigquery_table_config,
    ...smtp_based_integration
  };


const Constant = {
  SCUI_LOCATION: './stream_configurator_ui/index.html',
  // SCUI_LOCATION:  'http://localhost:8080/',
  REPLACE_DELIMITER: '@%%@',

  U_ALARM_VERSION_STRING: "@%%@U_ALARM_VERSION_STRING@%%@", //@deprecated

  ALARM_DOC_URL: "solutions_list",
  ALARM_STATE_POLL_INTERVAL_MS: 12000,

  ALL_FILTER_OBJECTS: ["negative_areas", "positive_areas", "source_areas", "target_areas", "excluded_areas", "areas"],

  ALARM_FPS_REQUIRED: {
    "ZONE_CROSSING": 6,
    "LOITERING_DETECTION": 6,
    "TRAFFIC_COUNTING": 6,
    "STOPPED_DETECTION": 6,
    "WRONG_DIRECTION_DETECTION": 10,
    "PPE_DETECTION": 6,
    "RTSP_STREAM": 6,
  },

  HIDE_SCHEDULE_CATEGORY: ["Counters", "Other", "hide"],
  HIDE_DISARM_ALARM: ["RTSP_STREAM"],
}

Constant.U_ALARM_VERSION_STRING = Constant.U_ALARM_VERSION_STRING.includes(Constant.REPLACE_DELIMITER) ? Constant.DEFAULT_VERSION_STRING + "/" : Constant.U_ALARM_VERSION_STRING + "/";

Constant.FormWidgets = {
  CROWD_DETECTION: {
    inputs: {
      widget: "SCUI",
      options: {
        allowMultipleCamera: true,
        allowDuplicatedCamera: false,
        filterObjects: ["negative_areas", "positive_areas"],
        scuiProfile: 'uvap-detfilter'
      },
      default: []
    },
    ...http_client_config,
    ...email_notification_config,
    ...genetec_client_config,
    ...nx_client_config,
    ...bigquery_table_config,
    ...smtp_based_integration,
  },
  INTRUSION_DETECTION: SINGLE_CAMERA_USE_CASE,
  HAZARDOUS_AREA: SINGLE_CAMERA_USE_CASE,
  MULTI_OBJECT_DETECTION: SINGLE_CAMERA_USE_CASE,
  LOITERING_DETECTION: SINGLE_CAMERA_USE_CASE,
  STOPPED_DETECTION: SINGLE_CAMERA_USE_CASE,
  FALL_DETECTION: SINGLE_CAMERA_USE_CASE,
  PPE_DETECTION: {
    ...SINGLE_CAMERA_USE_CASE,
    analysis_speed: {
      widget: "WrappedField",
      options: {
        optionalSubtitle: <LearnMore docUrl={`${Constant.ALARM_DOC_URL}#analysis-speed`}/>
      }
    },
    selected_equipments: {
      widget: "PpeSelector"
    }
  },
  WRONG_DIRECTION_DETECTION: {
    input: {
      widget: "SCUI",
      options: {
        allowMultipleCamera: false,
        allowDuplicatedCamera: false,
        filterObjects: ["areas"],
        scuiProfile: 'uvap-direction'
      },
      default: []
    },
    ...http_client_config,
    ...email_notification_config,
    ...genetec_client_config,
    ...nx_client_config,
    ...smtp_based_integration,
    ...bigquery_table_config
  },
  ZONE_CROSSING: {
    input: {
      widget: "SCUI",
      options: {
        allowMultipleCamera: false,
        allowDuplicatedCamera: false,
        filterObjects: ["source_areas", "target_areas", "excluded_areas"],
        scuiProfile: 'ualarm-zone'
      },
      default: []
    },
    ...http_client_config,
    ...email_notification_config,
    ...genetec_client_config,
    ...nx_client_config,
    ...smtp_based_integration,
    ...bigquery_table_config
  },
  TRAFFIC_COUNTING: {
    inputs: {
      widget: "SCUI",
      options: {
        allowMultipleCamera: true,
        allowDuplicatedCamera: true,
        filterObjects: ["pass_lines"],
        scuiProfile: 'uvap-passdet'
      },
      default: []
    },
    scheduled_reset: {
      widget: "OptionalObject",
      options: {
        enabledField: "enabled"
      }
    },
    time: {
      widget: "HourMinute",
    },
    ...technical_name,
    ...bigquery_table_config
  }
  ,
  MULTI_OBJECT_COUNTER: {
    inputs: {
      widget: "SCUI",
      options: {
        allowMultipleCamera: true,
        allowDuplicatedCamera: true,
        filterObjects: ["negative_areas", "positive_areas"],
        scuiProfile: 'uvap-detfilter'
      },
      default: []
    },
    ...technical_name,
    ...bigquery_table_config
  },
  RTSP_STREAM: {
    input: {
      widget: "SCUI",
      options: {
        allowMultipleCamera: false,
        allowDuplicateCamera: false,
        filterObjects: [],
      },
      default: []
    },
    rtsp_url_template: {
      widget: "ReadOnlyCopyText",
      options: {
        placeholder: "After creating the RTSP stream, its URL will be displayed here."
      }
    },
    video_annotation_order: {
      widget: "CustomDualListBox"
    },
  },
  METADATA_OUTPUT: {
    input: {
      widget: "SCUI",
      options: {
        allowMultipleCamera: false,
        allowDuplicateCamera: false,
        filterObjects: ["negative_areas", "positive_areas"],
        scuiProfile: 'uvap-detfilter'
      },
      default: []
    },
    ...technical_name,
    ...bigquery_table_config
  }
};

Constant.EVENT = {
  SCUI: {
    statusDataReceived: 'scui-send-data',
    requestData: 'scui-request-data',
    closeIFrame: 'scui-send-close',
    sendPreconfig: 'scui-preconfig',
    sendOpenSignal: 'scui-open-signal',
    backgroundRequestReceived: 'scui-request-background',
    sendCloseSignal: 'scui-close-signal',
    sendBackground: 'scui-background',
  },
  Slave: {
    statusDataReceived: 'slave-data-received',
    opened: 'slave-set-open-state',
    statusClosed: 'slave-closed'
  },
};

Constant.scuiTypes = {
  Passdet: 'uvap-passdet',
  Detfilter: 'uvap-detfilter',
  ZoneAB: 'ualarm-zone'
};

Constant.FLUX = new Enum(
  "URL_CHANGED",
  "FORM_DATA_UPLOADED",
  "FORM_DATA_CHANGED",
  "SOLUTIONS_CHANGED",
  "REFRESH_ALARM_LIST",
  "RESOURCE_CHANGED",
  "VALIDATE_FORM",
  "CHANGE_CAMERA",
);
Constant.rangeSelector = {
  showHorizontalHints: ["FALL_DETECTION"]
}
Constant.api409tryAgainTimes = 15;
Constant.api409tryAgainTimeout = 1750;

Constant.CALCULATION_TIMEOUT_MS = 500;

Constant.ModularText = {
  HttpInputCannotSendTestEvent: "To send a test event, please add a camera to this solution."
}

export default Constant;
