import { useEffect, useState } from "react"
import {
  Alert,
  Button,
  ButtonGroup,
  CircularProgress,
  LinearProgress,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import { Add, Build, QueryStats } from "@mui/icons-material"
import store from "../store"
import Dispatcher from "../dispatcher"
import StatTable from "../component/StatTable"
import { getSitesInAccount } from "../newApiClient"
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom"
import Cover from "../component/Cover"
import Security from "../component/Security"
import { toggleNegativeEvent } from "../adminApiClient"

export default function SiteList() {
  const [sites, setSites] = useState()

  const [_channels, setChannels] = useState()
  const [_allChannels, setAllChannels] = useState([])
  const [error, setError] = useState()
  const [waiting, setWaiting] = useState()
  const [doneWithBase, setDoneWithBase] = useState(false)
  const [openedStat, setOpenedStat] = useState()
  const navigate = useNavigate()
  const { accountId, _siteId } = useParams()
  const accounts = useRouteLoaderData("accounts")
  const currentAccount = accounts.find(account => account.id === Number(accountId))

  useEffect(() => {

    // todo actual AccountId%!!

    setWaiting(true)

    getSitesInAccount(accountId)
      .then(sites => {
        setSites(sites)
        store.siteList = sites
      })
      .catch(ther => {
        setError(ther?.response?.data || "unknown error")
      })
      .finally(() => {
        setWaiting(false)
      })

    setDoneWithBase(true)

  }, [])

  useEffect(() => {
    const d = Dispatcher.register(payload => {
      if (payload.contentType === "REFRESH_DONE") {
        setSites(store.groups)
        setChannels(store.orderedChannels)
        setAllChannels(store.allChannels)
      }
    })
    return () => {
      Dispatcher.unregister(d)
    }
  }, [])

  if (error) {
    return <Alert severity="error">{error}</Alert>
  }

  if (waiting) {
    return <div className={"progressBar"}><LinearProgress /></div>
  }

  if (!sites) {
    return <div className={"progressBar"}><LinearProgress />
    </div>
  }


  const Buttons = () => {
    return (<Button variant={"contained"} className={"action"}
                    onClick={() => navigate("./new")}>
      <Add />
      Add new site
    </Button>)
  }

  return (<>
    <h3 className={"noTopPad"}>Sites in {currentAccount.name}</h3>
    <br /><br />
    <Buttons />
    <br /><br />

    <Cover standalone table>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><span className={"bold"}>Site name</span></TableCell>
              <Security role={"ADMIN"}>
                <TableCell className={"smallcell"}>
                  Allow negative events
                </TableCell>
              </Security>
              <TableCell className={"smallCell"}><span className={"bold"}>Actions</span></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sites
              .sort((a, b) => a.name > b.name ? 1 : -1)
              .map((row) => (<TableRow
                key={row.name}
                className={"pointer"}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}

              >
                <TableCell component="td" scope="row"
                           onClick={() => navigate(`/acc/${accountId}/sites/${row.id}/channels`)}>
                      <span className={"pointer"}
                      >
                          {/*<Button className={"action"} title={"List channels"} variant={"outlined"}>*/}
                        {/*  <Edit/>&nbsp;Edit*/}
                        {/*</Button>*/}
                        {/*&nbsp;*/}
                        <span className={"rowItem siteListItem"}>
                              <span className={"limitWidth"}>
                                  {row.name}
                              </span>
                          </span>
                      </span>
                </TableCell>
                <Security role={"ADMIN"}>
                  <SiteNegativeEventSwitch row={row} />
                </Security>
                <TableCell>
                  {doneWithBase ? <ButtonGroup variant="outlined">
                    <Button className={"action left"} size={"small"} disableElevation
                            title={"See statistics"}
                            onClick={() => setOpenedStat(row.id)}>
                      <QueryStats />&nbsp;Stats
                    </Button>
                    <Button className={"action right outlined"} title={"Edit Site"}
                            onClick={() => navigate(`./${row.id}`)}>
                      <Build />&nbsp;Configure
                    </Button>

                  </ButtonGroup> : <CircularProgress size={"31px"} />}
                </TableCell>

              </TableRow>))} {/* todo add noi sites label **/}
          </TableBody>
        </Table>
      </TableContainer>
    </Cover>

    <br />

    <Buttons />

    {openedStat && <StatTable siteId={openedStat}
                              sites={sites}
                              close={() => setOpenedStat(null)}
                              site={sites.find(elem => elem.id === openedStat)}
    />}
  </>)
}

export function SiteNegativeEventSwitch({ row }) {
  const [checked, setChecked] = useState(row.notification_config.negative_event !== "NOT_ACCESSIBLE")
  const [progress, setProgress] = useState(false)

  const submit = async evt => {
    const newValue = evt.target.checked
    setProgress(true)
    toggleNegativeEvent(row.id, newValue)
      .then(res => {
        setChecked(newValue)
      })
      .catch()
      .finally(() => {
        setProgress(false)
      })


  }
  return <>
    <TableCell className={"smallcell"}>
      <Switch onChange={submit} checked={checked} />
    </TableCell>
  </>
}

