import React, {useEffect} from "react";
import TextField from '@mui/material/TextField/';
import formDataStore from "../FormDataStore";
import Dispatcher from "../../dispatcher";
import Constant from "../../constant";
import {JSUtil} from "jsutil";

const INTEGER_MIN = -2147483648;
const INTEGER_MAX = 2147483647;

export default function Integer({isRequired, id, propertyElement, path, value}) {
  const [number, setNumber] = React.useState(formDataStore.getByPath(path));
  const [errorMessage, setErrorMessage] = React.useState(null)

  useEffect(() => { // TODO eliminate unnecessary state updates
    const dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED || payload.contentType === Constant.FLUX.FORM_DATA_CHANGED) {
        const currentValue = formDataStore.getByPath(path);
        // if (currentValue !== number) {
          setNumber(currentValue)
        // }
      }
    })
    return () => {
      Dispatcher.unregister(dispatcher);
    };
  }, [])

  const validate = newValue => {
    if (isRequired && newValue === "") {
      throw new Error("Required field.");
    }
    if (isNaN(parseInt(newValue))) {
      throw new Error("Invalid value.");
    }
    return newValue;
  }

  const onChange = evt => {
    const strValue = evt.target.value.replace(/\D/g,'');
    if (strValue === String(number)) {
      return;
    }

    setNumber(strValue);
    const newValue = parseInt(strValue);

    try {
      validate(strValue);
      setErrorMessage(null);
      formDataStore.removeError(path);
      formDataStore.saveToPath(path, newValue);
    } catch (err) {
      formDataStore.addError(path, err.message)
      setErrorMessage(err.message);
    }
  }

  return (
    <React.Fragment>

      <TextField id={id}
                 label={propertyElement.title}
                 InputProps={{
                   inputProps: {
                     min: propertyElement.minimum || INTEGER_MIN,
                     max: propertyElement.maximum|| INTEGER_MAX,
                     required: isRequired
                   }
                 }}
                 fullWidth={true}
                 required={isRequired}
                 value={JSUtil.isNullOrUndefined(number) ? "" : number}
                 onChange={onChange}
                 error={!!errorMessage}
                 variant={"standard"}
                 helperText={`${errorMessage ? errorMessage : ''} ${propertyElement.description || ''}` }
      />
    </React.Fragment>
  )
}
