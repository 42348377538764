import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material"
import { Delete, Save } from "@mui/icons-material"
import * as React from "react"
import { useEffect, useState } from "react"
import enums from "../enums"
import { IntegrationDetails } from "./Integration"
import { copy, formatSmtpUser, unwrapErrorMessage } from "../util"
import { MtrModularText, NEW_API_URL, SiteFormWidget, TECHNICAL_NAME_PATTERN, TECHNICAL_NAME_REGEX } from "../constant"
import CopyBtn from "./CopyBtn"
import store from "../store"
import { SingleTaskViewRoot } from "multi_task_runner"
import SINGLE_SITE_SCHEMA from "../jsonschema/SiteHTTPWebhook"

import MtrApiClient from "../classes/mtrUfilterApiClient"
import { Link, useNavigate, useParams, useRouteLoaderData } from "react-router-dom"
import { createSite, deleteSite, getSingleSite, modifySite } from "../newApiClient"
import Cover, { CoverHeader } from "./Cover"
import { confirmDialog } from "./temp/ConfirmDialog"
import AutoId from "./AutoId"
import unwrapErrorFromApi from "./UnwrapErrorApi"

export const DEFAULT_SITE_VALUE = {
  email_integration_config: {
    email_integration: enums.INTEGRATION.PLAINMAILWIMAGE,
    attach_snapshot: true
  },
  offset: 0,
  notification_config: {
    negative_event: "NOT_ACCESSIBLE",
    overload: "ACTIVE",
    error: "INACTIVE"

  }
}

const SMTP_INTEGRATIONS = {
  [enums.INTEGRATION.BLACKHOLE]: "None",
  [enums.INTEGRATION.PLAINMAILWIMAGE]: "E-mail",
  [enums.INTEGRATION.IMMIX]: "Immix",
  [enums.INTEGRATION.WINGUARD]: "Winguard"
}

// applied when no MTR update was called
const defaultNoInteractionMtr = {
  url: "",
  enabled: false,
  headers: {},
  attach_snapshot: false
}
const defaultNoInteractionNxMtr = {
  trust_all_certs: false,
  host: ""

}
const baseHeader = {
  headers: {}
}

export default function Site() {
  const [original, setOriginal] = useState()
  const [actual, setActual] = useState()
  const [waitingSave, setWaitingSave] = useState(false)
  const [errorSave, setErrorSave] = useState()
  const [_finished, setFinished] = useState()
  const [technicalNameError, setTechnicalNameError] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const [smtpEdit, setSmtpEdit] = useState(false)

  const [mtrState, setMtrState] = useState(false)
  const [mtrStateChanged, setMtrStateChanged] = useState(false)
  const [apiClientInst, setApiClientInst] = useState(false)
  const [forceValidate, _forceValidateAct] = useState({})
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  let _loaded = useRouteLoaderData("site")
  let accounts = useRouteLoaderData("accounts")


  useEffect(() => {
    setActual(copy(original))
  }, [original])

  const { accountId, siteId } = useParams()
  const isCreating = siteId === "new"
  const currentAccount = accounts.find(account => account?.id === Number(accountId))

  useEffect(() => {
    let siteData = copy(store.getSiteById(siteId))
    if (isCreating) {
      splitDataToOriginalAndMtr(copy(DEFAULT_SITE_VALUE))
      setLoading(false)
    } else {
      if (!siteData) {
        setLoading(true)
        getSingleSite(siteId)
          .then(res => {
            splitDataToOriginalAndMtr(res)
            setLoading(false)
          })
          .catch(console.error)
      } else {
        splitDataToOriginalAndMtr(copy(store.getSiteById(accountId)))
      }
    }

    setApiClientInst(new MtrApiClient(NEW_API_URL, { siteId }))
  }, [])

  // useEffect(() => {
  //   splitDataToOriginalAndMtr(copy(store.getSiteById(accountId)))
  //   setApiClientInst(new MtrApiClient(API_URL, state));
  //   const d = Dispatcher.register(payload => {
  //     if (payload.contentType === "REFRESH_DONE") {
  //       splitDataToOriginalAndMtr(copy(store.getSiteById(accountId)))
  //       setApiClientInst(new MtrApiClient(API_URL, state));
  //     }
  //   });
  //   return () => {
  //     Dispatcher.unregister(d);
  //   }
  // }, [state])

  const splitDataToOriginalAndMtr = content => {
    setMtrState({
      http_webhook_config: content.http_webhook_config,
      nx_integration_config: content.nx_integration_config
    })
    setSmtpEdit(!!content.email_integration_config?.smtp_configuration)

    setOriginal(purgeHttp(content))
  }

  const onMtrStateChange = newState => {

    setMtrState(newState.formData)
    setMtrStateChanged(true)
  }

  const onChangeIntegration = evt => {
    const integration = evt.target.value
    const ac = {
      ...actual,
      email_integration_config: {
        ...actual.email_integration_config,
        email_integration: evt.target.value
      }
    }

    ac.email_integration_config.target_address =
      integration === enums.INTEGRATION.BLACKHOLE ? null : actual.email_integration_config.target_address
    setActual(ac)
  }

  const purgeHttp = act => {
    const cpAct = copy(act)
    delete cpAct.http_webhook_config
    return cpAct
  }
  const setNotificationConfig = variant => evt => {
    setActual(actual => {
      return {
        ...actual,
        notification_config: {
          ...actual.notification_config,
          [variant]: evt.target.checked ? "ACTIVE" : "INACTIVE"
        }
      }
    })
  }
  const submit = async evt => {
    evt.preventDefault()

    // todo do a better join

    const http_webhook_config = {
      ...baseHeader,
      ...(mtrState.http_webhook_config ? mtrState.http_webhook_config : defaultNoInteractionMtr)
    }
    http_webhook_config.url = http_webhook_config.url || ""
    http_webhook_config.attach_snapshot = http_webhook_config.attach_snapshot || false

    const nx_integration_config = {
      ...defaultNoInteractionNxMtr,
      ...mtrState.nx_integration_config
    }
    nx_integration_config.enabled = nx_integration_config.enabled || false
    nx_integration_config.host = nx_integration_config.host || ""
    nx_integration_config.password = nx_integration_config.password || ""
    nx_integration_config.username = nx_integration_config.username || ""
    nx_integration_config.port = nx_integration_config.port || 1
    //http_webhook_config.headers = http_webhook_config.headers || {};

    const combinedData = {
      ...copy(purgeHttp(actual)),
      http_webhook_config,
      nx_integration_config
    }

    setFinished(false)
    setWaitingSave(true)
    setErrorSave(false)
    try {
      if (isCreating) {
        await createSite(accountId, combinedData)
        navigate("..")
      } else {
        await modifySite(siteId, combinedData)
        await confirmDialog({ title: "Changes Saved.", noCancel: true })
        setOriginal(combinedData)
      }
      setFinished(true)
    } catch (err) {
      unwrapErrorFromApi(err)
      setErrorSave(unwrapErrorMessage(err))
    } finally {
      setWaitingSave(false)
    }
  }

  const deleteSiteCall = async () => {
    if (!window.confirm("Selected site and its channels will be deleted permanently.\nPress OK to confirm.")) {
      return
    }
    setWaitingSave(true)
    setErrorSave(false)
    try {
      await deleteSite(siteId)
      await confirmDialog({ title: "Site Deleted.", noCancel: true })

      navigate("..")
      //   await refreshData();
    } catch (err) {
      setErrorSave(unwrapErrorMessage(err))
    } finally {
      setWaitingSave(false)
    }
  }

  const Title = () => {
    if (isCreating) {
      return "New site"
    } else {
      return `Site: ${original.name}`
    }
  }

  const onChangeTechnicalName = evt => {
    const lower = evt.target.value.toLowerCase()
    setActual({ ...actual, tid: lower })
    const isOk = TECHNICAL_NAME_REGEX.test(lower)
    setTechnicalNameError(lower && !isOk)
  }

  if (loading) {
    return <div className={"progressBar"}><LinearProgress /></div>
  }

  if (!original) {
    return <Alert severity="error">Missing or loading data</Alert>
  }
  if (!actual) {
    return <Alert severity="info">Loading data</Alert>
  }

  const didFormChange = (JSON.stringify(actual) !== JSON.stringify(original)) || mtrStateChanged
  const didFormChangeJSON = (JSON.stringify(actual) !== JSON.stringify(original))

  return <div id={"siteWrapper"} className={"mw-960"}>


    <Typography variant="h3" sx={{ my: 2 }}>
      <Title />
    </Typography>
    <br />
    <br />
    <Cover standalone>
      <form onSubmit={submit}>

        <CoverHeader>

          <Button className={"formSubmit action"} variant="contained"
                  title={validationErrors.length !== 0 ? "Cannot save: There are validation errors." : ""}
                  disabled={!didFormChange || validationErrors.length !== 0} type={"submit"}>
            <Save />
            Save
          </Button>
          <div className={"right"}>

            {!isCreating && <>
              <Link to={"./channels"} className={"noa"}>
                <Button className={"action"} variant="outlined">
                  View Channels
                </Button>
              </Link>
              <Button className={"formSubmit action delete"} variant="contained" onClick={deleteSiteCall}>
                <Delete /> Delete Site
              </Button>

            </>}
          </div>
        </CoverHeader>

        <br />
        <br />

        <div className={"formHolder"}>
          <Grid container spacing={1}>
            <Grid item sm={12}>
              <TextField
                className="largeForm" type="text" label="Name" required
                value={actual?.name} variant="outlined"
                onChange={evt => setActual({ ...actual, name: evt.target.value })}
              />
            </Grid>
            <Grid item sm={12} sx={12}>
              <AutoId
                category={"site"}
                className={"largeForm " + (!isCreating ? "readonly" : "")}
                type="text"
                error={technicalNameError}
                label={`Unique ID  ${isCreating ? "(do not use special characters)" : "(cannot be changed)"}`}
                required
                inputProps={{
                  pattern: TECHNICAL_NAME_PATTERN,
                  readOnly: !isCreating
                }}
                InputProps={{
                  endAdornment: <CopyBtn value={actual?.tid} />
                }}
                value={actual?.tid || ""} variant="outlined"
                onChange={onChangeTechnicalName}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                className="largeForm" type="text" label="SMTP Password" required step={1}
                value={actual?.password || ""} variant="outlined"
                onChange={evt => setActual({ ...actual, password: (evt.target.value) })}
              />
            </Grid>
            <br />
            <Grid item sm={6} xs={12}>
              <TextField
                className="largeForm" type="number" label="Event Cooldown" required step={1} max={1800} min={0}
                value={actual?.cooldown ?? 60} variant="outlined"
                onChange={evt => setActual({ ...actual, cooldown: Number(evt.target.value) })}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                className="largeForm" type="number" label="Overload Cooldown" required step={1} max={600} min={0}
                value={actual?.overload_cooldown ?? 60} variant="outlined"
                onChange={evt => setActual({ ...actual, overload_cooldown: Number(evt.target.value) })}
              />
            </Grid>
            <br />

            {!isCreating && (
              <>
                <Grid item sm={6} xs={12}>
                  <TextField
                    className="largeForm readonly" type="text" label="SMTP server"
                    InputProps={{
                      endAdornment: <CopyBtn value={store.mail_server.mail_server || ""} />
                    }}
                    value={store.mail_server.mail_server || ""}
                    variant="outlined"
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    type="text" label="SMTP user"
                    value={formatSmtpUser(currentAccount?.tid, actual?.tid)}
                    className="largeForm readonly"
                    InputProps={{
                      readOnly: true,
                      endAdornment: <CopyBtn value={formatSmtpUser(currentAccount?.tid, actual?.tid)} />
                    }}
                    variant="outlined"
                  />
                </Grid>
                {/*<Grid item sm={6} xs={12}>*/}
                {/*  <TextField*/}
                {/*    className="largeForm readonly" type="text" label="SMTP password"*/}
                {/*    InputProps={{*/}
                {/*      endAdornment: <CopyBtn value={actual?.smtp_pw}/>*/}
                {/*    }}*/}
                {/*    value={actual?.smtp_pw || ""} variant="outlined"*/}
                {/*  />*/}
                {/*</Grid>*/}
              </>
            )}

            {/*{!isCreating && (*/}
            {/*  <Grid item sm={12} xs={12}>*/}
            {/*    <ResetSmtpPw id={original.id} currentValue={actual?.smtp_pw}/>*/}
            {/*  </Grid>*/}
            {/*)}*/}
            <Grid item xs={12}>
              <h4 className={"dividerItem"}>Integration</h4>
            </Grid>


            <Grid item xs={12}>
              <TextField
                className="largeForm" type="number" label="Event time offset (sec)" required step={1}
                value={actual?.offset || 0} variant="outlined"
                onChange={evt => setActual({ ...actual, offset: Math.round(Number(evt.target.value)) })}
              />
            </Grid>

            <Grid item xs={12}>
              <h5 className={"subtitleItem"}>SMTP or CSM Software</h5>
            </Grid>

            <Grid item xs={12}>
              <FormControl className="largeForm">
                <InputLabel>Integration *</InputLabel>
                <Select
                  value={actual.email_integration_config?.email_integration || enums.INTEGRATION.PLAINMAILWIMAGE}
                  label="Integration *"
                  onChange={onChangeIntegration}
                  required
                >
                  {
                    Object.entries(SMTP_INTEGRATIONS).map(
                      ([value, displayName]) => <MenuItem value={value} key={value}>{displayName}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </Grid>
            <IntegrationDetails siteId={siteId}
                                data={actual}
                                setData={setActual}
                                isCreating={isCreating}
                                dirty={didFormChangeJSON}
                                email_integration={actual.email_integration_config?.email_integration}
                                smtpEdit={smtpEdit}
            />

            <Grid item xs={12}>
              <h5 className={"subtitleItem"}>Send non-positive events</h5>


              {/*<FormControlLabel control={<Checkbox checked={true}*/}
              {/*                                   color={"secondary"}*/}
              {/*/>}*/}
              {/*label="Positive Event"/>*/}
              <div className={"ml-10"}>
                <FormControlLabel
                  control={<Checkbox checked={actual?.notification_config.overload === "ACTIVE"}
                                     onChange={setNotificationConfig("overload")}
                                     color={"secondary"}
                  />}
                  label="Overload" />
                <FormControlLabel
                  control={<Checkbox checked={actual?.notification_config.error === "ACTIVE"}
                                     onChange={setNotificationConfig("error")}
                                     color={"secondary"}
                  />}
                  label="Error and Technical" />
                {["ACTIVE", "INACTIVE"].includes(actual.notification_config?.negative_event) &&
                  <FormControlLabel
                    control={<Checkbox checked={actual?.notification_config.negative_event === "ACTIVE"}
                                       onChange={setNotificationConfig("negative_event")}
                                       color={"secondary"}
                    />}
                    label="Negative Event" />
                }
              </div>
              <br />
            </Grid>

          </Grid>


          <Grid item xs={12}>
            <h5 className={"subtitleItem"}>HTTP Webhook</h5>
          </Grid>

          <div className={"mtrBox"}>
            {apiClientInst && (mtrState || true) ?
              <SingleTaskViewRoot
                standalone={true}
                disableInputChoice={!isCreating}
                schema={SINGLE_SITE_SCHEMA}
                FormWidgets={SiteFormWidget}
                ModularText={MtrModularText}
                initialFormData={mtrState}
                MtrApiClientInstance={apiClientInst}
                onChange={onMtrStateChange}
                onSubmit={() => false}
                forceValidate={forceValidate}
                surfaceValidationErrors={value => setValidationErrors(value)}
              /> : <>Loading Webhook details</>
            }
          </div>

          <ValidationErrorsUFilter validationErrors={validationErrors} />

        </div>

        {
          waitingSave && <LinearProgress />
        }

        {
          errorSave &&
          <>
            <Alert severity="error" onClose={() => setErrorSave(undefined)}>{errorSave}</Alert>
            <br />
          </>
        }

      </form>

    </Cover>
    <br />
    <br />
  </div>
}

export function ValidationErrorsUFilter({ validationErrors }) {
  return <Collapse in={validationErrors?.length}>
    <br /><br />
    <Alert severity={"warning"} variant={"outlined"} className={" ufilter-validationerror"}>
      Validation issues:<br />
      {validationErrors.map(error => <p key={error}>{error}</p>)}
    </Alert>
  </Collapse>
}
