import { confirmDialog } from "./temp/ConfirmDialog"
import {
  getReasonPhrase
} from "http-status-codes"

export default async function unwrapErrorFromApi(error) {

  let msg = ""
  let title = "Error"

  if (!error) {
    title = "Cannot reach API"
  }

  if (error.response) {
    if (error.response.status === 403) {
      msg += `Password does not match or no access to resource.\n`
    }
    if (error.response.data) {
      try {
        //error.response.data = JSON.parse(error.response?.data);
      } catch (e) {

      }

      if (error.response.data.detail) {
        msg += error.response.data.detail + "\n"
      }
        // else if (error.response.data.length) {
        //   msg += error.response.data.join('\n')
      // }
      else {
        msg += error.response.data + "\n"
      }
    }
  } else {
    msg += error
  }


  if (!msg) {
    if (error.message) {
      msg += error.message + "\n"
    }
    if (error.response) {
      if (error.response.status) {
        msg += getReasonPhrase(error.response.status) + "\n"
      }
    }
  }

  return await confirmDialog({
    title,
    message: msg,
    noCancel: true
  })

}


