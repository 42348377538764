import { Button } from "@mui/material"
import { ArrowBack } from "@mui/icons-material"

import { useLocation, useNavigate, useParams, useRouteLoaderData } from "react-router-dom"

export default function Toolbar() {
  const navigate = useNavigate()
  const location = useLocation()

  const splitPath = location.pathname.split("/")
  const everyButLast = splitPath.slice(0, splitPath.length - 1).join("/")

  //eslint-disable-next-line
  const { accountId, siteId } = useParams()

  const currentSite = useRouteLoaderData("site") || false
  const accounts = useRouteLoaderData("accounts") || []
  const currentChannel = useRouteLoaderData("channel") || false
  const currentAccount = accounts?.find(account => account.id === Number(accountId))

  const usefulData = [
    currentAccount?.name,
    currentSite?.name,
    currentChannel?.name
  ]

  return <>
    <div id={"AppToolbar"}>
      <Button className={"toolbarBtn"}
              onClick={() => navigate(everyButLast)}>
        <ArrowBack className={"smallArrow"} /> Back
      </Button>

      <div id={"parsedPath"}>
        {
          splitPath
            .slice(1)
            .map((item, i) => {
              if (!isNaN(item)) return <span key={i}>
                  {" / "}
                <a className={"parsedPathItem parsedPathResource"}
                   title={splitPath.slice(0, i + 2).join("/")}
                   onClick={() => navigate(splitPath.slice(0, i + 2).join("/"))}
                >
                      {usefulData[Math.floor(i / 2)]}
                  </a>
              </span>

              return <span key={i}>
                  {" / "}
                <a className={"parsedPathItem parsedPathRoute"}
                   title={splitPath.slice(0, i + 2).join("/")}
                   onClick={() => navigate(splitPath.slice(0, i + 2).join("/"))}
                >
                      {item}
                  </a>
              </span>
            })
        }
      </div>

      <span className={"flr"} id={"iconsLeft"}>
              {/*<Button className={"toolbarBtn"} disabled*/}
        {/*        onClick={() => navigate(-1)}>*/}

        {/*  <ChevronLeft className={"smallArrow"}/> Previous*/}
        {/*</Button>*/}
        {/*<Button className={"toolbarBtn"} disabled*/}
        {/*        onClick={() => navigate(-1)}> Next*/}
        {/*  <ChevronRight className={"smallArrow"}/>*/}
        {/*</Button>*/}
          </span>
    </div>
    <div id={"AppToolbar-Clearfix"}>&nbps;</div>
  </>
}
