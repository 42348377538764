import React from 'react';

import EditTask from "../ui/EditTask";
import NewAlertView from './NewAlertView';
import MtrContext from "../MtrContext";

import Constant from "../constant";
import {JSUtil, PromiseUtil} from "jsutil";

import Dispatcher from "../dispatcher";
import Util from "../util";
import {Grid,Alert,LinearProgress,Typography} from "@mui/material";

import {faBell} from '@fortawesome/pro-duotone-svg-icons';
import AlarmList from "../components/AlarmList";
import {CtaButton} from "./temporary/CtaButton";
import {LearnMore} from "./temporary/LearnMore";
import BackBtnSmallDevices from "./temporary/BackBtnSmallDevices";
import SCUtils from "../form/SCUIUtils";
import Slave from "../scui-slave";

require("multi_task_runner/src/styles/alert.css");

const NEW_TASK_KEY = "NEW_TASK";

class ContentRoot extends React.Component {
  static getDerivedStateFromError(error) {
    console.error('[MTR UI Error]');
    return {hasReactError: true};
  }

  constructor(props, context) {
    super(props, context);

    const activeKey = Util.getQuerySearch('taskId');

    this.state = {
      finishedWithDomainData: false,
      finishedFetching: false,
      finishedWithSchemas: false,
      finishedWithTasks: false,
      message: "",
      activeKey,
      isFirstSelected: activeKey === NEW_TASK_KEY
    };
    this.containerRef = React.createRef();
    this.props = props;

  };

  resetState = () => {
    this.setState({
      finishedWithDomainData: false,
      finishedFetching: false,
      finishedWithSchemas: false,
      finishedWithTasks: false,
      hasLoadingError: false,
      hasReactError: false,
      message: "",
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.reloadToken !== prevProps.reloadToken) {
      this.reloadContent();
      this.setState({activeKey: "empty-state"})
    }
  }

  resolveUrl() {
    const activeKey = Util.getQuerySearch('taskId');
    if (!activeKey) this.onSelect(NEW_TASK_KEY)
    this.setState({
      activeKey,
      isFirstSelected: activeKey && activeKey === NEW_TASK_KEY
    });

  }

  componentDidMount() {
    this.resolveUrl();
    this.loadContentFromApi();

    this.dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.URL_CHANGED) {
        this.resolveUrl();
        if (SCUtils.isShown) { // close and reset scui if user navigates away
          Slave.sendCloseRequest();
        }
      }
      if (payload.actionType === Constant.FLUX.REFRESH_ALARM_LIST) {
        this.updateAllTasksAndTypes();
      }
    });

    this.pollInterval = setInterval(() => {
      if (!window.__IS_POLLING_DISABLED__) {
        this.props.mtrApiClient.getAllTasks();
      }
    }, Constant.ALARM_STATE_POLL_INTERVAL_MS);
  }

  componentWillUnmount() {
    Dispatcher.unregister(this.dispatcher);
    clearInterval(this.pollInterval);
  }

  loadContentFromApi = async () => {
    const {
      mtrApiClient, getTaskDomainTypes, getCameraDomainTypes, store, getPassLineTypes,
      getCameraList, getSchedules, getMissingThumbnails
    } = this.props;

    try {
      await mtrApiClient.getTaskTypes();
      store.schedules = await getSchedules() || [];
      store.cameras = await getCameraList() || [];
      this.setState({finishedFetching: true});

      mtrApiClient.getAllSchemas().then(() => {
        this.setState({finishedWithSchemas: true});
      })

      await mtrApiClient.getAllTasks();

    } catch (error) {
      if (!error.isCanceled) {
        if (error.response.status === 409) {
          this.setState({has409Error: error});
        } else if (error.response || error.message.includes("Network Error")) {
          this.setState({hasLoadingError: error});
        } else {
          this.setState({hasReactError: error});
        }
        this.onLock(false);
        this.props.mtrLogger(error);
      }
    }

    try {
      store.taskDomainTypes = await getTaskDomainTypes() || [];
      store.cameraDomainTypes = await getCameraDomainTypes() || [];
      store.taskPassLines = await getPassLineTypes() || [];
      store.thumbnails = await getMissingThumbnails();

      this.setState({finishedWithTasks: true});
      this.setState({finishedWithDomainData: true})
      await mtrApiClient.calculateResource();
    } catch (error) {

      if (!error.isCanceled) {
        if (error.response?.status === 409) {
          this.setState({has409Error: error});
        } else {
          this.setState({hasLoadingError: error});
        }

        this.props.mtrLogger(error);
      }
    }

  }

  setMessage = (message) => {
    this.setState({message});
  }

  updateAllTasksAndTypes = () => {
    Promise.all([
      this.props.mtrApiClient.getAllTasks(),
      this.props.mtrApiClient.getTaskTypes()
    ]).then(() => {
      this.setState({finishedWithTasks: true});
      this.onLock(false);
    });
  }

  onSelect = name => {
    if (window.location.href.indexOf('#/solution') !== -1) {
      this.setMessage(null);
      window.location.href = `#/solution?taskId=${name}`;
      setTimeout(() => this.props.mtrApiClient.calculateResource(), 0)
      document.querySelector('.contentBody').scrollTop = 0;
    }
  };

  onLock = shouldLock => {
    this.props.onStatusChange({isInvalid: shouldLock});
    if (!JSUtil.isReactInDevelopmentMode()) {
      JSUtil.attachBeforeUnloadPrompt(shouldLock);
    }
  };

  reloadContent = () => {
    this.resetState();
    this.loadContentFromApi();
    this.onLock(false);
  }

  render() {
    if (this.state.hasLoadingError) console.log("hasLoadingError", this.state.hasLoadingError);
    if (this.state.hasReactError) console.log("hasReactError", this.state.hasReactError);
    if (this.state.has409Error) console.log("has409Error", this.state.hasReactError);

    if (this.state.hasLoadingError || this.state.hasReactError || this.state.has409Error) {

      let msg;
      if (this.state.has409Error) {
        msg = "A previous change is still being processed by the device. Please wait a few seconds and then try again.";
      } else {
        msg = (this.state.hasReactError ? "An internal UI " : "A Network ") + "error occurred.";
      }

      return (
        <div className={"tab-empty-state-container"}>
          <div className={"task-tab-empty-state"}>
            {msg}
          </div>
          <br/>
          <CtaButton primary onClick={this.reloadContent}>Reload</CtaButton>
        </div>
      )
    }

    const selectedElement = this.props.store.tasks?.find(el => el.id === this.state.activeKey);

    /**
     * This is required to make SCUI not cache inbetween version upgrades.Łs
     * @type {*|string}
     */
    const parentApplicationVersion = this.props.store?.Suite?.versionString || "unset";
    return (
      <MtrContext.Provider value={(
        {
          store: this.props.store,
          mtrApiClient: this.props.mtrApiClient,
          taskAlias: this.props.taskAlias,
          mtrLogger: this.props.mtrLogger,
          getSnapshot: this.props.getSnapshot,
          Constant: this.props.Constant
        }
      )}>
 <span className={'tab-container'}>

   {this.state.finishedWithTasks &&
     <iframe id={"scuiIframe"}
             src={Constant.SCUI_LOCATION + "?version=" + parentApplicationVersion} name={"scuiIframeName"}
             title={"Stream Configurator"}/>
   }

         <div id="alert-container"
              ref={this.containerRef}
              className={this.state.isFirstSelected ? 'firstMenuSelected tab-container' : 'tab-container'}>

           {(this.state.activeKey &&
               this.state.activeKey !== NEW_TASK_KEY) &&
             <BackBtnSmallDevices onClick={() => this.onSelect(NEW_TASK_KEY)}/>}

           <span>
            <Grid container spacing={3} direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start">
             <Grid item sm={12} md={3}
                   className={(!this.state.activeKey || this.state.activeKey === NEW_TASK_KEY) ? "sm-visible" : "sm-hidden"}>



               <div className={"actually-alarmViewRoot"}>
                  <Typography variant={"h5"} className={"h1 paddingLeft-15"}>Solutions
                    {!this.state.isFirstSelected && <CtaButton icon={faBell} onClick={() => this.onSelect(NEW_TASK_KEY)}
                                                               className={"addNewButton leftMargin-20"}>
                      New Solution
                    </CtaButton>}
                  </Typography>

                 {
                   this.state.finishedWithTasks && this.state.finishedWithDomainData ?
                     <AlarmList selectedId={selectedElement?.id}
                                taskAlias={this.props.taskAlias}
                                mtrApiClient={this.props.mtrApiClient}
                                store={this.props.store}
                                onSelect={this.onSelect}/>
                     :
                     <div className={"padding-15"}>
                       <Typography align={"center"} variant="subtitle1">
                         Loading Solutions...<br/><br/>
                       </Typography>
                       <LinearProgress/>
                     </div>
                 }


                 <LearnMore docUrl={`${Constant.ALARM_DOC_URL}`}/>

                </div>
               <br/>

             </Grid>
            <Grid item md={9} sm={12}
                  className={(this.state.activeKey) ? "sm-visible " + this.state.activeKey : "sm-hidden"}>
                <div>
                  {
                    this.state.message &&
                    <React.Fragment>
                      <Alert className={"absolutePositionAlert primaryAlert"} severity={"success"} variant={"filled"}
                             onClose={() => this.setMessage(null)}
                             dismissible={"true"} style={{marginTop: '25px'}}>
                        {this.state.message}
                      </Alert>
                      <br/>
                    </React.Fragment>
                  }
                </div>
                <div className={"contentRootTab"}>

                      {this.state.isFirstSelected && (this.state.finishedFetching ? <NewAlertView
                        setMessage={this.setMessage}
                        onChange={this.onLock}/> : <React.Fragment>
                        <br/>
                        <div className={"medium-title"}>Loading Solution Types...</div>
                      </React.Fragment>)
                      }

                  {
                    this.state.activeKey === null && <div>
                      <br/> <br/> <br/>
                      Available solutions are loading...
                    </div>
                  }

                  {
                    this.state.finishedWithTasks && selectedElement &&
                    <span>
                              <EditTask id={selectedElement.id}
                                        taskInfo={{
                                          type: selectedElement.type,
                                          type_display_name: this.props.store.alertTypes.find(elem => elem.type === selectedElement.type).display_name,
                                          display_name: selectedElement.display_name,
                                          active: selectedElement.active,
                                          schedule_id: selectedElement.schedule_id
                                        }}
                                        setMessage={this.setMessage}
                                        onDelete={() => {
                                          this.onSelect('empty-state')
                                        }}
                                        onChange={this.onLock}
                              />

                          </span>
                  }

                  </div>
              </Grid>
            </Grid>
          </span>
        </div>
      </span>
      </MtrContext.Provider>
    );
  }
}

export default ContentRoot;
