import Util from "./util"

const SchemaTransform = {};

SchemaTransform.convertJsonSchema = function (jsonSchema, store) {
  if (typeof jsonSchema !== "object") throw Error('Cannot convert non-JSON formatted schema');
  jsonSchema.$schema = "http://json-schema.org/draft-07/schema#";

  // if you are testing a new property, you can inject it here and test it
  // please do not do any permanent dirty fixes here

  return jsonSchema;
};


SchemaTransform.domainTypeStrToJSON = function (data) {
  const parsedData = Util.deepCopy(data);

  if (parsedData.inputs) {
    parsedData.inputs = Util.convertObjectToList(parsedData.inputs);
  }

  if (parsedData.input) {
    for (const [_, value] of Object.entries(parsedData.input)) {
      parsedData.input = value;
    }
  }

  return parsedData;
};

export default SchemaTransform;
