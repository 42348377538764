
class Dispatcher {
  constructor(DispatchHandler) {
    this.fluxDispatcher = new DispatchHandler;
    this.queue = [];
  }

  register(callback) {
    return this.fluxDispatcher.register(callback);
  }

  unregister(id) {
    this.fluxDispatcher.unregister(id);
  }

  dispatch(payload) {
    this.queue.push(payload);
    this.startProcess();
  }

  startProcess() {
    while (this.queue.length > 0) {
      if (this.fluxDispatcher.isDispatching()) {
        return setTimeout(this.startProcess.bind(this), 10)
      }

      const payload = this.queue.shift();
      this.fluxDispatcher.dispatch(payload);
    }
  }
}

export default Dispatcher;
