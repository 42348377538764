// copied from mtr ui

import { ALL_FILTER_OBJECTS, EVENT, SCUI_TYPES } from "./constant.js"
import Dispatcher from "./dispatcher"
import SCUtils from "./SCUIUtils"

window.slave = {}


/**
 * Communicates with an instance of stream configurator
 */
class Slave {


  static messageReceived(e) {
    switch (e.data.actionType) {
      case EVENT.SCUI.requestData :
        Slave.image.then(resolved => {
          Slave.contentRequested(resolved.data.data)
          }
        ).catch(e => console.log(e)).finally(() =>
          delete Slave.image
        )
        break
      case EVENT.SCUI.statusDataReceived :
        Slave.dataReceived(e)
        SCUtils.Close()
        break
      case EVENT.SCUI.closeIFrame :
        //history.back(); - disabled awaiting refactor
        Slave.dispatchIFrameChange(false)
        SCUtils.Close()
        Dispatcher.dispatch({ actionType: EVENT.Slave.statusClosed })
        break
      case EVENT.SCUI.backgroundRequestReceived:
        Slave.getSnapshot(Slave.cameraId, true)
          .then((base64) => {
            Slave.changeBackground(base64)
          })
          .catch((error) => {
            Slave.sendBackgroundError(error)
          })
        break
      default:
        return false
    }
  }

  /**
   *
   * @param imageInBase64
   * @param contentType
   */
  static contentRequested(imageInBase64, contentType) {
    if (!contentType) {
      console.error("[MTR UI] contentRequested needs a valid contentType")
    }
    const dummyPassLine = JSON.stringify({ pass_lines: [] })

    let rawShapeData
    if (contentType === SCUI_TYPES.Passdet) {
      rawShapeData = Slave.shape ? JSON.stringify({ pass_lines: Slave.shape.pass_lines }) : dummyPassLine
    } else {
      rawShapeData = JSON.stringify(Slave.shape)
    }

    if (rawShapeData !== "null" && rawShapeData) {
      preconfig.content.defaultImport = [
        contentType,
        contentType === SCUI_TYPES.Detfilter ? `"config_data":${rawShapeData}` : rawShapeData
      ]
    }

    preconfig.content.defaultBackground.imageSrc = "data:image/jpg;base64," + imageInBase64


    // posting message to SCUI IFrame
    window.scuiIframe.contentWindow.postMessage(preconfig, "*")
  }

  static changeBackground(imageInBase64) {
    const e = {
      event: EVENT.SCUI.sendBackground
    }

    if (imageInBase64) {
      // noinspection UnnecessaryLocalVariableJS
      const imageSrc = "data:image/jpg;base64," + imageInBase64
      e.content = imageSrc
    } else {
      e.content = null
    }

    window.scuiIframe.contentWindow.postMessage(e, "*")
  }

  static sendBackgroundError(_error) {
    const e = {
      event: EVENT.SCUI.sendBackground,
      error: "Unable to get new snapshot."
    }

    window.scuiIframe.contentWindow.postMessage(e, "*")
  }

  static sendCloseRequest() {
    window.scuiIframe.contentWindow.postMessage({ event: EVENT.SCUI.sendCloseSignal })
  }

  static dispatchIFrameChange(shouldOpen) {
    //todo: think how its the cleaneast to wire this to ContentRoot
    Dispatcher.dispatch({
      actionType: null,
      content: shouldOpen
    })
  }

  /**
   * @param newShape
   * @param cameraId
   * @param [contentType]
   */
  static openSlaveIFrame(newShape, cameraId, image, getSnapshot, contentType = SCUI_TYPES.Detfilter) {
    Slave.dispatchIFrameChange(true)
    Slave.shape = newShape
    Slave.cameraId = cameraId
    Slave.image = image
    Slave.getSnapshot = getSnapshot
    Slave.contentType = contentType
    Slave.contentRequested(Slave.image, contentType)
    window.scuiIframe.contentWindow.postMessage({ event: EVENT.SCUI.sendOpenSignal }, "*")
  }

  static dataReceived(e) {
    const content = { config_data: e.data.exportData.config_data, cameraId: Slave.cameraId }
    ALL_FILTER_OBJECTS.forEach(element => {
      if (e.data.exportData.config_data[element]) {
        content[element] = e.data.exportData.config_data[element]
      }
    })


    Dispatcher.dispatch({
      actionType: EVENT.Slave.statusDataReceived,
      content
    })
  }
} // class Slave

/** @implements IStreamConfiguratorSlaveMode **/
const preconfig = {
  event: EVENT.SCUI.sendPreconfig,
  content: {
    windowSetup: {
      Logger: {
        isMounted: false
      },
      Elements: {
        isVisible: true,
        dockable: true,
        shouldDockByDefault: true
      },
      Source: {
        isMounted: false
      }

    },
    defaultBackground: {
      opacity: 70,
      gridSize: 80
    }
  }
}

Slave.shape = null
Slave.contentType = null
Slave.image = new Promise(() => null)
// old school event switch

window.addEventListener("message", Slave.messageReceived.bind(Slave), false)

export default Slave
