import React from 'react';
import Tooltip from "@mui/material/Tooltip/";
import {Icon} from "../ui/temporary/Icon";

const ALARM_TYPE = {
  INTRUSION_DETECTION: {
    text: "Intrusion",
    icon: "intrusion",
  },
  CROWD_DETECTION: {
    text: "Crowd",
    icon: "crowd_det",
  },
  MULTI_OBJECT_COUNTER: {
    text: "Multi Object Counter",
    icon: "multi_object_counter",
  },
  MULTI_OBJECT_DETECTION: {
    text: "Multi Object",
    icon: "multi_object_det",
  },
  ZONE_CROSSING: {
    text: "Zone crossing",
    icon: "zone_trespassing",
  },
  LOITERING_DETECTION: {
    text: "Loitering",
    icon: "loitering",
  },
  TRAFFIC_COUNTING: {
    text: "Traffic counting",
    icon: "traffic_counting",
  },
  STOPPED_DETECTION: {
    text: "Stopped",
    icon: "stopped"
  },
  WRONG_DIRECTION_DETECTION: {
    text: "Wrong direction",
    icon: "wrong-direction"
  },
  PPE_DETECTION: {
    text: "PPE detection",
    icon: "missing_ppe_detection",
  },
  RTSP_STREAM: {
    text: "Video output",
    icon: "video_output"
  },
  FALL_DETECTION: {
    text: "Fall detection",
    icon: "fall_detection"
  },
  HAZARDOUS_AREA: {
    text: "Hazardous area",
    icon: "hazardous"
  },
  METADATA_OUTPUT: {
    text: "Metadata output",
    icon: "metadata_output"
  }
}

export default function AlarmTypeIcon({type, ...props}) {
  if (!ALARM_TYPE[type]) {
    console.error("Missing Icon type for AlarmTypeIcon")
    return <span className={"alarmTypeIcon"}>
    Missing Icon for {type}
    </span>
  }
  return <Tooltip title={"Type: " + ALARM_TYPE[type].text} placement={"left"}>
    <span className={"alarmTypeIcon"}>
    <Icon path={ALARM_TYPE[type].icon}/>
    </span>
  </Tooltip>
}

export function AlarmIconCard({type, className}) {
  try {
    const realPath = require('./icons/illustration/' + ALARM_TYPE[type].icon + '.png').default;
    return <img
      loading={"lazy"}
      className={"card-img-top"}
      src={realPath}/>
  } catch (e) {
    return <img className={"card-img-top"} src={require('./icons/illustration/unknown.png').default}
                style={{background: '#444'}}/>
  }

}
