import { Alert, Button, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import SCUIUtils from "../SCUIUtils"
import { DEFAULT_ROI_CONFIG, EVENT, SCUI_PROFILE } from "../constant"
import Dispatcher from "../dispatcher"

export default function DrawRoi({ image, rois = DEFAULT_ROI_CONFIG, setRois }) {
  const [error, setError] = useState()

  useEffect(() => {
    const d = Dispatcher.register(payload => {
      if (payload.actionType === EVENT.Slave.statusDataReceived) {
        setRois({ positive_areas: payload.content.positive_areas, negative_areas: payload.content.negative_areas })
      }
    })
    return () => Dispatcher.unregister(d)
  })

  const formatAreas = number => number === 1 ? "area" : "areas"

  const getSummary = () => {
    if (!rois.positive_areas.length && !rois.negative_areas.length) {
      return "No areas are defined, entire screen is used"
    } else if (!rois.positive_areas.length) {
      return `Configured: ${rois.negative_areas.length} negative ${formatAreas(rois.negative_areas.length)}`
    } else if (!rois.negative_areas.length) {
      return `Configured: ${rois.positive_areas.length} positive ${formatAreas(rois.positive_areas.length)}`
    } else {
      return `Configured: ${rois.positive_areas.length} positive ${formatAreas(rois.positive_areas.length)} ` +
        `and ${rois.negative_areas.length} negative ${formatAreas(rois.negative_areas.length)}`
    }
  }

  const hasArea = () => {
    return rois.negative_areas.length > 0 || rois.positive_areas.length > 0
  }

  const removeAll = () => {
    setRois({ negative_areas: [], positive_areas: [] })
  }

  const refreshSnapshot = () => false

  const openSCUI = async () => {
    try {
      if (!image) {
        return
      }
      SCUIUtils.Open(rois, 0, image, refreshSnapshot, SCUI_PROFILE)
    } catch (e) {
      console.error(e)
      setError("Snapshot creating failed")
    }
  }

  return (
      <>
          <Typography variant="h6" className={"marginTop1"}>
              Draw ROI - {getSummary()}
          </Typography>
          <Button variant={"contained"} className={"action"} onClick={openSCUI}>Draw areas</Button>
          <Button variant={"contained"} className={"formSubmit action delete"} disabled={!hasArea()}
                  onClick={removeAll}>
              Remove areas
          </Button>
          {error && <><br /><br /><Alert severity="error" onClose={() => setError(undefined)}>{error}</Alert></>}

      </>
  )
}
