import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  AlertTitle,
  FormGroup,
  LinearProgress,
  Typography
} from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { resizeImageToMax, unwrapErrorMessage } from "../util"
import { Delete, FileUpload } from "@mui/icons-material"
import DrawRoi from "./DrawRoi"
import RangeSelector from "./RangeSelector"
import { DEFAULT_SIZE_FILTER, NEW_API_URL } from "../constant"
import { deleteChannelImage, getChannelImage, postChannelImage } from "../newApiClient"
import { CoverItem } from "./Cover"
import { confirmDialog } from "./temp/ConfirmDialog"

function transformRois(rois, sx, sy) {
  const adjustScale = scale => Math.abs(1-scale) < 0.0001 ? 1 : scale
  sx = adjustScale(sx)
  sy = adjustScale(sy)
  const transformSingleRoi = roi => roi.map((p) => ({x: Math.round(p.x * sx), y: Math.round(p.y * sy)}))
  return rois.map(roi => ({poly: transformSingleRoi(roi.poly)}))
}

export default function AdditionalFilters({
                                            channelId,
                                            rois,
                                            imageSize,
                                            setRois,
                                            detectionSize,
                                            setDetectionSize,
                                            setDim,
                                            setImageDimFromPreviousUpload,
                                            forceSaveOriginalForm
                                          }) {
  const [image, setImage] = useState()
  const [imageFromServer, setImageFromServer] = useState(true)
  const imageSizeRef = useRef()
  const [waiting, setWaiting] = useState()
  const [imageDim, setImageDim] = useState({})
  const [error, setError] = useState()
  const [file, setFile] = useState()
  const [save, setSave] = useState(true)
  const [reloadTk, setReloadTk] = useState(false)
  useEffect(() => {
    if (!image) {
      imageSizeRef.current = undefined
    } else {
      let im = new Image
      im.src = `data:image/jpeg;base64,${image}`
      im.onload = () => {

        const { height, width } = im
        imageSizeRef.current = { height, width }
        // for now setImageDim is not used only to push a react state update.
        setImageDim({ height, width })

        if (image) {
          setImageDimFromPreviousUpload({ height, width })
        } else {
          setImageDimFromPreviousUpload(false)
        }
      }
    }
  }, [image])

  useEffect(() => {
    setWaiting(true)
    getChannelImage(channelId)
      .then(img => {
        setImage(img)
      })
      .catch(err => setError(unwrapErrorMessage(err)))
      .finally(() => setWaiting(false))
  }, [])

  const setRoisWithImageSize = rois => {
    setDim(imageSizeRef.current)
    setRois(rois)
  }
  const transformUpload = ({width, height}) => {
    if (!imageDim || !("height" in imageDim))
      return {newRois: rois, newSizeFilter: null}
    const sw = width/imageDim.width
    const sh = height/imageDim.height
    const newRois = {
      "positive_areas": transformRois(rois["positive_areas"], sw, sh),
      "negative_areas": transformRois(rois["negative_areas"], sw, sh)
    }
    let newSizeFilter = null
    if (detectionSize) {
      newSizeFilter = {
      min: (Math.max(DEFAULT_SIZE_FILTER.min, Math.round(detectionSize.min * sh))),
      max: Math.round(detectionSize.max * sh)
      }
    }
    return {newRois, newSizeFilter}
  }
  const setDetectionSizeWithImageSize = size => {
    setDim(imageSizeRef.current)
    setDetectionSize(size)
  }

  const readFile = async () => {
    setWaiting(true)
    setError(null)

    try {
      const reader = new FileReader()
      const img = new Image()
      reader.onloadend = () => {

        let width, height
        img.src = reader.result
        img.onload = async () => {

          let base64String

          const resized = resizeImageToMax(img)
          base64String = resized.src
          width = resized.width
          height = resized.height


          if (imageDim.width) { // this is not the first image upload
            const confirmed = await confirmDialog({
              title: "Replacing Reference Image",
              message: <>Warning: Changing the image will reset the size filter
                settings and may affect the ROI settings. <br />
                Double check the ROI and size settings after a new image has been uploaded.
                This action will also reset the camera settings form to the last saved version,
                and all current changes will be lost.
                <br />
                Please save your work before uploading a new image.

              </>,
              cancelText: "Cancel upload",
              okText: "I understand, upload image"
            })
            if (!confirmed) {
              setWaiting(false)
              return false
            }

          }
          const {newRois, newSizeFilter} = transformUpload({width, height})
          setImage(null)
          base64String = base64String.replace("data:", "").replace(/^.+,/, "")
          const uint = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0))

          if (save) {
            postChannelImage(channelId, uint)
              .then(_ => {
                setImage(base64String)
                setRoisWithImageSize(newRois)
                setDetectionSize(newSizeFilter)
                setImageFromServer(true)
                setReloadTk(true)
                setTimeout(() => {
                  setReloadTk(false)
                }, 2000)
                forceSaveOriginalForm({ height, width, newRois, newSizeFilter })
              })
              .catch(err => {
                setError(unwrapErrorMessage(err))
              })
              .finally(() => {
                setWaiting(false)
              })
          } else {
            setImageFromServer(false)
            setImage(base64String)
          }

          setDim({ height, width })
        }

      }

      img.onerror = err => {
        setWaiting(false)
        console.error(err)
        setError("Cannot upload: unknown or invalid image file.")
      }
      reader.onerror = err => {
        setWaiting(false)
        setError(unwrapErrorMessage(err))
      }
      reader.readAsDataURL(file)
    } catch
      (err) {
      setError(unwrapErrorMessage(err))
    } finally {
      setWaiting(false)
    }
  }

  const resetFileInput = evt => evt.target.value = null

  const removeImage = async () => {
    setWaiting(true)
    try {
      await deleteChannelImage(channelId)
      setImage(null)
    } catch (err) {
      setError(unwrapErrorMessage(err))
    } finally {
      setWaiting(false)
    }
  }

  const isDetectionSizeSet = () => {
    return detectionSize && (detectionSize.min !== DEFAULT_SIZE_FILTER.min || detectionSize.max !== DEFAULT_SIZE_FILTER.max)
  }

  const displayCurrentValue = () => {
    if (!isDetectionSizeSet()) {
      return ` - Default: (min: ${DEFAULT_SIZE_FILTER.min}, max: ${DEFAULT_SIZE_FILTER.max})`
    } else {
      return ` - Configured: (min: ${detectionSize.min}, max: ${detectionSize.max})`
    }
  }

  return (
    <>
      <CoverItem>
        <h4>Additional filters</h4>

        <div>

          <>

            <Alert severity={"info"}>
              <AlertTitle>
                Current filters
              </AlertTitle>
              {imageSize && <>
                Image size: {imageSize.width}px × {imageSize.height}px (width × height)<br />
              </>}
              Minimum detection size: {detectionSize?.min || "Unknown "}px,
              Maximum detection size: {detectionSize?.max || "Unknown "}px<br />
              Positive areas: {rois?.positive_areas?.length}
              {rois?.positive_areas?.length === 0 && " (entire screen is used)"}
              ,
              Negative areas: {rois?.negative_areas?.length}<br />
              {
                !image && <>To change current filters, upload a reference image.</>
              }
              <br />Maximum image upload size is 3840px × 3840px.
            </Alert>
            <br />

          </>
          {image && <><br />Current image</>}
          {image &&
            <Button className={"action paddingL1 delete small"} variant="contained" onClick={removeImage}
                    disabled={!image}>
              <Delete /> Delete image
            </Button>
          }
        </div>

        <p>
          {image && (

            imageFromServer ? (reloadTk ? <>Loading new image...</> :
                  <img className={"middleImg"}
                       alt={"new image"}
                       src={`${NEW_API_URL}/channels/${channelId}/setup_image?r=${Math.random()}`} />
              ) :
              <>

                <img src={"data:image/jpg;base64," + image} className={"middleImg"}
                     alt={"middle image"} />
              </>
          )

          }

        </p>

        <Typography variant="h6" className={"marginTop1"}>
          {image ? "Change" : "Upload"} image
        </Typography>

        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={save} onChange={evt => setSave(evt.target.checked)} />}
            label="Save image on the server (makes later changes to ROI and Size easier.)" />
        </FormGroup>
        <Button className={"formSubmit action"}
                color={"primary"}
                component="label"
                variant="outlined">
          select image
          <input hidden type="file" onClick={resetFileInput}
                 onChange={event => setFile(event.target.files[0])} />
        </Button>

        <Button className={"formSubmit action"}
                variant="contained"
                disabled={!file}
                onClick={readFile}>
          <FileUpload /> Upload</Button>

        {waiting && <div className={"progressBar"}><LinearProgress /></div>}
        {error && <><br /><br /><Alert severity="error" onClose={() => setError(undefined)}>{error}</Alert></>}

        {image ? (
          <>
            <DrawRoi image={image}
                     setRois={setRoisWithImageSize}
                     rois={rois} />
            <Typography variant="h6" className={"marginTop1"}>
              Set detection size filter {displayCurrentValue()}
            </Typography>
            <RangeSelector detectionSize={detectionSize} onChange={setDetectionSizeWithImageSize}
                           image={image} isSet={isDetectionSizeSet()}
                           width={imageSizeRef.current?.width} height={imageSizeRef.current?.height} />
          </>
        ) : <>
        </>

        }


      </CoverItem>
    </>
  )
}
