import React, {useEffect, useRef, useState} from 'react';
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slider,
  Typography,
  Tooltip
} from "@mui/material";
import {faCrosshairs, faLineHeight, faSyncAlt} from "@fortawesome/pro-regular-svg-icons";

import {CtaButton} from "../ui/temporary/CtaButton";
import Shape from "../classes/Shape";
import DuotoneIcon from "../ui/temporary/DuotoneIcon";
import {LearnMore} from "../ui/temporary/LearnMore";
import Constant from "../constant";

let minObj, maxObj;

export default function RangeSelector({
                                        detectionSize,
                                        defaultDetectionSize,
                                        onChange,
                                        open,
                                        getSnapshotRegenerateCurrent,
                                        width,
                                        height,
                                        alertType
                                      }) {

  const lowerLimit = defaultDetectionSize.min;
  const upperLimit = Math.min(defaultDetectionSize.max, height);

  if (!detectionSize) return null;
  const showHorizontalHints = Constant.rangeSelector.showHorizontalHints.includes(alertType);
  const [show, setShow] = useState(false);
  const [snapshot, setSnapshot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSnapshot, setLoadingSnapshot] = useState(false);
  const canvas = useRef();
  const imageCv = useRef();

  let dragTarget = null;
  let startX = null;
  let startY = null;
  let ratio = null;
  let imgObj;

  useEffect(() => {
    if (show) {
      setInitialCanvas();
    }
    minObj = new Shape(Shape.POS_PERCENTAGE.min, canvas, detectionSize.min, Shape.CONFIG.min, width, height, showHorizontalHints);
    maxObj = new Shape(Shape.POS_PERCENTAGE.max, canvas, detectionSize.max, Shape.CONFIG.max, width, height, showHorizontalHints);
  }, [show])

  const handleSizeChange = (event, newValue) => {
    minObj.size = newValue[0];
    maxObj.size = Math.min(newValue[1], upperLimit);
    draw();
  }

  const save = () => {
    onChange({min: minObj.size, max: maxObj.size})
    handleClose();
  }

  const recenter = () => {
    minObj.initializePosition()
    maxObj.initializePosition()
    draw();
  }

  const handleOpen = async () => {
    setLoading(true);
    const snap = await open();
    setLoading(false);
    if (snap) {
      setSnapshot(snap);
      setShow(true);
    }
  }

  const handleClose = () => {
    setShow(false);
  }

  const setInitialCanvas = (numOfTries = 1) => {
    const imageCvElem = imageCv.current;
    const dragCvElem = canvas.current;
    if (!imageCvElem || !dragCvElem) {
      if (numOfTries < 5) {
        setTimeout(() => setInitialCanvas(numOfTries++), 100);
      }
      return;
    }

    setInitialImage();

  }

  const setInitialImage = (newSnapshot = snapshot) => {
    const imageCvElem = imageCv.current;
    const dragCvElem = canvas.current;
    const ctx = imageCvElem.getContext("2d");
    imgObj = new Image();

    imgObj.onload = function () {
      const ratio = imageCvElem.width / imgObj.width;
      const height = imgObj.height * ratio;
      imageCvElem.height = height;
      dragCvElem.height = height;
      ctx.drawImage(imgObj, 0, 0, imgObj.width, imgObj.height, 0, 0, imageCvElem.width, height);
      recenter();
    };
    draw();
    imgObj.src = "data:image/png;base64," + newSnapshot;
  }

  const draw = () => {
    const imageElem = imageCv.current;
    const canvasElem = canvas.current;
    if (!canvasElem || !imageElem) {
      return;
    }

    const ctx = canvasElem.getContext("2d");
    ctx.clearRect(0, 0, canvasElem.width, canvasElem.height);

    maxObj.draw(showHorizontalHints);
    minObj.draw(showHorizontalHints);

  }

  const handleMouseDown = e => {
    startX = Number(e.nativeEvent.offsetX - canvas.current.clientLeft);
    startY = Number(e.nativeEvent.offsetY - canvas.current.clientTop);
    if (minObj.isInHitbox(startX, startY)) {
      dragTarget = minObj
    } else if (maxObj.isInHitbox(startX, startY)) {
      dragTarget = maxObj
    }
  }
  const handleMouseMove = e => {
    if (!dragTarget) {
      return;
    }
    const mouseX = Number(e.nativeEvent.offsetX - canvas.current.clientLeft);
    const mouseY = Number(e.nativeEvent.offsetY - canvas.current.clientTop);
    const dx = mouseX - startX;
    const dy = mouseY - startY;
    startX = mouseX;
    startY = mouseY;
    dragTarget.x += dx;
    dragTarget.y += dy;
    draw();
  }
  const handleMouseUp = e => {
    dragTarget = null;
  }
  const handleMouseOut = e => {
    handleMouseUp(e);
  }
  const requestSnapshot = async () => {
    try {
      setLoadingSnapshot(true);
      const newSnapshot = await getSnapshotRegenerateCurrent()
      setSnapshot(newSnapshot);
      setInitialImage(newSnapshot);
    } catch (e) {
      alert('Cannot Get new Image');
    } finally {
      setLoadingSnapshot(false);
    }

  }

  const Button = () => {
    const missingLimits = lowerLimit === null || upperLimit === null;
    return (<React.Fragment>
        &nbsp;
        <CtaButton icon={faLineHeight} onClick={handleOpen}
                   disabled={missingLimits}
                   className={missingLimits ? "" : "gradient-bg"}>Set object size
          &nbsp;
          {loading && <CircularProgress className="smallCircProgress"/>}
        </CtaButton>
        {missingLimits && <React.Fragment>
          <br/><br/>
          <Alert variant={'filled'} severity={"error"}>
            There is no information about default detection sizes, please try to reload the page.</Alert>
        </React.Fragment>}
      </React.Fragment>
    )
  }

  if (!show) {
    return <Button/>
  }

  return <React.Fragment>
    <Button/>
    <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={show}
            className={"fullDialog multilineBottom"}>
      <DialogContent>
        <Typography variant={"h5"} className={"montserrat-bold"}>
          Set smallest and largest object height {showHorizontalHints && "and width"}
        </Typography>

        <div className={"rangeSelectorWrapper grabCursor"}>
          <canvas id={"RangeSelectorImage"}
                  ref={imageCv}
                  {...Shape.CANVAS_DIM}
          />
          <canvas id={"RangeSelectorCanvas"}
                  ref={canvas}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseOut={handleMouseOut}
                  {...Shape.CANVAS_DIM}
          />
        </div>

      </DialogContent>
      <DialogActions id={"objectSizeAction"}>
        <p>Drag <i>min</i> and <i>max</i> size indicators anywhere on the picture.
          Use the slider below to set sizes.
          <LearnMore docUrl={`${Constant.ALARM_DOC_URL}`}
                     inline={true}/>
        </p>

        <span className={"rangeSelectorFooter"}>
          <span className={"rangeSelectorButtons"}>
            <Tooltip title="Restore position of indicators">
              <IconButton className={"cta"} onClick={recenter}>
                <DuotoneIcon icon={faCrosshairs}/>
              </IconButton>
            </Tooltip>
            &nbsp;
            <Tooltip title="Refresh camera image">
            <IconButton className={"cta"} onClick={requestSnapshot}
                        disabled={loadingSnapshot}>
                <DuotoneIcon icon={faSyncAlt}/>
            </IconButton>
            </Tooltip>
          </span>
          <span className={"rangeSelectorSlider"}>
            <Slider
              defaultValue={[detectionSize.min, detectionSize.max]}
              onChange={handleSizeChange}
              valueLabelDisplay="auto"
              min={lowerLimit}
              max={upperLimit}
            />
          </span>
          <span className={"rangeSelectorButtons"}>
            <CtaButton onClick={save} className={"gradient-bg"}>
              Set sizes
            </CtaButton>
            &nbsp;
            <CtaButton onClick={handleClose}>
              Discard
            </CtaButton>
          </span>
        </span>
      </DialogActions>
    </Dialog>
  </React.Fragment>
}
