import { Link, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom"
import { Button, Checkbox, Divider, FormControlLabel, Paper, Typography } from "@mui/material"
import TextInput from "../../component/TextInput"
import { confirmDialog, confirmDialogCtrlSkippable, promptDialog } from "../../component/temp/ConfirmDialog"
import { deleteUser, modifyUser, patchPw } from "../../adminApiClient"
import Dispatcher from "../../dispatcher"
import { useEffect, useState } from "react"
import unwrapErrorFromApi from "../../component/UnwrapErrorApi"
import store from "../../store"

const getAuthMethod = method => {
    switch (method) {
        case "GOOGLE" :
            return "google account"
        case "MICROSOFT" :
            return "microsoft account"
        case "LOCAL" :
            return "local user"
        default:
            return "some unknown way"
    }
}
export default function SingleUser() {
    const [users] = useOutletContext()
    let { userId } = useParams()
    const path = useLocation().pathname?.split("/")
    const navigate = useNavigate()
    const [isAdmin, setIsAdmin] = useState(false)
    const [currentUser, setCurrentUser] = useState(users && users?.find(item => item.id === Number(path[path.length - 1])))


    useEffect(() => {
        setCurrentUser(users && users?.find(item => item.id === Number(path[path.length - 1])))
    }, [userId])

    useEffect(() => {
        setIsAdmin(currentUser?.roles?.includes("ADMIN"))
    }, [currentUser])

    const _returnAndUpdate = () => {
        Dispatcher.dispatch({ contentType: "GENERIC_RELOAD_PARENT" })
        navigate("/admin/users")
    }

    const setValue = name => evt => {
        setCurrentUser(state => {
            return { ...state, [name]: evt.target.value }
        })
    }

    const _setValueBase = name => value => {
        setCurrentUser(state => {
            return { ...state, [name]: value }
        })
    }

    const callDeleteUser = async evt => {
        const confirmed = await confirmDialogCtrlSkippable({
            message: <>This action will delete the user, and cannot be undone. <br />
                Associated Accounts will not be removed.
            </>,
            title: "Delete User?"
        }, evt)
        if (!confirmed) return

        deleteUser(currentUser.id)
            .then(() => {
                Dispatcher.dispatch({ contentType: "GENERIC_RELOAD_PARENT" })
                navigate(-1)
            })
          .catch(_ => {
                confirmDialog({ title: "Error", message: "Could not delete user.", noCancel: true })
            })
    }

    const apiModifyUser = evt => {
        const query = {
            ...currentUser,
            roles: isAdmin ? ["ADMIN", "CONFIGURATOR"] : ["CONFIGURATOR"]
        }
        modifyUser(userId, query)
            .then(() => {
                Dispatcher.dispatch({ contentType: "GENERIC_RELOAD_PARENT" })
                navigate("..")
            })
            .catch(error => {
                unwrapErrorFromApi(error)
            })
        evt.preventDefault()
    }

    const changePw = async () => {
        const newPw = await promptDialog({
            title: "Set new password",
            message: "Override current password for user.",
            inputProps: {
                minLength: 8,
                required: true,
                autoFocus: true,
                type: "password"
            }
        })
        if (!newPw) return

        patchPw(userId, { password: newPw })
            .then(console.log)
            .catch(unwrapErrorFromApi)
    }

    if (!users) {
        return <>
            <br />
            Users are loading...
        </>
    }
    const isEditingSelf = store.user.id === currentUser.id

    return <>
        <Paper className={"rightDrawer"}>
            <form onSubmit={apiModifyUser}>
                <Typography variant={"h5"}>
                    Editing{" "}
                    {currentUser.display_name || currentUser.email}

                </Typography>
                <br />
                <div className={"sizeloadedBtns"}>
                    <Button variant={"contained"} type={"submit"} onClick={apiModifyUser}>
                        Save
                    </Button>
                    <Link to={"/admin/users"} className={"noa"}>
                        <Button variant={"outlined"} color="primary">
                            Cancel
                        </Button>
                    </Link>
                </div>

                <br />
                <p>
                    User is authenticated using {getAuthMethod(currentUser.provider)}
                    .
                </p>
                <br />
                <TextInput label="Internal ID *" disabled size={"small"} className={"w100"}
                           value={currentUser.id} />
                <br /> <br />
                <TextInput label="Email Address *" disabled size={"small"} className={"w100"}
                           value={currentUser.email || ""} type={"email"} />
                <br /><br />
                <TextInput label="Username *" onChange={setValue("name")} className={"w100"}
                           value={currentUser.name} />
                <br /><br />

                {currentUser.provider === "LOCAL" &&
                    <div className={"sizeloadedBtns"}>
                        <Button variant={"outlined"} size={"small"}
                                onClick={changePw}
                                color={"primary"}>
                            set new password
                        </Button>
                    </div>
                    //
                    // <TextInput label="password (leave blank for no change)" onChange={setValue("password")}
                    //            value={currentUser.password || ""} type={"password"}/>
                }

                <br />
                <Divider />
                <br />
                {
                    !isEditingSelf ? <>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox onChange={() => setIsAdmin(state => !state)}
                                               checked={isAdmin || false} />}
                            label="Administrator User"
                        />
                    </> : <>
                        This is the currently logged in user. To change user privileges or delete account, please
                        edit this user from a different account.
                    </>
                }
                <br />
                <br />
                {/*{currentUser?.disabledAt &&*/}
                {/*  <><TextInput label="Account Disabled At" disabled size={"small"}*/}
                {/*               value={currentUser.disabledAt}/>*/}
                {/*    <br/><br/>*/}
                {/*  </>*/}
                {/*}*/}

                {/*<TextInput label="Account Created At" disabled size={"small"}*/}
                {/*           value={currentUser.createdAt}/>*/}
                {/*<br/>*/}
                {/*<br/>*/}
                <Divider />
            </form>
            <br />
            <div className={"sizeloadedBtns"}>
                <Link to={`link`} className={"noa"}>
                    <Button variant={"outlined"}
                            className={"wide"} color={"primary"}>
                        Edit associated Accounts
                    </Button>
                </Link>

                {
                    !isEditingSelf && <Button variant={"outlined"} onClick={callDeleteUser}
                                              className={"wide"} color={"error"}>
                        Delete User
                    </Button>
                }

            </div>

        </Paper>

    </>
}
