export default {
  PAGE: {
    SITE: "SITE",
    SITE_LIST: "SITE_LIST",
    CHANNEL: "CHANNEL",
    CHANNEL_LIST: "CHANNEL_LIST",
    STATS: "STATS",
    ACCOUNT_SELECTOR: "ACCOUNT_SELECTOR"
  },
  INTEGRATION: { PLAINMAILWIMAGE: "PLAINMAILWIMAGE", IMMIX: "IMMIX", WINGUARD: "WINGUARD", BLACKHOLE: "BLACKHOLE" },
  SENSITVITY: { VERY_LOW: "VERY LOW", LOW: "LOW", MEDIUM: "MEDIUM", HIGH: "HIGH", VERY_HIGH: "VERY HIGH", OFF: "OFF" },
  DETECTION_TYPE: {
    PERSON: "person",
    CAR: "car",
    TRUCK: "truck",
    BUS: "bus",
    MOTORCYCLE: "motorcycle",
    BICYCLE: "bicycle",
    TRAIN: "train",
    BOAT: "boat",
    AIRPLANE: "airplane"
  },
  ACTION_KEY: {
    BACK_TO_GROUP_LIST: "BACK_TO_GROUP_LIST",
    CREATE_SITE: "CREATE_SITE",
    EDIT_SITE: "EDIT_SITE",
    CREATE_CHANNEL: "CREATE_CHANNEL",
    EDIT_CHANNEL: "EDIT_CHANNEL",
    VIEW_ALL_CHANNELS: "VIEW_ALL_CHANNELS",
    VIEW_ALL_ACCOUNTS: "VIEW_ALL_ACCOUNTS"
  },
  ROLES: {
    ADMIN: "ADMIN",
    CONFIGURATOR: "CONFIGURATOR"
  }
}
