import React, {useEffect} from "react";
import Switch from '@mui/material/Switch/';
import FormGroup from '@mui/material/FormGroup/';
import FormControl from '@mui/material/FormControl/';
import FormControlLabel from '@mui/material/FormControlLabel/';
import formDataStore from "../FormDataStore";
import Dispatcher from "../../dispatcher";
import Constant from "../../constant";
import {JSUtil} from "jsutil";

export default function Bool({id, propertyElement, path}) {

  if (!propertyElement) {
    console.error("No property Element Assigned");
    return "";
  }


  const [on, setOn] = React.useState(formDataStore.getByPath(path));
  const [basePath, _] = React.useState(JSUtil.deepCopy(path));

  useEffect(() => {
    const dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED || payload.contentType === Constant.FLUX.FORM_DATA_CHANGED) {
        const currentValue = formDataStore.getByPath(basePath);
        // if (currentValue !== on) {
        setOn(currentValue)
        // }
      }
    })
    return () => {
      Dispatcher.unregister(dispatcher);
    };
  }, [on, setOn])

  const onChange = evt => {
    setOn(evt.target.checked);
    formDataStore.saveToPath(path, evt.target.checked)
  }

  const label = () => {
    if (propertyElement.description) {
      //return <abbr title={propertyElement.description}>{propertyElement.title}</abbr>
      return <span className={"propertyDescription"}>
        {propertyElement.title}<br/>
        <i >
          {propertyElement.description}
        </i>
      </span>
    } else {
      return propertyElement.title
    }
  }

  return (
    <React.Fragment>
      <br/>
      <FormGroup className={"marginLeft05em"}>
        <FormControl variant="outlined" className={"formControl"}>
          <FormControlLabel
            control={<Switch checked={on || false}
                             onChange={onChange}
                             name={propertyElement.title || "Unkown name"}
                             color={"primary"}/>}
            label={label()}
          />
        </FormControl>
      </FormGroup>
    </React.Fragment>
  )
}
