import { NX_INTEGRATION_NAME } from "../constant"
let SINGLE_SITE_SCHEMA;
export default SINGLE_SITE_SCHEMA = {
    "$schema": "http://json-schema.org/draft-04/schema#",
    "title": "Properties",
    "type": "object",
    "additionalProperties": false,
    "properties": {
// "input": {
//   "description": "Do not define ROIs for moving cameras.",
//   "title": "Camera",
//   "type": "object",
//   "additionalProperties": false,
//   "properties": {
//     "camera_id": {
//       "type": "string"
//     },
//     "filter": {
//       "type": "object",
//       "additionalProperties": false,
//       "properties": {
//         "id": {
//           "type": "string"
//         },
//         "confidence_sensitivity": {
//           "type": "string",
//           "default": "Medium (recommended)",
//           "enum": [
//             "Very Low",
//             "Low",
//             "Medium (recommended)",
//             "High",
//             "Very High"
//           ],
//           "description": "Confidence limit of the detections.",
//           "title": "Sensitivity"
//         },
//         "detection_size": {
//           "title": "Detection size",
//           "type": "object",
//           "additionalProperties": false,
//           "properties": {
//             "min": {
//               "type": "integer"
//             },
//             "max": {
//               "type": "integer"
//             }
//           },
//           "required": [
//             "min",
//             "max"
//           ]
//         },
//         "positive_areas": {
//           "type": "array",
//           "items": {
//             "type": "object",
//             "additionalProperties": false,
//             "properties": {
//               "poly": {
//                 "type": "array",
//                 "items": {
//                   "type": "object",
//                   "additionalProperties": false,
//                   "properties": {
//                     "x": {
//                       "type": "integer"
//                     },
//                     "y": {
//                       "type": "integer"
//                     }
//                   },
//                   "required": [
//                     "x",
//                     "y"
//                   ]
//                 }
//               }
//             }
//           }
//         },
//         "negative_areas": {
//           "type": "array",
//           "items": {
//             "type": "object",
//             "additionalProperties": false,
//             "properties": {
//               "poly": {
//                 "type": "array",
//                 "items": {
//                   "type": "object",
//                   "additionalProperties": false,
//                   "properties": {
//                     "x": {
//                       "type": "integer"
//                     },
//                     "y": {
//                       "type": "integer"
//                     }
//                   },
//                   "required": [
//                     "x",
//                     "y"
//                   ]
//                 }
//               }
//             }
//           }
//         }
//       },
//       "required": [
//         "confidence_sensitivity",
//         "detection_size"
//       ]
//     }
//   }
// },
// "tolerance_time_sec": {
//   "type": "integer",
//   "minimum": 5,
//   "maximum": 15,
//   "default": 5,
//   "description": "How long to wait before the solution is triggered (in seconds)",
//   "title": "Tolerance time"
// },
        "http_webhook_config": {
            "type": "object",
            "additionalProperties": false,
            "properties": {

                "enabled": {
                    "type": "boolean",
                    "default": false,
                    "title": "HTTP Webhook"
                },
                "url": {
                    "type": "string",
                    "default": "",
                    "title": "Target URL (required)"
                },
                "headers": {
                    "type": "object",
                    "additionalProperties": {
                        "type": "string"
                    },
                    "default": {},
                    "description": "Custom HTTP headers.",
                    "title": "Headers"
                },
// "trust_all_certs": {
//   "type": "boolean",
//   "default": false,
//   "description": "If enabled, HTTPS certification errors will be ignored.",
//   "title": "Trust all certificates"
// },
                "attach_snapshot": {
                    "type": "boolean",
                    "default": false,
                    "description": "If enabled, the image that triggered the event will be provided in the `img_url` field, in base64 encoding.",
                    "title": "Send snapshot image"
                },
                // "send_gps": {
                //   "type": "boolean",
                //   "default": false,
                //   "description": "If enabled, the GPS Coordinates of the channel will be sent in the `geolocation` object field , if any are set.",
                //   "title": "Send GPS coordinates"
                // },
                // "event_description": {
                //   "type": "boolean",
                //   "default": false,
                //   "description": "If enabled, a detailed description will be provided in the `message` field",
                //   "title": "Send event description"
                // }
            },
            "required": [
                // "url",
                "enabled"
            ]
        },

        "nx_integration_config": {
            "type": "object",
            "additionalProperties": false,
            "properties": {
                "enabled": {
                    "type": "boolean",
                    "default": false,
                    "title": NX_INTEGRATION_NAME
                },
                // "offset": {
                //   "type": "integer",
                //   "default": 0,
                //   "title": "Timestamp offset"
                // },
                "trust_all_certs": {
                    "type": "boolean",
                    "default": false,
                    "title": "Trust all certificates"
                },
                "host": {
                    "type": "string",
                    "title": " Hostname",
                    "required": true,
                },
                "port": {
                    "type": "integer",
                    "default": 7001,
                    "title": "Port",
                    "required": true,
                },
                "username": {
                    "type": "string",
                    "title": "Username"
                },
                "password": {
                    "type": "string",
                    "title": "Password",
                    "fieldType": "password"
                },
            },
            "required": [
                "enabled",
                "offset",
                "host",
                "port"
            ]
        }
// },

// "smtp_based_integration": {
//   "type": "object",
//   "additionalProperties": false,
//   "properties": {
//     "enabled": {
//       "type": "boolean",
//       "default": false,
//       "title": "SMTP or Central Station Monitoring software"
//     },
//     "selected_smtp_client": {
//       "type": "string",
//       "default": "Email",
//       "enum": [
//         "Email",
//         "IMMIX",
//         "Sentinel",
//         "SureView"
//       ]
//     },
//     "email_notification_config": {
//       "title": "Email",
//       "type": "object",
//       "additionalProperties": false,
//       "properties": {
//         "recipients": {
//           "type": "array",
//           "items": {
//             "type": "string"
//           },
//           "title": "Recipients of email"
//         },
//         "attach_snapshot": {
//           "type": "boolean",
//           "default": false,
//           "description": "If enabled, solution cooldown must be at least 10 seconds.",
//           "title": "Enable snapshot attach"
//         },
//         "attach_video": {
//           "type": "boolean",
//           "default": false,
//           "description": "If enabled, solution cooldown must be at least 10 seconds.",
//           "title": "Enable video attach"
//         }
//       },
//       "required": [
//         "attach_snapshot",
//         "attach_video"
//       ]
//     },
//     "sentinel_client_config": {
//       "title": "Sentinel",
//       "type": "object",
//       "additionalProperties": false,
//       "properties": {
//         "site_id": {
//           "type": "string",
//           "title": "Target siteID"
//         },
//         "attach_snapshot": {
//           "type": "boolean",
//           "default": false,
//           "description": "If enabled, solution cooldown must be at least 10 seconds.",
//           "title": "Enable snapshot attach"
//         },
//         "attach_video": {
//           "type": "boolean",
//           "default": false,
//           "description": "If enabled, solution cooldown must be at least 10 seconds.",
//           "title": "Enable video attach"
//         }
//       },
//       "required": [
//         "attach_snapshot",
//         "attach_video"
//       ]
//     },
//     "immix_client_config": {
//       "title": "IMMIX",
//       "type": "object",
//       "additionalProperties": false,
//       "properties": {
//         "recipient": {
//           "type": "string",
//           "format": "email",
//           "title": "Target recipient"
//         },
//         "attach_snapshot": {
//           "type": "boolean",
//           "default": false,
//           "description": "If enabled, solution cooldown must be at least 10 seconds.",
//           "title": "Enable snapshot attach"
//         },
//         "attach_video": {
//           "type": "boolean",
//           "default": false,
//           "description": "If enabled, solution cooldown must be at least 10 seconds.",
//           "title": "Enable video attach"
//         },
//         "send_end_of_event": {
//           "type": "boolean",
//           "default": false,
//           "description": "Also sent event at the end of the incident.",
//           "title": "Send end of event"
//         }
//       },
//       "required": [
//         "attach_snapshot",
//         "attach_video",
//         "send_end_of_event"
//       ]
//     },
//     "sureview_client_config": {
//       "title": "SureView",
//       "type": "object",
//       "additionalProperties": false,
//       "properties": {
//         "recipient": {
//           "type": "string",
//           "format": "email",
//           "title": "Target recipient"
//         },
//         "attach_snapshot": {
//           "type": "boolean",
//           "default": false,
//           "description": "If enabled, solution cooldown must be at least 10 seconds.",
//           "title": "Enable snapshot attach"
//         },
//         "attach_video": {
//           "type": "boolean",
//           "default": false,
//           "description": "If enabled, solution cooldown must be at least 10 seconds.",
//           "title": "Enable video attach"
//         },
//         "send_end_of_event": {
//           "type": "boolean",
//           "default": false,
//           "description": "Also sent event at the end of the incident.",
//           "title": "Send end of event"
//         }
//       },
//       "required": [
//         "attach_snapshot",
//         "attach_video",
//         "send_end_of_event"
//       ]
//     }
//   },
//   "required": [
//     "enabled"
//   ]
// }
    },
    "required": [
        "tolerance_time_sec"
    ],
    "definitions": {
        "Input(AreaFilter)": {
            "type": "object",
            "additionalProperties": false,
            "properties": {
                "camera_id": {
                    "type": "string"
                },
                "filter": {
                    "type": "object",
                    "additionalProperties": false,
                    "properties": {
                        "id": {
                            "type": "string"
                        },
                        "confidence_sensitivity": {
                            "type": "string",
                            "default": "Medium (recommended)",
                            "enum": [
                                "Very Low",
                                "Low",
                                "Medium (recommended)",
                                "High",
                                "Very High"
                            ],
                            "description": "Confidence limit of the detections.",
                            "title": "Sensitivity"
                        },
                        "detection_size": {
                            "title": "Detection size",
                            "type": "object",
                            "additionalProperties": false,
                            "properties": {
                                "min": {
                                    "type": "integer"
                                },
                                "max": {
                                    "type": "integer"
                                }
                            },
                            "required": [
                                "min",
                                "max"
                            ]
                        },
                        "positive_areas": {
                            "type": "array",
                            "items": {
                                "type": "object",
                                "additionalProperties": false,
                                "properties": {
                                    "poly": {
                                        "type": "array",
                                        "items": {
                                            "type": "object",
                                            "additionalProperties": false,
                                            "properties": {
                                                "x": {
                                                    "type": "integer"
                                                },
                                                "y": {
                                                    "type": "integer"
                                                }
                                            },
                                            "required": [
                                                "x",
                                                "y"
                                            ]
                                        }
                                    }
                                }
                            }
                        },
                        "negative_areas": {
                            "type": "array",
                            "items": {
                                "type": "object",
                                "additionalProperties": false,
                                "properties": {
                                    "poly": {
                                        "type": "array",
                                        "items": {
                                            "type": "object",
                                            "additionalProperties": false,
                                            "properties": {
                                                "x": {
                                                    "type": "integer"
                                                },
                                                "y": {
                                                    "type": "integer"
                                                }
                                            },
                                            "required": [
                                                "x",
                                                "y"
                                            ]
                                        }
                                    }
                                }
                            }
                        }
                    },
                    "required": [
                        "confidence_sensitivity",
                        "detection_size"
                    ]
                }
            }
        },
        "AreaFilter": {
            "type": "object",
            "additionalProperties": false,
            "properties": {
                "id": {
                    "type": "string"
                },
                "confidence_sensitivity": {
                    "type": "string",
                    "default": "Medium (recommended)",
                    "enum": [
                        "Very Low",
                        "Low",
                        "Medium (recommended)",
                        "High",
                        "Very High"
                    ],
                    "description": "Confidence limit of the detections.",
                    "title": "Sensitivity"
                },
                "detection_size": {
                    "title": "Detection size",
                    "type": "object",
                    "additionalProperties": false,
                    "properties": {
                        "min": {
                            "type": "integer"
                        },
                        "max": {
                            "type": "integer"
                        }
                    },
                    "required": [
                        "min",
                        "max"
                    ]
                },
                "positive_areas": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "additionalProperties": false,
                        "properties": {
                            "poly": {
                                "type": "array",
                                "items": {
                                    "type": "object",
                                    "additionalProperties": false,
                                    "properties": {
                                        "x": {
                                            "type": "integer"
                                        },
                                        "y": {
                                            "type": "integer"
                                        }
                                    },
                                    "required": [
                                        "x",
                                        "y"
                                    ]
                                }
                            }
                        }
                    }
                },
                "negative_areas": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "additionalProperties": false,
                        "properties": {
                            "poly": {
                                "type": "array",
                                "items": {
                                    "type": "object",
                                    "additionalProperties": false,
                                    "properties": {
                                        "x": {
                                            "type": "integer"
                                        },
                                        "y": {
                                            "type": "integer"
                                        }
                                    },
                                    "required": [
                                        "x",
                                        "y"
                                    ]
                                }
                            }
                        }
                    }
                }
            },
            "required": [
                "confidence_sensitivity",
                "detection_size"
            ]
        },
        "DetectionSize": {
            "type": "object",
            "additionalProperties": false,
            "properties": {
                "min": {
                    "type": "integer"
                },
                "max": {
                    "type": "integer"
                }
            },
            "required": [
                "min",
                "max"
            ]
        },
        "DetectionArea": {
            "type": "object",
            "additionalProperties": false,
            "properties": {
                "poly": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "additionalProperties": false,
                        "properties": {
                            "x": {
                                "type": "integer"
                            },
                            "y": {
                                "type": "integer"
                            }
                        },
                        "required": [
                            "x",
                            "y"
                        ]
                    }
                }
            }
        },
        "Point": {
            "type": "object",
            "additionalProperties": false,
            "properties": {
                "x": {
                    "type": "integer"
                },
                "y": {
                    "type": "integer"
                }
            },
            "required": [
                "x",
                "y"
            ]
        },
        "HttpClientConfigWithSnapshotAndEoe": {
            "type": "object",
            "additionalProperties": false,
            "properties": {
                "enabled": {
                    "type": "boolean",
                    "default": false,
                    "title": "HTTP webhook"
                },
                "url": {
                    "type": "string",
                    "default": "",
                    "title": "Target url"
                },
                "headers": {
                    "type": "object",
                    "additionalProperties": {
                        "type": "string"
                    },
                    "description": "Custom HTTP headers.",
                    "title": "Headers"
                },
                "trust_all_certs": {
                    "type": "boolean",
                    "default": false,
                    "description": "If enabled, HTTPS certification errors will be ignored.",
                    "title": "Trust all certificates"
                },
                "attach_snapshot": {
                    "type": "boolean",
                    "default": false,
                    "description": "If enabled, the image that triggered the event will be provided in the `img_url` field, in base64 encoding.",
                    "title": "Send snapshot image"
                },
                "send_gps": {
                    "type": "boolean",
                    "default": false,
                    "description": "If enabled, the GPS Coordinates of the channel will be sent in the `geolocation` object field , if any are set.",
                    "title": "Send GPS Coordinates"
                },
                "event_description": {
                    "type": "boolean",
                    "default": false,
                    "description": "If enabled, a detailed description will be provided in the `message` field",
                    "title": "Send event description"
                }
            },
            "required": [
                "enabled",
                "trust_all_certs",
                "attach_snapshot",
                "send_end_of_event"
            ]
        },

        "NxEventConfig": {
            "type": "object",
            "additionalProperties": false,
            "properties": {
                "enabled": {
                    "type": "boolean",
                    "default": false,
                    "title": NX_INTEGRATION_NAME
                },
                "offset": {
                    "type": "integer",
                    "default": 0,
                    "title": "Timestamp offset"
                }
            },
            "required": [
                "enabled",
                "offset"
            ]
        },
        /*
            "SmtpBasedIntegration(EmailNotificationConfigWithVideo,SentinelClientConfigWithSnapshot,ImmixClientConfigWithSnapshotAndEoe,SureviewClientConfigWithSnapshotAndEoe)": {
              "type": "object",
              "additionalProperties": false,
              "properties": {
                "enabled": {
                  "type": "boolean",
                  "default": false,
                  "title": "SMTP or Central Station Monitoring software"
                },
                "selected_smtp_client": {
                  "type": "string",
                  "default": "Email",
                  "enum": [
                    "Email",
                    "IMMIX",
                    "Sentinel",
                    "SureView"
                  ]
                },
                "email_notification_config": {
                  "title": "Email",
                  "type": "object",
                  "additionalProperties": false,
                  "properties": {
                    "recipients": {
                      "type": "array",
                      "items": {
                        "type": "string"
                      },
                      "title": "Recipients of email"
                    },
                    "attach_snapshot": {
                      "type": "boolean",
                      "default": false,
                      "description": "If enabled, solution cooldown must be at least 10 seconds.",
                      "title": "Enable snapshot attach"
                    },
                    "attach_video": {
                      "type": "boolean",
                      "default": false,
                      "description": "If enabled, solution cooldown must be at least 10 seconds.",
                      "title": "Enable video attach"
                    }
                  },
                  "required": [
                    "attach_snapshot",
                    "attach_video"
                  ]
                },
                "sentinel_client_config": {
                  "title": "Sentinel",
                  "type": "object",
                  "additionalProperties": false,
                  "properties": {
                    "site_id": {
                      "type": "string",
                      "title": "Target siteID"
                    },
                    "attach_snapshot": {
                      "type": "boolean",
                      "default": false,
                      "description": "If enabled, solution cooldown must be at least 10 seconds.",
                      "title": "Enable snapshot attach"
                    },
                    "attach_video": {
                      "type": "boolean",
                      "default": false,
                      "description": "If enabled, solution cooldown must be at least 10 seconds.",
                      "title": "Enable video attach"
                    }
                  },
                  "required": [
                    "attach_snapshot",
                    "attach_video"
                  ]
                },
                "immix_client_config": {
                  "title": "IMMIX",
                  "type": "object",
                  "additionalProperties": false,
                  "properties": {
                    "recipient": {
                      "type": "string",
                      "format": "email",
                      "title": "Target recipient"
                    },
                    "attach_snapshot": {
                      "type": "boolean",
                      "default": false,
                      "description": "If enabled, solution cooldown must be at least 10 seconds.",
                      "title": "Enable snapshot attach"
                    },
                    "attach_video": {
                      "type": "boolean",
                      "default": false,
                      "description": "If enabled, solution cooldown must be at least 10 seconds.",
                      "title": "Enable video attach"
                    },
                    "send_end_of_event": {
                      "type": "boolean",
                      "default": false,
                      "description": "Also sent event at the end of the incident.",
                      "title": "Send end of event"
                    }
                  },
                  "required": [
                    "attach_snapshot",
                    "attach_video",
                    "send_end_of_event"
                  ]
                },
                "sureview_client_config": {
                  "title": "SureView",
                  "type": "object",
                  "additionalProperties": false,
                  "properties": {
                    "recipient": {
                      "type": "string",
                      "format": "email",
                      "title": "Target recipient"
                    },
                    "attach_snapshot": {
                      "type": "boolean",
                      "default": false,
                      "description": "If enabled, solution cooldown must be at least 10 seconds.",
                      "title": "Enable snapshot attach"
                    },
                    "attach_video": {
                      "type": "boolean",
                      "default": false,
                      "description": "If enabled, solution cooldown must be at least 10 seconds.",
                      "title": "Enable video attach"
                    },
                    "send_end_of_event": {
                      "type": "boolean",
                      "default": false,
                      "description": "Also sent event at the end of the incident.",
                      "title": "Send end of event"
                    }
                  },
                  "required": [
                    "attach_snapshot",
                    "attach_video",
                    "send_end_of_event"
                  ]
                }
              },
              "required": [
                "enabled"
              ]
            },
            "EmailNotificationConfigWithVideo": {
              "type": "object",
              "additionalProperties": false,
              "properties": {
                "recipients": {
                  "type": "array",
                  "items": {
                    "type": "string"
                  },
                  "title": "Recipients of email"
                },
                "attach_snapshot": {
                  "type": "boolean",
                  "default": false,
                  "description": "If enabled, solution cooldown must be at least 10 seconds.",
                  "title": "Enable snapshot attach"
                },
                "attach_video": {
                  "type": "boolean",
                  "default": false,
                  "description": "If enabled, solution cooldown must be at least 10 seconds.",
                  "title": "Enable video attach"
                }
              },
              "required": [
                "attach_snapshot",
                "attach_video"
              ]
            },
            "SentinelClientConfigWithSnapshot": {
              "type": "object",
              "additionalProperties": false,
              "properties": {
                "site_id": {
                  "type": "string",
                  "title": "Target siteID"
                },
                "attach_snapshot": {
                  "type": "boolean",
                  "default": false,
                  "description": "If enabled, solution cooldown must be at least 10 seconds.",
                  "title": "Enable snapshot attach"
                },
                "attach_video": {
                  "type": "boolean",
                  "default": false,
                  "description": "If enabled, solution cooldown must be at least 10 seconds.",
                  "title": "Enable video attach"
                }
              },
              "required": [
                "attach_snapshot",
                "attach_video"
              ]
            },
            "ImmixClientConfigWithSnapshotAndEoe": {
              "type": "object",
              "additionalProperties": false,
              "properties": {
                "recipient": {
                  "type": "string",
                  "format": "email",
                  "title": "Target recipient"
                },
                "attach_snapshot": {
                  "type": "boolean",
                  "default": false,
                  "description": "If enabled, solution cooldown must be at least 10 seconds.",
                  "title": "Enable snapshot attach"
                },
                "attach_video": {
                  "type": "boolean",
                  "default": false,
                  "description": "If enabled, solution cooldown must be at least 10 seconds.",
                  "title": "Enable video attach"
                },
                "send_end_of_event": {
                  "type": "boolean",
                  "default": false,
                  "description": "Also sent event at the end of the incident.",
                  "title": "Send end of event"
                }
              },
              "required": [
                "attach_snapshot",
                "attach_video",
                "send_end_of_event"
              ]
            },
            "SureviewClientConfigWithSnapshotAndEoe": {
              "type": "object",
              "additionalProperties": false,
              "properties": {
                "recipient": {
                  "type": "string",
                  "format": "email",
                  "title": "Target recipient"
                },
                "attach_snapshot": {
                  "type": "boolean",
                  "default": false,
                  "description": "If enabled, solution cooldown must be at least 10 seconds.",
                  "title": "Enable snapshot attach"
                },
                "attach_video": {
                  "type": "boolean",
                  "default": false,
                  "description": "If enabled, solution cooldown must be at least 10 seconds.",
                  "title": "Enable video attach"
                },
                "send_end_of_event": {
                  "type": "boolean",
                  "default": false,
                  "description": "Also sent event at the end of the incident.",
                  "title": "Send end of event"
                }
              },
              "required": [
                "attach_snapshot",
                "attach_video",
                "send_end_of_event"
              ]
            }

         */
    }
}
