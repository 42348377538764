import Page from "../../component/Page"
import Cover from "../../component/Cover"
import { useEffect, useState } from "react"
import { getNegativeEventSites } from "../../adminApiClient"
import { Grid, LinearProgress } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import Dispatcher from "../../dispatcher"


const columns = [
  {
    field: "account_name",
    headerName: "Account Name",
    width: 170
  },
  {
    field: "site_name",
    headerName: "Site Name",
    width: 170
  },
  {
    field: "negative_event_forwarding",
    headerName: "Negative Event sending",
    width: 170,
    valueGetter: (params) => {
      if (params.value) return "ON"
      else return "OFF"
    }
  }
]
export default function Dashboard() {
  function selectItem(value) {
    navigate(`/acc/${value.row.account_id}/sites`)
  }

  const navigate = useNavigate()
  const [negativeActive, setNegativeActive] = useState(false)
  const [usersByAccounts, setUsersByAccounts] = useState([])


  useEffect(() => {
    let req = getNegativeEventSites()
    req.then(value => {
      console.log(value)
      setNegativeActive(value)
    })

    const d = Dispatcher.register(payload => {
      if (payload.contentType === "GENERIC_RELOAD_PARENT") {
        getNegativeEventSites().then(value => {
          setNegativeActive(value)
        })
      }
    })
    return () => {
      Dispatcher.unregister(d)
    }
  }, [])

  return <><Page title={"Dashboard"}>
    <br />
    <Grid container className={"pageContainer"}>

      <Grid item xs={12} xl={8}>
        <Cover title={"Sites where negative events are allowed"}>
          <div id={""}>
            {negativeActive === false ? <LinearProgress /> :
              <DataGrid
                disableVirtualization
                autoHeight
                rows={negativeActive}
                getRowId={row => (row.account_id + "-" + row.site_id)}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20, 100, 500]}
                onRowClick={selectItem}
              />
            }
          </div>
        </Cover>
      </Grid>
    </Grid>
    <br /></Page>
  </>
}
