import React, {useEffect, useState, useRef} from "react";
import Dispatcher from "../../dispatcher";
import Constant from "../../constant";
import MtrContext from "../../MtrContext";
import Util from "../../util";
import formDataStore from "../FormDataStore";
import { Alert } from '@mui/material'
import CameraForm from "../../components/CameraForm";
import AddCamera from "../../components/AddCamera";
import SCUtils from "../SCUIUtils";
import FetchingIndicator from "../../ui/temporary/FetchingIndicator";

export default function SCUIWrapper({options, path, alertType, taskId = null}, context) {
  const optionsRef = useRef(options)
  const {store, getSnapshot, mtrApiClient} = React.useContext(MtrContext)
  const schema = store.schemas[alertType].properties[path[path.length - 1]];
  const defaultObjectType = store.alertTypes.find(elem => elem.type === alertType).default_object_type;
  const [value, setValue] = useState(formDataStore.getByPath(path) || []);
  const [openedInput, setOpenedInput] = useState(null);
  const [usableCameras, setUsableCameras] = useState(null);
  const [ableToChange, setAbleToChange] = useState(false);
  window.SCUIWrapperValue = value

  useEffect(() => {
    optionsRef.current = options
  }, [options])

  useEffect(() => {
    getCameraList();
  }, [alertType, taskId, value])

  const getCameraList = async () => {
    const usable = await mtrApiClient.getCameraListByTaskTypes(alertType, taskId)
    const camerasForAlarmType = store.cameras?.filter(elem => usable.includes(elem.id));
    const unusedCameras = camerasForAlarmType.filter(elem => !value.some(el => el.camera_id === elem.id));
    const showChange = !!unusedCameras.length || !!options.allowDuplicatedCamera;
    setUsableCameras(camerasForAlarmType);
    setAbleToChange(showChange);
  }

  const onChange = newValue => {
    delete newValue.errorMessage;
    delete newValue.isSnapshotLoading;
    setValue(newValue);
    window.SCUIWrapperValue = newValue;
    formDataStore.saveToPath(path, newValue)
  }

  const filterProperties = schema.items?.properties.filter.properties || schema.properties.filter.properties;
  const sensitivity = filterProperties.confidence_sensitivity;
  const objDetTypes = filterProperties.object_types;
  const objDetType = filterProperties.object_type;
  const alertTypeConfig = store.alertTypes.find(el => el.type === alertType)

  useEffect(() => {
    const d = Dispatcher.register(payload => {
      if (payload.actionType === Constant.EVENT.Slave.statusDataReceived /*todo: add limiter which checks: investigate aron*/) {
        const cameraId = payload.content.cameraId;
        const copy = SCUtils.ReceiveData(payload.content, optionsRef.current.filterObjects);
        if (copy.length) {
          for (let i = 0; i < copy.length; i++) {
            delete copy[i].errorMessage;
            delete copy[i].isSnapshotLoading;
          }
        } else { // TODO check if this is really needed
          delete copy.errorMessage;
          delete copy.isSnapshotLoading;
        }


        setValue(copy);
        formDataStore.saveToPath(path, copy)
      } else if (payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED || payload.contentType === Constant.FLUX.FORM_DATA_CHANGED) {
        const currentValue = formDataStore.getByPath(path)
        if (!Util.isObjectEqual(currentValue, value) && currentValue) {
          setValue(currentValue)
        }
      }
    });
    return () => Dispatcher.unregister(d);
  }, [])

  const createNewCameraObject = id => {
    const camera = {
      // camera detection_size is added later dynamically.
      camera_id: id,
      filter: SCUtils.emptyDetFilter(sensitivity, alertTypeConfig, options.filterObjects)
    }

    if (objDetType && defaultObjectType) {
      camera.filter.object_type = defaultObjectType;
    } else if (objDetTypes && defaultObjectType) {
      camera.filter.object_types = [defaultObjectType];
    }
    return camera;
  }

  const changeCamera = (newId, oldIdx = null) => {
    if (oldIdx !== null && newId === value[oldIdx].camera_id) {
      return;
    }
    const copy = Util.deepCopy(value);
    if (oldIdx !== null) {
      copy.splice(oldIdx, 1);
    }
    copy.push(createNewCameraObject(newId));
    setValue(copy);
    setOpenedInput(copy.length - 1);
    onChange(copy);
  }

  const clone = idx => {
    const copy = Util.deepCopy(value);
    copy.isSnapshotLoading = null;
    copy.errorMessage = null;
    const clone = Util.deepCopy(value[idx]);
    delete clone.filter.id;
    copy.push(clone);
    setValue(copy);
    setOpenedInput(copy.length - 1);
    onChange(copy);
  }

  const deleteCamera = idx => {
    const copy = Util.deepCopy(value);
    copy.splice(idx, 1);
    setValue(copy);
    onChange(copy);
  }

  const askForCameraChange = idx => {
    const id = value[idx].camera_id;
    const oldCamera = store.cameras.find(elem => elem.id === id);
    Dispatcher.dispatch({
      contentType: Constant.FLUX.CHANGE_CAMERA,
      oldCamera,
      oldIdx: idx
    })
  }


  if (!usableCameras) {
    return <FetchingIndicator text={"Loading data"} isFetching={true}/>
  }

  if (!usableCameras?.length) {
    let msg = "You have to add a camera to be able to create this Solution."
    if (Object.keys(Constant.ALARM_FPS_REQUIRED).includes(alertType)) {
      msg = `You have to add a minimum ${Constant.ALARM_FPS_REQUIRED[alertType]} fps camera to be able to create this Solution.`
    }
    return (
      <Alert variant={'filled'} severity={"error"}>{msg}</Alert>
    )
  }

  return (
    <div>
      <br/>
      {value.length === 0 && <p>No cameras are added.<br/><br/></p>}
      {
        value.map((cam, idx) => <CameraForm key={idx}
                                            camera={cam}
                                            value={value}
                                            requestTypeSCUI={options.scuiProfile}
                                            getSnapshot={getSnapshot}
                                            onChange={onChange}
                                            sensitivity={sensitivity}
                                            alertType={alertType}
                                            alertTypeConfig={alertTypeConfig}
                                            idx={idx}
                                            store={store}
                                            clone={() => clone(idx)}
                                            deleteCam={() => deleteCamera(idx)}
                                            parentPath={JSON.stringify(path)}
                                            openedInput={openedInput}
                                            setOpenedInput={setOpenedInput}
                                            allowDuplicatedCamera={options.allowDuplicatedCamera}
                                            askForCameraChange={() => askForCameraChange(idx)}
                                            showChange={ableToChange}
                                            filterObjects={options.filterObjects}
                                            filterProperties={filterProperties}
                                            getCameraById={mtrApiClient.getCameraById.bind(mtrApiClient)}
        />)
      }

      <AddCamera addCamera={changeCamera}
                 activeCameras={usableCameras}
                 allowDuplicatedCamera={options.allowDuplicatedCamera}
                 allowMultipleCamera={options.allowMultipleCamera}
                 alreadyAddedCameras={value}
                 store={store}
      />

    </div>
  )
}
