import React, {useEffect, useState} from "react";
import ObjectField from "../fields/ObjectField";
import formDataStore from "../FormDataStore";
import Bool from "../fields/Bool";
import {JSUtil} from "jsutil";
import Dispatcher from "../../dispatcher";
import Constant from "../../constant";

export default function OptionalObject({propertyElement, isRequired, idPrefix, path, options, Interactive, noWrap, alertType, widgets}) {
  let dispatcher;
  const enabledField = options.enabledField;
  const enabledPath = JSUtil.deepCopy(path);
  enabledPath.push(enabledField)

  const [enabled, setEnabled] = useState(formDataStore.getByPath(enabledPath));
  useEffect(() => {
    if (!dispatcher) {
      const dispatcher = Dispatcher.register(payload => {
        if (payload.contentType === Constant.FLUX.FORM_DATA_CHANGED || payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED) {
          const currentEnabled = formDataStore.getByPath(enabledPath);

          if (currentEnabled !== enabled) {
            setEnabled(currentEnabled);
          }
        }
      })
      return () => Dispatcher.unregister(dispatcher);
    }
  }, [setEnabled, enabled])
  const params = {propertyElement, isRequired, idPrefix, path, options, alertType, widgets};

  if (enabled) {
    return (<span className={noWrap ? "objectFieldHolder" : ""}>
      <ObjectField noWrap={noWrap} {...params} />
      {Interactive && <React.Fragment>
        <br/>
        {Interactive}
        <br/>
      </React.Fragment>}
    </span>)
  } else {
    return (
      <Bool {...params} path={enabledPath}
            propertyElement={params.propertyElement.properties[enabledField]}/>
    )
  }


}
