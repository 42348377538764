import WrappedLink from "./WrappedLink"
import Security from "./Security"
import { useRouteLoaderData, useParams } from "react-router-dom"
import { Alert } from "@mui/material"
import Enums from "../enums"

export default function Account() {
  const { accountId } = useParams()
  const accounts = useRouteLoaderData("accounts")
  const currentAccount = accounts.find(account => account.id === Number(accountId))

  if (!currentAccount) {
    return <Alert variant={"filled"} className={"shortAlert"} severity={"error"}>
        Cannot find Account. Does it exist, or do you have access to it?
    </Alert>
  }

  return <>

      <h3 className={"noTopPad"}>Account {currentAccount.name}</h3>
      <br />
      Unique ID: {currentAccount.tid}
      <br /><br />
      <WrappedLink to={"./sites"}>
          Sites in account
      </WrappedLink>

      <Security role={Enums.ROLES.ADMIN}>
          <WrappedLink to={`/admin/accounts`} admin>
              Edit accounts
          </WrappedLink>
      </Security>
  </>
}
