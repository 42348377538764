import React, {useEffect} from "react";
import TextField from '@mui/material/TextField/';
import formDataStore from "../FormDataStore";
import Dispatcher from "../../dispatcher";
import Constant from "../../constant";

export default function SimpleArray({isRequired, id, propertyElement, path}) {
  const [value, setValue] = React.useState(formDataStore.getByPath(path));
  const [errorMessage, setErrorMessage] = React.useState(null);

  useEffect(() => {
    const dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED || payload.contentType === Constant.FLUX.FORM_DATA_CHANGED) {

        const currentValue = formDataStore.getByPath(path);
        // if (currentValue !== number) {
        setValue(currentValue)
        // }
      }
    })
    return () => {
      Dispatcher.unregister(dispatcher);
    };
  }, [])

  const validate = newValue => {
    if (isRequired && newValue === "") {
      throw new Error("Required field.");
    }
    return newValue;
  }

  const onChange = evt => {
    const newValue = evt.target.value.replace(/\s+/g, '').split(",");
    setValue(newValue);

    try {
      validate(evt.target.value);
      setErrorMessage(null);
      formDataStore.removeError(path);
      formDataStore.saveToPath(path, newValue);
    } catch (err) {
      formDataStore.addError(path, err.message)
      setErrorMessage(err.message);
    }
  }

  return (
    <TextField id={id}
               label={propertyElement.title}
               type="text"
               required={isRequired}
               value={value?.toString() || ""}
               onChange={onChange}
               fullWidth
               error={!!errorMessage}
               variant={"standard"}
               helperText={`${errorMessage || ''} ${propertyElement.description || ''} (separated by comma)` }
    />
  )
}
