/**
 *
 */
const Const = {};

/** Google's Material UI Colors **/
Const.COLORS = {
  Red: '#F44336',
  Pink: '#E91E63',
  Purple: '#9C27B0',
  DPurple: '#673AB7',
  Blue: '#2196F3',
  LBlue: '#03A9F4',
  Cyan: '#00BCD4',
  Teal: '#009688',
  Green: '#4CAF50',
  LGreen: '#8BC34A',
  Lime: '#CDDC39',
  Yellow: '#FFEB3B',
  Amber: '#FFC107',
  Orange: '#FF9800',
  DOrange: '#FF5722',
  Brown: '#795548',
  Grey: '#9E9E9E',
  Metal: '#607D8B'

};
Const.COLORS.DEFAULT = 500; // default shade
Const.COLORS[Const.COLORS.DEFAULT] = Const.COLORS;
Const.COLORS.SHADES = [500];


export default Const;
