import {LinearProgress, Typography} from "@mui/material";

import React from "react";

export default function FetchingIndicator({isFetching, children, text}) {
  if (!isFetching) return children ? children : null;
  return <div className={"fetchingIndicator"}>
    <Typography align={"center"}>
      {text ? text : 'Loading...'}
      <br/><br/>
    </Typography>
    <LinearProgress/>
  </div>
}
