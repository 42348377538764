import React from "react";
import FieldRouter from "./FieldRouter";

export default function FormElement({
                                      propertyKey,
                                      propertyElement,
                                      isRequired,
                                      idPrefix,
                                      widgets,
                                      alertType,
                                      value, taskId
                                    }) {
  if (propertyElement && propertyElement.type) {
    return (
      <span className={"formElementRoot"}>
        <h5>{propertyElement.title}</h5>
        <FieldRouter propertyKey={propertyKey} isRequired={isRequired} propertyElement={propertyElement}
                     idPrefix={idPrefix} path={[propertyKey]} value={value} taskId={taskId}
                     alertType={alertType} widgets={widgets}/>
      </span>
    )
  } else {
    // console.error(propertyKey);
    return null;
  }

}
