import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import SCUI from "../form/widgets/SCUI"
import TechnicalName from "../form/widgets/TechnicalName";

import {DetectionTypeSelector, DetectionTypesSelector} from "../form/widgets/DetectionTypeSelector";
import ReadOnlyCopyText from "../form/widgets/ReadOnlyCopyText"

import OptionalObject from "../form/widgets/OptionalObject";
import HttpClientConfigWrapper from "../form/widgets/HttpClientConfigWrapper";
import HttpWebhookConfigWrapper from "../form/widgets/HttpWebhookConfigWrapper";
import EnumMultiplexer from "../form/widgets/EnumMultiplexer";
import WrappedField from '../form/widgets/WrappedField';
import PpeSelector from '../form/widgets/PpeSelector';
import HourMinute from '../form/widgets/HourMinute';
import {faPlus, faSave} from '@fortawesome/free-solid-svg-icons';
import {CtaButton} from "../ui/temporary/CtaButton";
import BigQueryWrapper from "../form/widgets/BigQueryWrapper";
import NxClientConfigWrapper from "../form/widgets/NxClientConfigWrapper";
import UfilterNxClientConfigWrapper from "../form/widgets/UfilterNxClientConfigWrapper";
import CustomDualListBox from "../form/widgets/CustomDualListBox";

function SaveButton({isSubmitting, newTask, hasChanged, disabled}) {
  const startIcon = <FontAwesomeIcon icon={newTask ? faPlus : faSave}/>;
  return (<React.Fragment>
    {
      isSubmitting ?
        <CtaButton disabled primary
                   type={"submit"}
                   startIcon={startIcon}>
          {newTask ? 'Submitting...' : 'Saving...'}
        </CtaButton>
        :
        <CtaButton primary
                   startIcon={startIcon}
                   type={"submit"} disabled={(!newTask && !hasChanged) || !!disabled}
                   dataTestId={newTask ? "NewSolutionSubmitButton" : "NewSolutionSaveButton"}>
          {newTask ? 'Submit' : 'Save'}
        </CtaButton>
    }
  </React.Fragment>)
}

const scrollToTop = () => document.querySelector('.contentBody').scrollTop = 0; // scrolling to the top so the user can see error messages

const widgets = {
  OptionalObject,
  HttpClientConfigWrapper,
  HttpWebhookConfigWrapper, // ufilter specific
  NxClientConfigWrapper,
  EnumMultiplexer,
  DetectionTypeSelector,
  DetectionTypesSelector,
  TechnicalName,
  SCUI,
  CustomDualListBox,
  WrappedField,
  PpeSelector,
  HourMinute,
  ReadOnlyCopyText,
  BigQueryWrapper,
  UfilterNxClientConfigWrapper
};

const CommonTask = {
  SaveButton,
  widgets,
  scrollToTop
}
export default CommonTask;
