import React, { useRef, useState } from "react"
import Cover from "./Cover"
import { Button, Collapse, LinearProgress, TextField } from "@mui/material"
import unwrapErrorFromApi from "./UnwrapErrorApi"
import { confirmDialog } from "./temp/ConfirmDialog"

export function SmtpTest({ enabled, dirty, apiCall }) {

  const recipientsRef = useRef()
  const [loading, setLoading] = useState(false)

  const send = async (e) => {
    e.preventDefault()
    setLoading(true)
    const rec = recipientsRef.current.value.split(",").map(s => s.trim())
    try {
      const res = await apiCall(rec)
      setLoading(false)
      await confirmDialog({
        title: "SMTP test result",
        message: res.remote_response_status == 200 ? "Email has been sent successfully." :
          "Email could not be send." + res.remote_response_message,
        noCancel: true
      })

    } catch (err) {
      setLoading(false)
      await unwrapErrorFromApi(err)
    }
  }

  const testAvailable = () => enabled && !dirty

  return <Cover title={"Send Test E-mail"}>
      <Collapse in={testAvailable()}>
          <form onSubmit={send}>
              <TextField label={"List of recipients (separated by comma)"} fullWidth required
                   inputRef={recipientsRef}
        />
              <br /> <br />
              <Button
          disabled={loading}
          onClick={send}
          variant={"contained"}
          type="submit"
          className={"formSubmit action"}>
                  Send test
              </Button>
          </form>
          {loading && <div className={"progressBar wrapped"}><LinearProgress /></div>}
      </Collapse>
      <Collapse in={!enabled}>
          To send test e-mail, please set up server address, name and port.
          <br />
          <br />
      </Collapse>
      <Collapse in={enabled && dirty}>
          Please save configuration before testing.
          <br />
          <br />
      </Collapse>
  </Cover>
}
