import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBellSlash,
  faExclamationTriangle,
  faMoonStars,
  faQuestionCircle,
  faHourglassHalf
} from "@fortawesome/pro-light-svg-icons";
import Tooltip from "@mui/material/Tooltip/";

const ALARM_STATE = {
  INACTIVE: {
    text: "Inactive",
    icon: faBellSlash,
  },
  STARTING: {
    text: "Starting",
    icon: faHourglassHalf,
  },
  RUNNING: {
    text: "Running",
    icon: faBell,
  },
  SCHEDULED_FREEZE: {
    text: "Freeze",
    icon: faMoonStars,
  },
  ERROR: {
    text: "Error",
    icon: faExclamationTriangle
  },
  UNKNOWN: {
    text: "Unknown",
    icon: faQuestionCircle
  }
}

export default function AlarmStateIcon({state, ...props}) {
  if (!state) return null;
  return <Tooltip title={"Solution status: " + ALARM_STATE[state].text} placement={"left"}>
    <span>
    <FontAwesomeIcon icon={ALARM_STATE[state].icon} className={"marginLeft-15"}/>
    </span>
  </Tooltip>
}
