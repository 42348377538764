import React from "react";
import FormInput from "./FormInput";
import {FormControl,InputLabel,Select} from "@mui/material/";
import Constant from "../constant";


export default function ScheduleSelector({schedules, value, onChange,alarmCategory}) {
  if (Constant.HIDE_SCHEDULE_CATEGORY.includes(alarmCategory)) return null;

  return <FormInput >
    <FormControl fullWidth variant={"standard"}>
      <InputLabel shrink htmlFor="selectSchedule">Schedule</InputLabel>
      <Select native
              value={value || ""}
              displayEmpty
              inputProps={{
                name: 'Schedule of the alarm' ,
                id: 'selectSchedule',
              }}
              onChange={e => onChange(e)}>

        <option key={"schedule_disabled"} value={""}>
          No schedule
        </option>
        {schedules.map(s => <option value={s.id} key={s.id}>{s.display_name}</option>)}
      </Select>
    </FormControl>
  </FormInput>;
}
