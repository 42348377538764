import {TextField} from '@mui/material';
import React, {useState} from "react";
import {CtaButton} from "./CtaButton";

export default function CopyToClipboard(props) {
  let {ownId, label, value} = props;
  ownId = ownId || 'clipboard-' + Math.random();
  const [copiedFeedback, setCopiedFeedback] = useState(false);
  const copy = () => {
    setCopiedFeedback(true);
    const copyText = document.getElementById(ownId);

    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    setTimeout(() => setCopiedFeedback(false), 1250)
  }

  return <React.Fragment>
    <TextField
      id={ownId}
      label={label || "Copy"}
      multiline fullWidth
      value={value || ""}
      InputProps={{readOnly: true}}
      InputLabelProps={{shrink: true}}
      variant="filled"
      onClick={copy}
    />
    <br/><br/>
    <CtaButton size={"small"}
               onClick={copy}
                disabled={copiedFeedback}>
      {copiedFeedback ? 'Copied to clipboard!' : 'Copy to clipboard'}
    </CtaButton>
    <br/><br/>
  </React.Fragment>
}
