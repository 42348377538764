import React, {useEffect} from "react";
import TextField from '@mui/material/TextField/';
import formDataStore from "../FormDataStore";
import Dispatcher from "../../dispatcher";
import Constant from "../../constant";

export default function String({isRequired, id, propertyElement, path, shortInput}) {
  const [value, setValue] = React.useState(formDataStore.getByPath(path) || propertyElement.default);
  const [errorMessage, setErrorMessage] = React.useState(null);

  useEffect(() => {
    const dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED || payload.contentType === Constant.FLUX.FORM_DATA_CHANGED) {

        const currentValue = formDataStore.getByPath(path) || propertyElement.default

        formDataStore.saveWhenDefault(path, propertyElement);
        setValue(currentValue)
      }
    })
    return () => {
      Dispatcher.unregister(dispatcher);
    };
  }, [])

  const validate = newValue => {
    if (isRequired && newValue === "") {
      console.log(path);
      throw new Error("Required field.");
    }
    return newValue;
  }

  const onChange = evt => {
    const newValue = evt.target.value;
    setValue(newValue);

    try {
      validate(evt.target.value);
      setErrorMessage(null);
      formDataStore.removeError(path);
      formDataStore.saveToPath(path, newValue);
    } catch (err) {
      console.debug("[MTR UI Component Error]", err);
      formDataStore.addError(path, err.message);
      if (true) {
        formDataStore.saveToPath(path, newValue); // todo: potential breaking change
        /**
         * @description before this, the MTR ui validated all values, but kept an inconsistent state.
         * For example: if you delete a value from a required string, the UI will show a warning
         * but the internal state will stay the same.
         * This is bad because in U-Filter the validation also occurs on the api side.
         */
      }
      setErrorMessage(err.message);
    }
  }
  return (
    <TextField id={id}
               label={propertyElement.title}
               type={propertyElement.fieldType || "text"}
               required={isRequired}
               value={value || ""}
               onChange={onChange}
               fullWidth={!shortInput}
               error={!!errorMessage}
               variant={"standard"}
               helperText={`${errorMessage || ''} ${propertyElement.description || ''}`}
    />
  )
}
