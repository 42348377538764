import React, { useState } from "react"
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, Select, TextField } from "@mui/material"

import { RawEnum } from "jsutil"

const ENCRYPTION_ENUM = new RawEnum("NONE", "STARTTLS", "SSL_TLS")
const ENCRYPTION = {
  [ENCRYPTION_ENUM.NONE]: "None",
  [ENCRYPTION_ENUM.STARTTLS]: "STARTTLS",
  [ENCRYPTION_ENUM.SSL_TLS]: "SSL/TLS"
}

export default function SmtpForm({ smtpState, setSmtpState, enabled = true, edit }) {

  const [modifyPassword, setModifyPassword] = useState(false)

  const onChangeSmtpProperty = (prop, converter = null) => (event) => {
    let val = event.target.value
    if (converter) {
      val = converter(val)
    }
    const copy = { ...smtpState }
    copy[prop] = val
    setSmtpState(copy)
  }

  const onChangeSmtpPassword = evt => {
    const val = evt.target.checked
    setSmtpState({ ...smtpState, password: val ? "" : null })
    setModifyPassword(val)
  }

  return <>
      <TextField className={"largeForm"} disabled={!enabled} label={"Sender e-mail address"}
               value={smtpState?.email_sender || ""}
               required
               type={"smtpState"} onChange={onChangeSmtpProperty("email_sender")} />
      <FormControl className={"formControl largeForm"}>
          <InputLabel htmlFor="encryption">Encryption</InputLabel>
          <Select disabled={!enabled}
              native
              label="encryption" value={smtpState?.encryption || ENCRYPTION.NONE}
              onChange={onChangeSmtpProperty("encryption")}>
              {Object.entries(ENCRYPTION).map(([value, displayName]) => <option value={value}
                                                                          key={value}>{displayName}</option>)}
          </Select>
      </FormControl>
      <Grid container spacing={3}>
          <Grid item xs={8}>
              <TextField
          disabled={!enabled}
          label={"Server name"} required
          className={"largeForm"}
          value={smtpState?.host || ""}
          onChange={onChangeSmtpProperty("host")} />
          </Grid>
          <br />
          <Grid item xs={4}>
              <TextField
          disabled={!enabled}
          label={"Server port"} required
          className={"largeForm"}
          value={smtpState?.port || ""}
          type="number"
          InputProps={{
            inputProps: {
              min: 0,
              max: 65535
            }
          }}
          onChange={onChangeSmtpProperty("port", Number)} />
          </Grid>
      </Grid>
      <TextField
      className={"largeForm"}
      disabled={!enabled}
      label={"User name"}
      value={smtpState?.user || ""}
      onChange={onChangeSmtpProperty("user")} />
      <br />
      <Grid container spacing={3}>
          <Grid item xs={8}>
              <TextField
          className={"largeForm"}
          label={"Password  "} fullWidth
          type={"password"}
          value={smtpState?.password}
          disabled={!enabled || (edit && !modifyPassword)}
          onChange={onChangeSmtpProperty("password")} />
          </Grid>
          {
        edit &&
        <Grid item xs={4}>
            <FormControlLabel
            control={<Checkbox
              disabled={!enabled}
              checked={modifyPassword}
              color="primary"
              onChange={onChangeSmtpPassword} />}
            label="Override password"
          />
            <br />
        </Grid>
      }
      </Grid>
  </>
}


