import React from "react";
import FieldRouter from "../FieldRouter";

export default function WrappedField({propertyElement, isRequired, idPrefix, path, options, Interactive, noWrap}) {
  let dispatcher;

  const params = {propertyElement, isRequired, idPrefix, path, options};
    return (<span className={noWrap ? "objectFieldHolder" : ""}>
      <FieldRouter {...params} />
      {options.optionalSubtitle && <div><br/>{options.optionalSubtitle}</div>}
    </span>)


}
