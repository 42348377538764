import { NEW_API_URL } from "../constant"

const axios = require("axios").default

// const swaggerApi = axios.create({
//   baseURL: NEW_API_URL, withCredentials: true
// })

const CONFIG = { withCredentials: true }
export default class MtrApiClient {
  constructor(apiUrl, state) {
    this.apiUrl = apiUrl
    this.state = state
    console.debug("[MTR | UFilter] Client created")
  }

  getCameraListByTaskTypes() {
    return []
  }

  async testIntegration(content) {
    content = { ...content }
    const res = await axios.post(`${NEW_API_URL}sites/${this.state.siteId}/test_webhook`, content.http_webhook_config, CONFIG)
    res.data.response_status_code = res.data.remote_response_status
    res.data.response_body = res.data.remote_response_message
    res.data.success = res.data.remote_response_status < 300
    return res.data
  }

  async sendNxTestEvent(_, _2, content) {
    const res = await axios.post(`${NEW_API_URL}sites/${this.state.siteId}/test_nx_integration`, content.nx_integration_config, CONFIG)
    res.data.response_status_code = res.data.remote_response_status
    res.data.response_body = res.data.remote_response_message
    res.data.success = res.data.remote_response_status < 300
    return res.data
  }
}
