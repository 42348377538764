import * as React from "react"
import { useEffect, useState } from "react"
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import HomeIcon from "@mui/icons-material/Home"
import store from "../store"
import { Link, useNavigate } from "react-router-dom"
import { getUserInfo, logoutUser } from "../newApiClient"
import { isDev } from "../util"

const drawerWidth = 240

export default function Header({ state, headerName, asAdmin }, props) {
  // const [account, setAccount] = useState(store.account)
  const _navigate = useNavigate()

  const NAV_ITEMS_ADMIN = [
    { text: "Log out", onClick: logoutUser }

  ]

  const NAV_ITEMS = [
    // {text: `[Account : ${account?.name}]`, onClick: () => navigate('/acc')},
    ...NAV_ITEMS_ADMIN
  ]

  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        U-Filter
      </Typography>
      <Divider />
      <List>
        {NAV_ITEMS.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }} onClick={item.onClick}
              // className={item.self === state.page ? "selected" : ""
            >
              <ListItemText primary={item.text}
                // className={item.self === state.page ? "selected" : ""
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  const [isAdmin, setIsAdmin] = useState(null)
  useEffect(() => {
    let req = getUserInfo()
    req.then(value => {
      if (value.data.roles.includes("ADMIN")) {
        setIsAdmin(true)
      }
    })
      .catch(_ => setIsAdmin(false))
  }, [])
  return (
    <>
      <AppBar component="nav" id={asAdmin ? "drawer-admin" : "drawer-user"}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon /> &nbsp;U-Filter
          </IconButton>

          <div id={"innerLogo"}>
            <img src={`/ua_logo_notext_gradient_dark.svg`} id={"logo"} alt={"logo"} />
          </div>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >

            <div id={"productName"}>
              {headerName || "U-Filter"}
              {isDev() && " (Dev)"}
            </div>
          </Typography>
          <Link to={"/"} className={"noa"}>
            <Button key={"home"} sx={{ color: "#fff" }} startIcon={<HomeIcon />}>

            </Button>
          </Link>

          {
            isAdmin && <Link to={"/admin"} className={"noa"}>
              <Button key={"admin"} sx={{ color: "#fff" }}>
                Admin Panel
              </Button>
            </Link>
          }
          <Link to={"/user"} className={"noa"}>
            <Button key={"user"} sx={{ color: "#fff" }}>
              {store?.user?.name ? store.user.name : "User"}
            </Button>
          </Link>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {(asAdmin ? NAV_ITEMS_ADMIN : NAV_ITEMS).map((item) => (
              <Button key={item.text} sx={{ color: "#fff" }} onClick={item.onClick}
              >
                {item.text}
              </Button>
            ))}

          </Box>

        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth }
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  )
}
