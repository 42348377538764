import React from "react";
import {Button} from "@mui/material";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';

export default function BackBtnSmallDevices({onClick}) {
  return (
    <div className={"lg-hidden sm-visible"}>
      <Button className={"button-back"} onClick={onClick}>
        <FontAwesomeIcon icon={faArrowLeft}/>
        &nbsp;
        Go Back
      </Button>
      <br/><br/>
    </div>
  )
}
