import Page from "../../component/Page"
import { DataGrid } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { Alert, Button, Fab, LinearProgress } from "@mui/material"
import { Link, Outlet, useNavigate } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import { getAccountsInUser, getUsers } from "../../adminApiClient"
import Dispatcher from "../../dispatcher"

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 70
  },
  {
    field: "name",
    headerName: "Name",
    width: 150
  },
  {
    field: "email",
    headerName: "Email",
    width: 150

  },
  {
    field: "roles",
    headerName: "Roles",
    width: 150,
    renderCell: (props) => {
      const { value } = props
      return value.join(", ").toLowerCase()
    }
  },
  {
    field: "accounts",
    headerName: "Accounts",
    width: 210,
    renderCell: function rc(props) {
      const { value } = props
      if (!value) return "Loading.."
      if (value.length === 0)
        return <Link onClick={event => event.stopPropagation()}
                     to={`/admin/users/${props.row.id}/link`}
                     className={"userLink"}>
          No accounts
        </Link>

      return <Link onClick={event => event.stopPropagation()}
                   to={`/admin/users/${props.row.id}/link`}
                   className={"userLink"}>
        {value.length}
        {" accounts : "}
        {value.join(",")}</Link>
    }
  }
]

export default function Users() {
  function selectItem({ id }) {
    navigate(`${id}`)
  }

  const navigate = useNavigate()
  const [users, setUsers] = useState(false)
  const [currentUser, _setCurrentUser] = useState(false)
  const [accountsByUsers, setAccountsByUsers] = useState([])
  const [error, setError] = useState(false)

  useEffect(() => {
    if (!users || users.length === 0) return
    Promise.all(
      users.map(user => getAccountsInUser(user.id))
    ).then(res => {
      setAccountsByUsers(res)
    })

  }, [users, setUsers])

  const mergedAccountAndUserList = users && users?.map((users, i) => {
    return {
      ...users,
      accounts: accountsByUsers[i]?.map(account => account.name || account.id)
    }
  })

  const fetchUsers = () => {
    getUsers()
      .then(value => {
        setUsers(value)
      })
      .catch(_ => {
        setError(true)
      })
  }

  useEffect(() => {
    fetchUsers()


    const d = Dispatcher.register(payload => {
      if (payload.contentType === "GENERIC_RELOAD_PARENT") {
        fetchUsers()
      }
    })
    return () => {
      Dispatcher.unregister(d)
    }
  }, [])


  if (error) return <UserError clearError={() => {
    setError(false)
    fetchUsers()
  }} />

  return <Page title={"Users"}>
    <br />
    <Link to={"/admin/users/create"}>
      <Fab variant="extended"
           size="medium" color="primary" id={"Accounts-AddFab"}>
        <AddIcon sx={{ mr: 1 }} />
        Add User
      </Fab>
    </Link>

    <div id={"Accounts-DataGrid"}>
      {users === false ? <LinearProgress /> :
        <DataGrid
          disableVirtualization
          autoHeight
          rows={mergedAccountAndUserList}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20, 100, 500]}
          onRowClick={selectItem}
        />
      }
    </div>
    <div className={"Account-Outlet"}>
      <Outlet context={[users, currentUser]} />
    </div>
  </Page>
}

const UserError = ({ clearError }) => {
  return <Page title={"Users"}>
    <Alert variant={"filled"} className={"shortAlert"} severity={"error"}>Could not load Users.</Alert>
    <br />
    <Button color={"primary"} variant={"contained"}
            onClick={clearError}>Reload</Button>
  </Page>
}
