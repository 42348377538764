import WrappedLink from "../../component/WrappedLink"

export default function UserRoot() {
  return <>
      <h3 className={"noTopPad"}>User Configuration</h3>
      <br />
      <br />
      <WrappedLink to={"./password"}>Change Password</WrappedLink>
  </>
}
