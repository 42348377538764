/**
 * @param lengthToPadTo
 * @param textToPadWith
 * @returns {string}
 */
Number.prototype.toPadLeft = function (lengthToPadTo = 2, textToPadWith = 0) {
  return (this).toString().padStart(lengthToPadTo, textToPadWith);
};

Array.prototype.max = function () {
  return Math.max(...this);
};

Array.prototype.min = function () {
  return Math.min(...this);
};

Object.deepCopy = function (obj) {
  // provides a deep primitive copy (keep in mind that functions, maps and sets are not passed through)
  return JSON.parse(JSON.stringify(obj));
};

Function.prototype.repeatTimes = function (length) {
  Array.from({length}, this);
};
Math.clamp = function (value, min, max) {
  return ((value < min) ? min : ((value > max) ? max : value));
};

Math.logn = function(x, y) {
  return Math.log(y) / Math.log(x);
}

String.prototype.capitalise = function () {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
}

/**
 * Splitting up JS Date value to api value
 * @param dt : Date @example new Date()
 * @return {{month: *, hour: *, year: *, day: *, minute: *}}
 */
Date.prototype.toApi = function() {
  return {
    year: this.getFullYear(),
    month: this.getMonth()+1,
    day: this.getDate(),
    hour: this.getHours(),
    minute: this.getMinutes()
  }

}
Date.prototype.offset = function(by) {
  return new Date(this.setTime( this.getTime() - by));
}

String.prototype.maxLength = function(max) {
  if (this.length <= max) return this;
  const str = this.substring(0, max) + "...";
  return str;
}
