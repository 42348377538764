import React, {useEffect, useState} from "react";
import Dispatcher from "../dispatcher";
import Constant from "../constant";
import Util from "../util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrochip} from "@fortawesome/pro-light-svg-icons";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,Alert} from '@mui/material';

export default function ResourceQuota({store, isActive, className}) {
  const [state, setState] = useState(store.resource || {});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const dispatcher = Dispatcher.register(payload => {
      if (payload.actionType === Constant.FLUX.RESOURCE_CHANGED) {
        setState(payload.resource)
      }
    })
    return () => Dispatcher.unregister(dispatcher);
  })

  const getColorGroup = ({used, diff, max}) => {
    if (used + diff > 100) return "cg-tooMuch";
    if (diff < 0) return "cg-wannaBeFree";
    if (diff > 0) return "cg-wannaBeEaten";
  }

  const getGroup = ({used, diff, max}, i) => {
    if (used + diff > max && i >= used) {
      return ["tooMuch", "More than allowed"];
    } else if (diff > 0 && i >= used && i < (used + diff)) {
      return ["wannaBeEaten", "Going to be used"];
    } else if (diff < 0 && i >= (used + diff) && i < used) {
      return ["wannaBeFree", "Going to be made free."];
    } else if (i < used) {
      return ["used", "Already used slots."];
    } else {
      return ["free", "Free slots remaining."];
    }
  }

  const getText = () => {
    if (state.diff + state.used > 100) return (
      <React.Fragment><Alert severity={"error"} variant={"filled"}>
        This solution would use too many resources: consider changing assigned cameras.
      </Alert><br/></React.Fragment>
    ); else if (state.diff)
      return (<React.Fragment><Alert severity={"success"} variant={"filled"}>
        The currently edited solution will use <b>{Math.abs(state.diff)} {state.diff > 0 ? "more " : "less "}
        slots</b> then currently in use.
      </Alert><br/></React.Fragment>);
    else return "";
  }


  return (<React.Fragment>
    <Dialog onClose={() => setOpen(false)} open={open}
            className={"largeDialog largePaddingDialog"}>
      <DialogTitle>Analysis Slots</DialogTitle>
      <DialogContent>
        <p className={"wallOfText"}>
          {getText()}
          Represents how much computing power the device has.<br/>
          Each camera, when assigned to an alarm or counter uses resources. Resource usage varies by solution.
          <br/><br/>
          When a camera is used multiple times in the <i>same solution type</i> it will not use more slots.
          <br/><br/>
          Currently <b>{state.used}% of the resources is in use</b> and {100 - state.used}% is free.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
    <div id={"resQuotaRoot"} onClick={() => setOpen(true)} title={"Analysis Slots. Click for more information."}>
      <FontAwesomeIcon icon={faMicrochip} className={getColorGroup(state)}/>

      <div className={"resourceQuotaOuter"}>
        {
          isActive && <div className={"resourceQuotaDisplay " + getColorGroup(state)}>
            (
            {state.diff >= 0 && "+"}
            {Math.round(state.diff)}
            {state.diff !== 0 && "%"}
            )
          </div>
        }

        <div className={`resourceQuotaHolder ${className} ${100 > 8 && 'narrow'}`}>
          {
            Util.fillArray(100, (v, i) => {
              const [className, title] = getGroup(state, i);
              return <div className={"resourceQuota " + className} key={i} title={title}></div>
            })
          }
        </div>

      </div>

    </div>
  </React.Fragment>)
}
