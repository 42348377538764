import React from "react";
import EnumSelectCheckbox from "../fields/EnumSelectCheckbox";
import {DetectionTypeIcon} from "../FormUtils";
import EnumSelectRadioButton from "../fields/EnumSelectRadioButton";

export function DetectionTypeSelector({onChange, value, propertyElement, parentPath, isRequired}) {
  if (!propertyElement?.enum) return null;
  propertyElement.label = getLabels(propertyElement.enum);
  const params = {onChange, value, propertyElement, parentPath, isRequired}
  return <EnumSelectRadioButton {...params}/>
}

export function DetectionTypesSelector({onChange, value, propertyElement, parentPath, isRequired}) {
  if (!propertyElement?.items?.enum) return null;
  propertyElement.items.label = getLabels(propertyElement.items.enum);
  const params = {onChange, value, propertyElement, parentPath, isRequired}
  return <EnumSelectCheckbox {...params}/>
}

function getLabels(enumScheme) {
  return enumScheme.map(elem => {
    return <span>
      <span className={"detTypeSel-icon"}>
      {DetectionTypeIcon(elem)}
      </span>
      {elem.charAt(0).toUpperCase() + elem.slice(1).toLowerCase().replace(/_/g, " ")}
    </span>
  })
}
