import React, {useState} from 'react';
import {create} from "react-modal-promise";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button} from '@mui/material';
import TextInput from "../TextInput";


function ConfirmDialog({open, message, cancelText, okText, onResolve, noCancel, title}) {
  return (
      <Dialog className={"cDialog"} open={open} onClose={() => onResolve(false)}>
          {title && <DialogTitle>{title}</DialogTitle>}
          <DialogContent>
              <DialogContentText className={"pre-wrap"}>
                  {message}
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              {!noCancel &&
                  <Button variant="outlined" onClick={() => onResolve(false)}>
                      {cancelText || "Cancel"}
                  </Button>
        }
              <Button variant="contained" onClick={() => onResolve(true)} primary autoFocus>
                  {okText || "OK"}
              </Button>
          </DialogActions>
      </Dialog>
  );
}

function PromptDialog({open, message, cancelText, okText, onResolve, title, defaultValue, inputProps}) {

  const [value, setValue] = useState(defaultValue || "");
  const onSubmit = evt => {
    evt.preventDefault();
    console.log(evt.target.checkValidity())
    onResolve(value);
  }
  return (
      <Dialog className={"cDialog"} open={open} onClose={() => onResolve(false)}>
          <form onSubmit={onSubmit}>
              {title && <DialogTitle>{title}</DialogTitle>}
              <DialogContent>
                  <DialogContentText className={"pre-wrap"}>
                      {message}

                      <br /><br />
                      <TextInput value={value} onChange={evt => setValue(evt.target.value)}
                       inputProps={inputProps || {}}/>
                      <br />
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button variant="outlined" onClick={() => onResolve(null)}>
                      {cancelText || "Cancel"}
                  </Button>
                  <Button variant="contained" type={"submit"} primary>
                      {okText || "OK"}
                  </Button>
              </DialogActions>
          </form>
      </Dialog>
  );
}

const confirmDialogCtrlSkippable = async (monad, evt) => {
  if (evt?.ctrlKey) return Promise.resolve(true);
  else return create(ConfirmDialog)(monad);
}



export const confirmDialog = create(ConfirmDialog);


const confirmDialogAlert = async (alert) => {
  return confirmDialog(
    {noCancel: true, title: alert, message: ""}
  )
}
export const promptDialog = create(PromptDialog);
export {confirmDialogCtrlSkippable, confirmDialogAlert};
