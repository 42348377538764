const def_api_url = (window.location.hostname === "localhost") ?
    "http://localhost:5000" : window.location.protocol + "//" + window.location.host + "/API"
const api_override = "@@API_URL@@"
export const API_URL = (api_override.startsWith("@@") ? def_api_url : api_override)
export const NEW_API_URL = localStorage.getItem("apiUrl") ? localStorage.getItem("apiUrl") : (window.location.protocol + "//" + window.location.host) + "/API/"
//"http://127.0.0.1:8080/"
export const TECHNICAL_NAME_PATTERN = "^[a-zA-Z0-9_]{1,60}$"
export const TECHNICAL_NAME_REGEX = new RegExp(TECHNICAL_NAME_PATTERN)

export const CHANNELS_ON_MAIN_LIST = 10


export const DEFAULT_ROI_CONFIG = {
    positive_areas: [],
    negative_areas: []
}

export const DEFAULT_SIZE_FILTER = {
    min: 32,
    max: 1000
}

export const GET_CHANNEL_DEFAULT_VALUE = () => {
    return {
        detection_config: {
            class_info: {
                car: { sensitivity: "MEDIUM", filter_motionless: false },
                person: { sensitivity: "MEDIUM", filter_motionless: false },
                truck: { sensitivity: "MEDIUM", filter_motionless: false },
                bus: { sensitivity: "OFF", filter_motionless: false },
                motorcycle: { sensitivity: "OFF", filter_motionless: false },
                bicycle: { sensitivity: "OFF", filter_motionless: false },
                train: { sensitivity: "OFF", filter_motionless: false },
                boat: { sensitivity: "OFF", filter_motionless: false },
                airplane: { sensitivity: "OFF", filter_motionless: false }
            },
            rois: { ...DEFAULT_ROI_CONFIG },
            size_filter: { ...DEFAULT_SIZE_FILTER }
        }
    }
}
export const SiteFormWidget = {
    http_webhook_config: {
        widget: "HttpWebhookConfigWrapper",
        options: {
            enabledField: "enabled",
            shortInputs: ["header", "header_value"]
        }
    },
    nx_integration_config: {
        widget: "UfilterNxClientConfigWrapper",
        options: {
            enabledField: "enabled"
        }
    }
}

export const SCUI_LOCATION = "/stream_configurator/index.html"

export const EVENT = {
    SCUI: {
        statusDataReceived: "scui-send-data",
        requestData: "scui-request-data",
        closeIFrame: "scui-send-close",
        sendPreconfig: "scui-preconfig",
        sendOpenSignal: "scui-open-signal",
        backgroundRequestReceived: "scui-request-background",
        sendCloseSignal: "scui-close-signal",
        sendBackground: "scui-background"
    },
    Slave: {
        statusDataReceived: "slave-data-received",
        opened: "slave-set-open-state",
        statusClosed: "slave-closed"
    }
}

export const SCUI_TYPES = {
    Passdet: "uvap-passdet",
    Detfilter: "uvap-detfilter",
    ZoneAB: "ualarm-zone"
}

export const MtrModularText = {
    HttpInputCannotSendTestEvent: "To send a test event, please create the site first."
}

export const ALL_FILTER_OBJECTS = ["negative_areas", "positive_areas", "source_areas", "target_areas",
    "excluded_areas", "areas"]

export const SCUI_PROFILE = "uvap-detfilter"

export const MAX_DIMENSION = 1920

export const SMTP_USER_DELIMITER = "-"

export const NX_INTEGRATION_NAME = "NX Witness / DW Spectrum / Wisenet Wave"

console.log("%c[This is U-Filter version 1.2.2 at 9 Jan 2024]", "font-size:16px")
