import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import { ExpandMore, Help } from "@mui/icons-material"
import CopyBtn from "./CopyBtn"
import * as React from "react"

import { useNavigate, useParams, useRouteLoaderData, useSearchParams } from "react-router-dom"
import store from "../store"
import { formatSmtpUser } from "../util"


export default function ConfigurationHelp({ site, channel, accordion = true, title = true }) {

  const { accountId } = useParams()
  let [searchParams, _setSearchParams] = useSearchParams()
  useNavigate()
  useRouteLoaderData("site")
  const accounts = useRouteLoaderData("accounts")
  const currentAccount = accounts.find(account => account.id === Number(accountId))

  const smtp_server = store.mail_server.mail_server

  const smtp_user = formatSmtpUser(currentAccount.tid, site.tid)

  const isNvr = channel.nvr_id || searchParams.get("nvr") === ""
  const parsed = {
    ...site,
    ...channel,
    smtp_user,
    smtp_to: (isNvr ? channel.nvr_id : channel.tid) + "@" + (isNvr ? "nvr." : "") + smtp_server,
    smtp_server
  }
  const Inside = () => (
    <>
      {title && (
        <>
          <br />
          <Typography variant={"h5"}>
            Camera configuration help
            <br />
          </Typography>
        </>
      )}

      <div className={"configHelp"}>
        <table>
          <tbody>
          <tr>
            <td>
              Sender's Address
            </td>
            <td colSpan={2}>
              not used, any value is acceptable
            </td>
          </tr>
          <tr>
            <td>
              SMTP Server
            </td>
            <td>
              {parsed?.smtp_server}
            </td>
            <td>
              <CopyBtn value={parsed?.smtp_server} adornment={false} />
            </td>
          </tr>
          <tr>
            <td>
              SMTP Port
              <br />(depending on encryption)
            </td>
            <td>
              465 (SSL/TLS) <br />
              25 or 587 (STARTTLS or No Encryption)
            </td>
          </tr>
          <tr>
            <td>
              Authentication
            </td>
            <td>
              <code>Yes</code>
            </td>
          </tr>
          <tr>
            <td>
              Username
            </td>
            <td>
              <code title={formatSmtpUser("<Account unique id>", "<Site unique id>")}>
                {parsed?.smtp_user}</code> <br /> or
            </td>
            <td>
              <CopyBtn value={parsed?.smtp_user} adornment={false} />
            </td>
          </tr>
          <tr>
            <td>
            </td>
            <td>
              <code title={formatSmtpUser("<Account unique id>", "<Site unique id>")}>
                {parsed?.smtp_user}@{parsed?.smtp_server}</code> <br />
            </td>
            <td>
              <CopyBtn value={`${parsed?.smtp_user}@${parsed?.smtp_server}`} adornment={false} />
            </td>
          </tr>
          <tr>
            <td>
              Password
            </td>
            <td>
              <code>{parsed?.password}</code>
            </td>
            <td>
              <CopyBtn value={parsed?.password} adornment={false} />
            </td>
          </tr>
          <tr>
            <td>
              Receiver's Address
            </td>
            <td>
              <code title={"<Channel unique id>@<Mail server>"}
              >{parsed?.smtp_to}</code><br />
            </td>
            <td>
              <CopyBtn value={parsed?.smtp_to} adornment={false} />
            </td>
          </tr>
          <tr>
            <td>Copy everything</td>
            <td><CopyBtn value={everythingForCopy} adornment={false} /></td>
          </tr>
          </tbody>

        </table>

      </div>
    </>
  )

  const everythingForCopy = `Sender's Address: not used, any value is acceptable\n` +
    `SMTP Server: ${
      parsed?.smtp_server
    }\n` +
    `SMTP Port (depending on encryption): 465 (SSL/TLS), 25 or 587 (STARTTLS or No Encryption)\n` +
    `Authentication: Yes\n` +
    `User Name: ${
      parsed?.smtp_user
    } or\n` +
    `${parsed?.smtp_user}@${parsed?.smtp_server}\n` +
    `Password: ${
      parsed?.password
    }\n` +
    `Receiver's Address: ${
      parsed?.smtp_to
    }`

  if (accordion) {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
        >
          <Help />
          &nbsp;
          <Typography>Camera configuration help <CopyBtn value={everythingForCopy}
                                                         adornment={false} /></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Inside />
        </AccordionDetails>
      </Accordion>
    )
  }

  return <Inside />
}
