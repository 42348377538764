import React, {useState, useEffect} from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  CircularProgress,
  FormControl,
  InputLabel,
  Alert,
  Select, FormControlLabel, Switch, FormGroup, LinearProgress
} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faObjectGroup, faVectorSquare} from "@fortawesome/free-solid-svg-icons";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {JSUtil} from "jsutil";
import {DetectionTypeSelector, DetectionTypesSelector} from "../form/widgets/DetectionTypeSelector";
import {CtaButton} from "../ui/temporary/CtaButton";
import {DetectionTypeIcon} from "../form/FormUtils";
import RangeSelector from "./RangeSelector";
import SCUIUtils from "../form/SCUIUtils";

export default function CameraForm({
                                     camera, value, objDetTypeSchema, objDetTypesSchema, filterProperties,
                                     getSnapshot, onChange, sensitivity, alertType, idx, store, clone, requestTypeSCUI,
                                     deleteCam, parentPath, openedInput, setOpenedInput, allowDuplicatedCamera,
                                     askForCameraChange, showChange, filterObjects, alertTypeConfig, getCameraById
                                   }) {
  const dummyThumbnail = "./dummy.png";

  const [thisCam, setThisCam] = useState();
  const [errorR, setErrorR] = useState();

  const setDetectionSizeInitial = ({min, max}) => {
    const copy = JSUtil.deepCopy(value);
    copy[idx].filter.detection_size.max = Math.clamp(copy[idx].filter.detection_size.max, 0, max);
    // todo much later: if there is a vertical video AND we use a solution that also has horizontal lines, an additional check is neeeded...
    onChange(copy);
  }

  useEffect(() => {
    getCameraById(camera.camera_id).then(cam => {
      setThisCam(cam);
      setDetectionSizeInitial({max: cam.height});
    }).catch(err => {
      setErrorR(JSUtil.unwrapErrorMessage(err));
      console.error("[MTR Camera Form Error]", err);
    });
  }, [])

  useEffect(() => {
    getCameraById(camera.camera_id).then(cam => setThisCam(cam))
  }, [camera])

  const [hasError, setError] = useState(false);

  if (thisCam === undefined) {
    return <LinearProgress/>
  }

  if (!camera || !thisCam) {
    return null;
  }

  const setConfidence = newValue => {
    const copy = JSUtil.deepCopy(value);
    copy[idx].filter.confidence_sensitivity = newValue;
    onChange(copy);
  }

  const setSnapshotLoading = newValue => {
    const copy = JSUtil.deepCopy(value);
    copy[idx].isSnapshotLoading = newValue;
    onChange(copy);
  }

  const setErrorMessage = newValue => {
    const copy = JSUtil.deepCopy(value);
    copy[idx].errorMessage = newValue;
    onChange(copy);
  }

  const setDetectionSize = newValuePair => {
    const copy = JSUtil.deepCopy(value);
    copy[idx].filter.detection_size = newValuePair;
    onChange(copy);
  }


  const hasArea = () => {
    if (!value[idx] || !value[idx].filter) return false;
    return filterObjects.some(object => value[idx].filter[object]?.length > 0);
  }

  const removeArea = () => {
    const copy = JSUtil.deepCopy(value);
    for (let i = 0; i < filterObjects.length; i++) {
      copy[idx].filter[filterObjects[i]] = [];
    }
    onChange(copy);
  }

  const getCurrentConfidence = () => {
    return value[idx] ? value[idx].filter.confidence_sensitivity : null;
  }

  const getWhatIsDrawable = () => {
    if (!filterObjects.length) return null;
    if (!filterObjects.includes('pass_lines')) return "Areas";
    if (JSON.stringify(filterObjects) === '["pass_lines"]') return "Lines";
    return "Objects";
  }

  const request = async () => {
    try {
      setErrorMessage(null);
      setSnapshotLoading(idx);
      const snapshot = await getSnapshot(camera.camera_id)
      const copy = JSUtil.deepCopy(value);
      SCUIUtils.Open(copy[idx].filter, idx, snapshot, getSnapshot, requestTypeSCUI)
    } catch (e) {
      console.error(e)
      setErrorMessage("Snapshot creating failed");
    } finally {
      setSnapshotLoading(null);
    }
  }

  const openRangeSelector = async () => {
    try {
      setErrorMessage(null);
      const snapshot = await getSnapshot(camera.camera_id, null, false);
      return snapshot
    } catch (e) {
      console.error(e)
      setErrorMessage("Snapshot creating failed");
    }
  }

  const RoiInfo = () => {
    const areaAlias = <abbr title="Regions of interest">(ROIs)</abbr>
    if (!value[idx] || !filterObjects.length) {
      return null;
    }

    const detFilter = value[idx] ? value[idx].filter : null;
    let areaText = filterObjects
      .filter(elem => detFilter[elem])
      .map((elem, idx) => `${detFilter[elem].length} ${elem.split("_")[0]}`).join(', ');

    if (filterObjects.some(elem => detFilter[elem] && detFilter[elem].length)) {
      return <div>
        Configured Areas {areaAlias}: <br/>
        {areaText}
      </div>
    } else {
      return (
        <div><p>No areas {areaAlias} are defined, entire screen is used.</p></div>
      )
    }
  }

  const RoiButtons = () => {
    if (!getWhatIsDrawable()) return null;
    return <React.Fragment> <CtaButton onClick={request} icon={faVectorSquare}
                                       className={"gradient-bg resetIcon-inButton"}>
      Draw {getWhatIsDrawable()}
      &nbsp;
      {camera.isSnapshotLoading === idx && <CircularProgress className="smallCircProgress"/>}
    </CtaButton>
      {hasArea() && <CtaButton className={"leftMargin-10"}
                               onClick={removeArea}>
        Remove all {getWhatIsDrawable()}
      </CtaButton>}
    </React.Fragment>
  }

  const LineInfo = () => {
    if (!value[idx]) {
      return null;
    }
    const lines = value[idx] ? value[idx].filter : null;

    if (filterObjects.some(elem => lines[elem] && lines[elem].length)) {
      return <div>
        Configured Lines:
        {lines.pass_lines.length}
      </div>
    } else {
      return (
        <div><p>No lines are drawn.</p></div>
      )
    }
  }

  const IconList = () => {
    if (!camera?.filter.object_types && !camera?.filter.object_type) {
      return null;
    }
    if (camera?.filter.object_types) {
      const MAXIMUM_NUMBER = 5;
      const part = camera.filter.object_types.slice(0, MAXIMUM_NUMBER);
      const iconList = part.map(elem => <React.Fragment key={elem}> {DetectionTypeIcon(elem)}</React.Fragment>)
      return <React.Fragment>{iconList}{camera.filter.object_types.length <= MAXIMUM_NUMBER ? "" : "..."}</React.Fragment>
    } else {
      const type = camera.filter.object_type;
      return [<React.Fragment key={type}> {DetectionTypeIcon(type)}</React.Fragment>]
    }
  }

  const onChangeDetectionTypes = (newType, hasError) => {
    if (newType !== null) {
      const copy = JSUtil.deepCopy(value);
      copy[idx].filter.object_types = newType;
      onChange(copy);
    }
    setError(hasError);
  }

  const onChangeDetectionType = (newType, hasError) => {
    if (newType !== null) {
      const copy = JSUtil.deepCopy(value);
      copy[idx].filter.object_type = newType;
      onChange(copy);
    }
    setError(hasError);
  }

  const onChangePrivacyMask = newValue => {
    const copy = JSUtil.deepCopy(value);
    copy[idx].filter.privacy_mask = newValue;
    onChange(copy);
  }

  const DetectionTypeWithPrivacyMask = () => {
    if (filterProperties.privacy_mask) {
      return <FormGroup className={"marginLeft05em"}>
        <FormControl variant="outlined" className={"formControl"}>
          <FormControlLabel
            control={<Switch checked={camera.filter.privacy_mask}
                             onChange={evt => onChangePrivacyMask(evt.target.checked)}
                             color={"primary"}/>}
            label={"Enable privacy mask"}
          />
        </FormControl>
        {camera.filter.privacy_mask && <DetectionType/>}
      </FormGroup>
    }
    return <DetectionType/>
  }

  const DetectionType = () => {
    if (filterProperties.object_type) {
      return <DetectionTypeSelector
        onChange={onChangeDetectionType}
        value={camera.filter.object_type}
        propertyElement={filterProperties.object_type}
        parentPath={parentPath}
        isRequired={true}
      />
    }
    if (filterProperties.object_types) {
      return <DetectionTypesSelector
        onChange={onChangeDetectionTypes}
        value={camera.filter.object_types}
        propertyElement={filterProperties.object_types}
        parentPath={parentPath}
        isRequired={true}
      />
    }
    return null;
  }

  const thisCamThumb = store.thumbnails[thisCam.id];

  return (<span key={idx}>
    <Accordion expanded={idx === openedInput} className={"cameraForm " + (hasError ? "hasError" : "")}
               onChange={(_, expanded) => setOpenedInput(expanded ? idx : null)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>

        <div>
          <span className={"h5"}>{thisCam.display_name}</span>
          &nbsp;&nbsp;
          <IconList/>
          {hasArea() && <FontAwesomeIcon icon={faObjectGroup}/>}
        </div>
        <div className={"cloneDelete"}>
          <ButtonGroup onClick={evt => evt.stopPropagation()}>
            {allowDuplicatedCamera && <CtaButton onClick={clone} primary>Clone</CtaButton>}
            {showChange && <CtaButton onClick={askForCameraChange}>Change</CtaButton>}
            <CtaButton onClick={deleteCam}>Delete</CtaButton>
          </ButtonGroup>
        </div>

      </AccordionSummary>
      <AccordionDetails>

        <div className={'setCamera' + (camera.isSnapshotLoading ? ' covered' : ' ')} key={idx}>
          <div className={"roiEditHolder"}>
            <img src={thisCamThumb ? `data:image/png;base64,${thisCamThumb}` : dummyThumbnail}
                 className={"roiImage"} alt="Camera Thumbnail"
                 width={"320px"}/>

            <div className={"roiEditButtons roiEditButtons-counter"}>
                <span>
                  <RoiButtons/>
                  <RangeSelector onChange={setDetectionSize}
                                 open={openRangeSelector}
                                 getSnapshotRegenerateCurrent={async () => await getSnapshot(camera.camera_id, true)}
                                 detectionSize={value[idx]?.filter?.detection_size}
                                 defaultDetectionSize={alertTypeConfig.detection_size}
                                 width={thisCam.width}
                                 height={thisCam.height}
                                 alertType={alertType}
                  />
                  <br/> <br/>
                      <div className={"roiConfigInfo"}>
                        {
                          getWhatIsDrawable() === "Lines" ? <LineInfo/> : <RoiInfo/>
                        }
                      </div>
                  <br/>

                    <FormControl variant={"standard"}>
                      <InputLabel>{sensitivity.title}</InputLabel>
                      <Select native
                              value={getCurrentConfidence() || sensitivity.default}
                              onChange={evt => setConfidence(evt.target.value)}>
                        {
                          sensitivity.enum.map(elem => <option value={elem} key={elem}>{elem}</option>)
                        }
                      </Select>

                    </FormControl>
               </span>
            </div>
            <br/>
            <div className={"detectionTypeSelector"}>
              <DetectionTypeWithPrivacyMask/>
            </div>

          </div>

          {camera.errorMessage &&
            <React.Fragment>
              <br/><br/>
              <Alert severity={"error"} variant={"filled"}
                     onClose={() => setErrorMessage(null)}
                     dismissible key={camera.id}>
                {camera.errorMessage}
              </Alert>
            </React.Fragment>
          }

        </div>

      </AccordionDetails>
    </Accordion>
    <br/>
    </span>
  );
}
