import { getSingleChannel, getSingleSite, getUsersAccount } from "./newApiClient"
import { Alert } from "@mui/material"

const loader = {}
loader.site = async ({ params }) => {
  if (params.siteId === "new") {
    return null
  }
  return getSingleSite(params.siteId)
}

loader.channel = async ({ params }) => {
  if (params.channelId === "new") {
    return null
  }

  return getSingleChannel(params.channelId)
}

loader.accounts = async () => {
  return getUsersAccount()
}

loader.account = async () => {
  //return getAcc(params.accountId);
}

export function PageNotFound({ full }) {
  return <div id={"noRouter"} className={full && "full"}>
    <Alert severity={"error"} variant={"filled"}>
      The URL address you entered cannot be found.
    </Alert>
  </div>
}

export { loader }
