import React, {Component} from 'react';
import FormInput from "../components/FormInput";
import {Switch,FormControlLabel,TextField,Typography,Paper,Alert} from "@mui/material";
import ScheduleSelector from "../components/ScheduleSelector";
import MtrContext from "../MtrContext";
import {ValidationErrors} from "../form/FormUtils";
import ResourceQuota from "../components/ResourceQuota";
import Constant from "../constant";

class Task extends Component {
  constructor(props, context) {
    super(props, context);
    this.props = props;
  }

  getTaskSchema() {
    throw new Error("UNIMPLEMENTED");
  }

  formHeaderChanged() {
    throw new Error("UNIMPLEMENTED");
  }

  /**
   * returns the current task's category. Alarm or Counters
   * @return {taskTypeCategoy}
   */
  getCategory() {
    // taskName is stored differently in new alarm and existing alarm
    const taskType = this.props.taskInfo?.type || this.props.type[0];
    return this.context.store
      .alertTypes.find(el => el.type === taskType).category;
  }

  getTaskType() {
    return this.props.taskInfo?.type || this.props.type[0];
  }

  Error = () => {
    const {store, taskAlias, mtrApiClient} = this.context;

    if (this.state.error) {
      return (
        <Alert severity={"error"}
               variant={"filled"}
               className={"topMargin-30"}
               onClose={() => this.setState({error: false})}
               dismissible={"true"}>
          An error occurred while processing the {taskAlias}.<br/>
          Error: {this.state.error}
        </Alert>
      )
    }
    return null;
  }

  MainTitle() {
    throw new Error("UNIMPLEMENTED");
  }

  SecondaryTitle() {
    throw new Error("UNIMPLEMENTED");
  }

  CancelAndDeleteButton() {
    throw new Error("UNIMPLEMENTED");
  }

  DeleteButton() {
    throw new Error("UNIMPLEMENTED");
  }

  SaveButton() {
    throw new Error("UNIMPLEMENTED");
  }

  Header = () => {
    return (
      <React.Fragment>
        <Typography variant={"h5"} className={"h1"}>
          <this.MainTitle/>

          {
            this.SecondaryTitle() && <span id={"secondaryTitle"}>
              <this.SecondaryTitle/>
            </span>
          }
        </Typography>

        <span className={"floatingUpperButtons"}>
          <br/>
          <this.SaveButton/>
          <this.CancelButton/>
          <this.DeleteButton/>
          <br/><br/>
          <MtrContext.Consumer>
            {({store}) => this.state.isSubmitting ? null : <ResourceQuota store={store} isActive />}
          </MtrContext.Consumer>
          {(this.state.triedToSubmit || this.state.hasChanged) && <ValidationErrors validationErrors={this.state.validationErrors}/>}
          <this.Error/>
        </span>


      </React.Fragment>
    )
  }

  FormHeader = () => {
    const {store, taskAlias, mtrApiClient} = this.context;
    return (
      <div className={"taskHeader"}>


        <FormInput title={"Name"}>
          <TextField value={this.state.name || ""} fullWidth
                     variant={"standard"}
                     helperText={"Recognisable name of the alarm"}
                     onChange={e => {
                       this.setState({
                         name: e.target.value
                       }, this.formHeaderChanged)
                     }}
                     dataTestId={"NewSolutionNameInput"}/>
        </FormInput>

        {!Constant.HIDE_DISARM_ALARM.includes(this.getTaskType()) && (
          <React.Fragment>
          <br/>
          <FormInput>
          <FormControlLabel label="Armed" control={
          <Switch type="checkbox"
          id="isActiveCheckbox"
          color="primary"
          checked={this.state.isActive || false}
          onChange={e => {
          this.setState({
          isActive: e.target.checked
        }, this.formHeaderChanged)
        }}/>}/>
          </FormInput>
          </React.Fragment>
        )}


        <br/>
        <ScheduleSelector
          schedules={store.schedules}
          alarmCategory={this.getCategory()}
          value={this.state.scheduleId || null}
          onChange={e => {
            this.setState({
              scheduleId: e.target.value
            }, this.formHeaderChanged)
          }}
        />

      </div>
    )
  }

  UnableToRender = () => {
    const {store, taskAlias, mtrApiClient} = this.context;
    return (
      <React.Fragment>
        <Alert severity={"error"} variant={"filled"}>
          This {taskAlias} could not be rendered, because the matching Schema was not found. <br/>
          Please contact a system administrator to get more information about this.
        </Alert>
        <DeleteButton/>
      </React.Fragment>
    )
  }

  Form = () => {
    throw new Error("UNIMPLEMENTED");
  }

  render = () => {
    return (
      <Paper className={"padding-15"} variant={"outlined"}>
        <div className={`ribbon taskContainer ${this.state.isSubmitting ? "coverTasks" : ""}`}>
          <this.Form>
            <div className={"stickyRibbon"}>
              <this.Header/>
            </div>

            {this.getTaskSchema() ?
              <div>
                <this.FormHeader/>
              </div>
              :
              <this.UnableToRender/>
            }
          </this.Form>
        </div>
      </Paper>
    )
  }

}

Task.contextType = MtrContext;
export default Task;
