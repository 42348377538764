import React, {useState, useEffect} from 'react';
import { Alert } from '@mui/material'

import formDataStore from "../FormDataStore";
import Constant from "../../constant";
import Dispatcher from "../../dispatcher";
import CopyToClipboard from "../../ui/temporary/CopyToClipboard";

export default function ReadOnlyCopyText({options, path}) {
  const [value, setValue] = useState(formDataStore.getByPath(path));

  useEffect(() => {
    const d = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED || payload.contentType === Constant.FLUX.FORM_DATA_CHANGED) {
        setValue(formDataStore.getByPath(path))
      }
    })
  }, [])

  if (value) {
    return <CopyToClipboard value={value}>{value}</CopyToClipboard>
  } else {
    return <Alert variant={'filled'} severity={"info"}>{options.placeholder}</Alert>
  }
}
