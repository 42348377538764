import { Alert, Button, ButtonGroup, LinearProgress, Paper, TextField, Typography } from "@mui/material"
import { Build, Delete, Edit, IosShare, QueryStats, SwitchVideo, VideoCall } from "@mui/icons-material"
import { useEffect, useRef, useState } from "react"
import { copy, getObjectIcon, unwrapErrorMessage } from "../util"
import store from "../store"
import { DataGrid } from "@mui/x-data-grid"
import HelpPopup from "./HelpPopup"
import BatchSensitivityPopup from "./BatchSensitivityPopup"
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom"
import { deleteChannelsFromSite, getChannelsInSite } from "../newApiClient"
import TestInside from "./TestInside"
import Cover, { CoverItem, CoverTitle } from "./Cover"
import StatTable from "./StatTable"

export default function ChannelList({ state }) {
  const [openChannel, setOpenChannel] = useState(0)
  const [channelName, setChannelName] = useState("")
  const [openedStat, setOpenedStat] = useState()
  const navigate = useNavigate()
  const site = useRouteLoaderData("site")


  const _getChannelsFromStore = () => {
    if (store.orderedChannels) {
      return flattenChannels(store.orderedChannels[state.group])
    }
  }

  // const group = copy(store.getSiteById(state.group));
  const [channels, setChannels] = useState()
  const [filteredChannels, setFilteredChannels] = useState()
  const [searchText, setSearchText] = useState("")
  const [selectionModel, setSelectionModel] = useState([]) // channel id will be inside
  const [waiting, setWaiting] = useState(true)
  const [error, setError] = useState()
  const timeoutRef = useRef()
  const SEARCH_TIMEOUT_MS = 300
  //eslint-disable-next-line
  const { accountId, siteId } = useParams()


  useEffect(() => {
    setWaiting(true)
    getChannelsInSite(siteId)
      .then(res => {
        setChannels(res)
      })
      .finally(() => {
        setWaiting(false)
      })
  }, [])

  useEffect(() => {
    setFilteredChannels(channels)
  }, [channels])


  const openTestModal = (channel, channelName) => () => {
    setOpenChannel(channel)
    setChannelName(channelName)
  }

  const renderQuickButtons = cell => {
    if (!cell) {
      return ""
    }
    return <>
      <ButtonGroup size="small">
        <HelpPopup channel={cell.row} title={"Help"}
                   site={site}
                   left={true} />

        <Button className={"action  center noMinWidth"}
                onClick={openTestModal(cell.id, cell.row.name)}
                variant={"outlined"} title={"Upload image for test detections"}>
          <IosShare />
        </Button>
        <Button className={"action right"}
                onClick={() => navigate(`./${cell.id}`)}
                title={"List channels"} variant={"outlined"}>
          <Build />
          <span className={"hideSmall"}>&nbsp;Configure</span>
        </Button>

      </ButtonGroup>
    </>
  }

  const renderEditButton = cell => {
    if (!cell) {
      return ""
    }
    return (<>
        <span className={"pointer"}
              onClick={() => navigate(`./${cell.id}`)}>

            &nbsp;
          <span className={"rowItem siteListItem"}>
                {cell.value}
            </span>
        </span>
      </>
    )
  }

  const COLUMNS = [
    { field: "name", headerName: "Name", renderCell: renderEditButton, flex: 2, minWidth: 100 },
    {
      field: "tid", headerName: "Unique ID", flex: 1, minWidth: 100
    },
    {
      field: "detection_config",
      headerName: "Detections",
      sortable: false,
      renderCell: renderDetectionTypes, flex: 2
    },
    {
      field: "edit", headerName: "", width: 240, renderCell: renderQuickButtons, sortable: false
    }]

  const onChangeSearchText = evt => {
    const text = evt.target.value
    setSearchText(text)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
    timeoutRef.current = setTimeout(() => {
      filterChannels(text)
    }, SEARCH_TIMEOUT_MS)
  }

  const filterChannels = (text) => {
    if (text === "") {
      setFilteredChannels(channels)
    }
    setFilteredChannels(channels.filter(elem => elem.name.toLowerCase().includes(text.toLowerCase()) || elem.tid.toLowerCase().includes(text.toLowerCase())))
  }

  const batchDelete = async () => {
    if (!window.confirm("Selected channels will be deleted permanently.\nPress OK to confirm.")) {
      return
    }

    setError(null)
    setWaiting(true)
    try {
      await deleteChannelsFromSite(Number(siteId), selectionModel)
      // todo: this is disgusting, but i literally dont have time to finish this.
      // todo: do an actual reload.
      window.location.reload()
    } catch (err) {
      setError(unwrapErrorMessage(err))
    } finally {
      setWaiting(false)
    }
  }

  const onSensitivitySet = async () => {
    // todo: this is disgusting, but i literally dont have time to finish this.
    // todo: do an actual reload.
    window.location.reload()
  }

  const Buttons = () => {
    return <>
      {/*<Button className={"formSubmit action"} variant="outlined"*/}
      {/*        onClick={() => navigate("../..")}>*/}
      {/*  <ArrowBack/> Back*/}
      {/*</Button>*/}

      <Button className={"action"} title={"New channel (usually a camera)"} variant="contained"
              onClick={() => navigate("./new")}>
        <VideoCall />{" New Channel"}
      </Button>
      &nbsp;&nbsp;
      <Button className={"action"} title={"New NVR channel  (connect through NVR system)"} variant="contained"
              onClick={() => navigate("./new?nvr")}>
        <SwitchVideo />{" New NVR Channel"}
      </Button>
      &nbsp;&nbsp;
      <Button className={"action"} title={"Edit Site"} variant="contained"
              onClick={() => navigate("..")}>
        <Edit />{" Edit Site "}
      </Button>

      &nbsp;&nbsp;
      <Button className={"action"} variant="outlined"
              onClick={() => setOpenedStat(true)}>
        <QueryStats />{" Site stats "}
      </Button>
      {selectionModel.length > 0 && (<div className={"right"}>
        <br />
        <BatchSensitivityPopup selectedChannels={selectionModel} onSet={onSensitivitySet} />
        <Button className={"formSubmit action delete"} variant="contained" onClick={batchDelete}>
          <Delete />{" Delete Selected"}
        </Button>
        <br />
        <br />
      </div>)}
    </>
  }

  if (waiting || !filteredChannels) {
    return <>
      <div className={"progressBar"}><LinearProgress /></div>
    </>
  }


  return <>
    <Typography variant="h3" sx={{ my: 2 }}>
      Channels in Site {site.name}
    </Typography>

    <br />
    <Buttons />
    <br /><br />
    <TextField id="outlined-basic" label="Search" variant="outlined"
               fullWidth value={searchText} onChange={onChangeSearchText} />
    <br /><br />

    <DataGrid
      autoHeight
      rows={filteredChannels}
      columns={COLUMNS}
      pagination
      rowsPerPageOptions={[10, 50, 100, 500]}
      checkboxSelection
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel)
      }}
      selectionModel={selectionModel}
      disableSelectionOnClick={true}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }]
        }
      }}
    />

    <div className={(openChannel ? "open" : "hidden")}>
      <Paper className={"rightDrawer"}>
        <Cover>
          <CoverItem>
            <CoverTitle>Send test event to {channelName}</CoverTitle>
            <TestInside state={{ ...state, channel: filteredChannels }}
                        channel={openChannel}
                        popup={true} closePopup={() => setOpenChannel(false)} />


            <br />
            <br />
            <Button className={"action  fullwidth"}
                    onClick={() => setOpenChannel(0)}
                    variant={"outlined"}>
              Close
            </Button>
          </CoverItem>
        </Cover>

      </Paper>
    </div>

    <br />

    <Buttons />

    {waiting && <><br /><br /><LinearProgress /></>}

    {error && <><br /><br /><Alert severity="error" onClose={() => setError(undefined)}>{error}</Alert></>}

    {openedStat && <StatTable siteId={siteId}
                              close={() => setOpenedStat(null)}
                              site={site}
    />}

  </>
}

function flattenChannels(chs) {
  if (!chs) {
    return null
  }
  const flat = copy(chs)
  flat.map(elem => {
    elem.detections = []
    for (const [key, _] of Object.entries(elem.detection_config?.class_info)) {
      elem.detections.push(key)
    }
    return elem
  })
  return flat
}

function renderDetectionTypes(cell) {
  if (!cell) {
    return ""
  }

  return Object.keys(cell.row.detection_config?.class_info || {})
    ?.sort()
    .filter(elem => cell.row.detection_config.class_info[elem].sensitivity !== "OFF")
    .map(elem => <span key={elem} title={elem}>{getObjectIcon(elem)}</span>)
}

