export const createStore = () => ({
    alertTypes: null,
    categories: null,
    schemas: {},
    tasks: [],
    cameraDomainTypes: [],
    taskDomainTypes: [],
    cameras: [],
    camerasByAlertTypes: {},
    Product: {},
    thumbnails: {},
    architecture: null
  }
);

