import { InputAdornment, TextField } from "@mui/material"

export default function LatLonInput({ value, onChange, latOrLon, required }) {
  const onChangeDeg = evt => {
    let v = evt.target.value
    if (v === "") {
      v = null
    } else {
      v = parseFloat(v)
    }
    onChange(v)
  }

  return <TextField
    className="largeForm"
    type="number"
    label={latOrLon === "LAT" ? "GPS Latitude" : "GPS Longitude"}
    required={required}
    step={0.0000001}
    InputProps={{
      startAdornment: (
          <InputAdornment position="start">
              <>{value > 0 && (latOrLon === "LAT" ? "N" : "E")}{value < 0 && (latOrLon === "LAT" ? "S" : "W")}</>
          </InputAdornment>
      ),
      endAdornment: (
          <InputAdornment position="end">
              <>°</>
          </InputAdornment>
      )
    }}
    inputProps={{
      min: latOrLon === "LAT" ? -90 : -180,
      max: latOrLon === "LAT" ? 90 : 180,
      step: "any"
    }}
    value={(value !== null && value !== undefined) ? Math.round(value * Math.pow(10, 7)) / Math.pow(10, 7) : ""}
    variant="outlined"
    onChange={onChangeDeg}
  />

}
