import React from "react";
import EnumSelectCheckbox from "../fields/EnumSelectCheckbox";
import {faVest} from "@fortawesome/free-solid-svg-icons";
import {faUserHelmetSafety} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import formDataStore from "../FormDataStore";

export default function PpeSelector({value, propertyElement, parentPath, isRequired, path}) {

  const onChange = newValue => {
    formDataStore.saveToPath(path, newValue)
  }

  if (!propertyElement?.items?.enum) return null;
  propertyElement.items.label = getLabels(propertyElement.items.enum);
  const params = {onChange, value, propertyElement, parentPath, path,isRequired}
  return <EnumSelectCheckbox {...params}/>
}

function getLabels(enumScheme) {
  return enumScheme.map(elem => {
    return <span>
      <span className={"detTypeSel-icon"}>
      {PpeIcon(elem)}
      </span>
      {elem.capitalise().replace(/_/g, " ")}
    </span>
  })
}

function PpeIcon(name) {
  switch (name) {
    case "Helmet":
      return <FontAwesomeIcon icon={faUserHelmetSafety}/>
    case "Visibility Vest":
      return <FontAwesomeIcon icon={faVest}/>
    default:
      console.warn("[MTR] PpeSelector.js : No image found for type: ",name)
      return <React.Fragment>{name}</React.Fragment>
  }
}
