import enums from "../enums"
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { getObjectIcon, getObjectLabel } from "../util"
import { CoverItem } from "./Cover"

export default function SensitivityForm({ currentData, onChangeDetectionType, onChangeCB }) {
  const ObjectLabel = ({ value }) => {
    if (!Object.values(enums.DETECTION_TYPE).includes(value)) {
      console.error(`Unknown object label: ${value}`)
      return value
    }
    return <>{getObjectIcon(value)} {getObjectLabel(value)}</>
  }

  const { detection_config } = currentData
  return <>


      <CoverItem>
          <h4>Sensitivity</h4>
          <Grid container spacing={1} className={""}>
              {Object.values(enums.DETECTION_TYPE).map(type => {
          const current_detection_config = (detection_config?.class_info || {})[type] || { sensitivity: enums.SENSITVITY.OFF }
          return <Grid item container md={4} sm={6} key={type}>
              <FormControl className="largeForm">
                  <InputLabel><ObjectLabel value={type} /></InputLabel>
                  <Select
                value={current_detection_config.sensitivity}
                className={current_detection_config.sensitivity === enums.SENSITVITY.OFF ? "off" : "on"}
                label={type}
                name={type}
                onChange={onChangeDetectionType}
                required={true}
              >
                      {Object.keys(enums.SENSITVITY).map(sensitivity => (
                          <MenuItem value={sensitivity} key={sensitivity}>
                              {enums.SENSITVITY[sensitivity]}
                          </MenuItem>))}
                  </Select>
              </FormControl>
              <div className={"filterMotionless"}>
                  <FormControlLabel
                disabled={current_detection_config.sensitivity === enums.SENSITVITY.OFF}
                control={<Checkbox
                  checked={current_detection_config.sensitivity === enums.SENSITVITY.OFF ? false : current_detection_config.filter_motionless}
                  onChange={onChangeCB(type)}
                />} label="Filter Motionless Events" />
              </div>
          </Grid>
        })}
          </Grid>
      </CoverItem>


  </>
}
