import { Outlet } from "react-router-dom"
import Header from "../../component/Header"
import { useReducer } from "react"
import Navigation from "./Navigation"
import Security from "../../component/Security"

export default function AdminRoot() {
    const INITIAL_STATE = {}
    const reducer = (state) => state
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
    return <div id={"AdminRoot"}>
        <br />
        <br />

        <Header state={state} dispatch={dispatch} headerName={"U-Filter Administrator"} asAdmin />
        <Navigation />
        <div id={"AdminOutlet"}>
            <Security role={"ADMIN"} required>
                <Outlet />
            </Security>
        </div>
    </div>
}
