import React from "react";
import Integer from "./fields/Integer";
import String from "./fields/String";
import Bool from "./fields/Bool";
import Array from "./fields/SimpleArray";
import ObjectField from "./fields/ObjectField";
import EnumSelectCheckbox from "./fields/EnumSelectCheckbox";
import Constant from "../constant";
import EnumSelectOnlyOne from "./fields/EnumSelectOnlyOne";
import ListOfKeyValue from "./fields/ListOfKeyValue";

export default function FieldRouter(
  {
    propertyKey,
    propertyElement,
    isRequired,
    idPrefix,
    path,
    alertType,
    widgets,
    value,
    shortInput,
    taskId
  }) {
  const params = {propertyElement, isRequired, idPrefix, path, value, shortInput, widgets};
  params.id = `${idPrefix}-${propertyKey}`
  if (Constant.FormWidgets[alertType] && Constant.FormWidgets[alertType][propertyKey]) {
    const widgetInfo = Constant.FormWidgets[alertType][propertyKey];
    return (
      <React.Fragment>
        {
          React.createElement(widgets[widgetInfo.widget], {
            options: widgetInfo.options,
            alertType,
            taskId,
            ...params
          })
        }
      </React.Fragment>
    )

  } else if (propertyElement?.type) {

    // hide labels forcefully because we cant do dynamic label change

    if (unsafeExtraActions(propertyElement)) return "";

    switch (propertyElement.type) {
      case "object": {
        if (propertyElement.properties) {
          return <ObjectField {...params} />
        } else {
          return <ListOfKeyValue{...params} />
        }
      }
      case "integer": {
        return <Integer {...params} />;
      }
      case "string": {
        if (propertyElement?.enum?.length) {
          return <EnumSelectOnlyOne {...params}/>
        } else {
          return <String {...params} />;
        }

      }
      case "boolean": {
        return <Bool {...params} />;
      }
      case "array"  : {
        if (propertyElement?.items?.enum?.length) {
          return <EnumSelectCheckbox {...params}/>
        } else {
          return <Array {...params} />;
        }
      }
      case "hide" : {
        return null;
      }
      default: {
        console.error("Missing key", propertyKey, propertyElement.type)
        return null;
      }
    }
  }
  return null;
}

const unsafeExtraActions = (propertyElement) => {

  ///
  if (window._mtr_platform !== "X86") return false;

  // if (propertyElement?.title.toLowerCase() === "enable snapshot attach") return true;
  if (propertyElement?.title.toLowerCase() === "enable video attach") return true;
  ///

  return false;
}
