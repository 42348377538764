import Dispatcher from "./dispatcher"
import { DEFAULT_SITE_VALUE } from "./component/Site"
import { CHANNEL_DEFAULT_VALUE } from "./component/Channel"

class Store {
  // _p_groups;
  // _p_orderedChannels;
  // _p_allChannels;
  // _p_account;
  // tokens;

  constructor() {
    this._p_groups = undefined
    this._p_orderedChannels = undefined
    this._p_allChannels = undefined
    this._p_account = undefined
    this.tokens = {}
    this._p_siteList = []
    this.mail_server = {}
  }

  set account(to) {
    if (JSON.stringify(to) !== JSON.stringify(this._p_account)) {
      this._p_account = to
      Dispatcher.dispatch({ contentType: "ACCOUNT_CHANGED" })
    }
  }

  get account() {
    return this._p_account
  }

  set groups(to) {
    this._p_groups = to
    Dispatcher.dispatch({ contentType: "GROUPS_CHANGED" })
  }

  get groups() {
    return this._p_groups
  }


  set orderedChannels(ch) {
    this._p_orderedChannels = ch
    Dispatcher.dispatch({ contentType: "ORDERED_CHANNELS_CHANGED" })
  }

  set allChannels(ch) {
    this._p_allChannels = ch
    Dispatcher.dispatch({ contentType: "ALL_CHANNELS_CHANGED" })
  }

  get orderedChannels() {
    return this._p_orderedChannels
  }

  get allChannels() {
    return this._p_allChannels
  }

  getChannelById(id) {
    if (id === null) {
      return CHANNEL_DEFAULT_VALUE
    }
    return this._p_allChannels?.find(elem => elem.id === id) || {}
  }


  get siteList() {
    return this._p_siteList
  }

  set siteList(newList) {
    this._p_siteList = newList
  }

  getSiteById(id) {
    if (id === null) {
      return DEFAULT_SITE_VALUE
    }
    return this._p_siteList?.find(elem => elem?.id === id)
  }

}


const s = new Store()
window._Store = s
export default s

