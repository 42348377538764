import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, CardActionArea, CardContent, CardMedia, Typography, CardActions, Button} from "@mui/material";
import {Icon} from "./temporary/Icon";
import MtrContext from "../MtrContext";
import {faMicrochip} from "@fortawesome/pro-light-svg-icons";
import {AlarmIconCard} from "../components/AlarmTypeIcon";

/**
 * Shows icon when using both licences, to display which alarm is provided by which licence.
 * @param useBoth
 * @param id
 * @return {JSX.Element|null}
 * @constructor
 */
function AlarmProvidedBy({useBoth, id}) {
  if (useBoth) {
    return <MtrContext.Consumer>
      {
        ({store}) => <div className={"alertTypeCardProvidedBy"}>
          {store.Suite.all.safe.hideTasks.every(el => el !== id) && (
            <Icon path={"safe"} title={"This Solution/Counter is Provided by U-Safe Licence"}/>)
          }
          {store.Suite.all.alarm.hideTasks.every(el => el !== id) && (
            <Icon path={"alarm"} title={"This Solution/Counter is Provided by U-Alarm Licence"}/>)
          }
        </div>
      }
    </MtrContext.Consumer>
  } else return null;
}

function PowerConsumption({consumption}) {
  if (!consumption) {
    console.error("[MTR] cannot get resource consumption");
    return null;
  }

  const max = Math.round(consumption.max * 1000) / 10;
  const min = Math.round(consumption.min * 1000) / 10;

  let msg = max && max !== min ? `${min}% - ${max}%` : `${min}%`;
  if (consumption.multi_cam) msg += ' / camera'
  return <div className={"consumption"} title={`This Solution will use ${msg} of the device's resources.`}>
    <FontAwesomeIcon icon={faMicrochip}/>
    &nbsp;
    {msg}
  </div>
}

export default function AlertTypeCard({currentType, taskAlias, addName, useBoth}) {
  if (!currentType) return null;
  return (
    <Card
      className={'alarmTypeCard  enabledAlarmType'}
      onClick={() => {
        addName(currentType.type, currentType.display_name)
      }}
      dataTestId={"New" + currentType.type.replace(/\s/g, "") + "Button"}
      key={currentType.type}
    >

      <CardContent>
        <Typography variant={"h5"} className={"montserrat-bold"}>{currentType.display_name}</Typography>
        <br/>
        <AlarmProvidedBy useBoth={useBoth} id={currentType.type}/>
        <CardMedia className={"card-img-holder"}>
          <AlarmIconCard type={currentType.type}/>
        </CardMedia>
        <Typography variant={"body1"}> {currentType.description}</Typography>
      </CardContent>
      <PowerConsumption consumption={currentType.resource_consumption}/>
    </Card>)
}
