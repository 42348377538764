import React from "react";
import {Alert} from "@mui/material";
import {
  faBicycle,
  faBus,
  faCar,
  faMotorcycle, faPlane,
  faShip,
  faSubway,
  faTruck,
  faWalking
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export function ValidationErrors({validationErrors}) {
  if (!validationErrors?.length) return null
  return <React.Fragment>
    <br/><br/>
    <Alert severity={"warning"} variant={"filled"} className={"primaryAlert"}>
      Validation errors:<br/>
      {validationErrors.map(error => <p key={error}>{error}</p>)}
    </Alert>
  </React.Fragment>
}



export function DetectionTypeIcon(name) {
  switch (name) {
    case "TRAIN":
      return <FontAwesomeIcon icon={faSubway}/>
    case "PERSON":
      return <FontAwesomeIcon icon={faWalking}/>
    case "CAR":
      return <FontAwesomeIcon icon={faCar}/>
    case "BUS":
      return <FontAwesomeIcon icon={faBus}/>
    case "TRUCK":
      return <FontAwesomeIcon icon={faTruck}/>
    case "MOTORCYCLE":
      return <FontAwesomeIcon icon={faMotorcycle}/>
    case "BICYCLE":
      return <FontAwesomeIcon icon={faBicycle}/>
    case "BOAT":
      return <FontAwesomeIcon icon={faShip}/>
    case "AIRPLANE":
      return <FontAwesomeIcon icon={faPlane}/>
    default:
      return null
  }
}
