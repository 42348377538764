import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { Dashboard, Email, ExitToApp, Person, SwitchAccount, VpnKey } from "@mui/icons-material"

export default function Navigation() {
  useNavigate()
  return <>

      <Drawer
      id={"adminDrawer"}
      variant="permanent"
      anchor="left"
      ModalProps={{ keepMounted: false }}
    >
          <List>
              <ListItem key={"-"} disablePadding>
                  <ListItemButton disabled>
                      <ListItemText primary={<b>Admin Panel</b>} />
                  </ListItemButton>
              </ListItem>
            {adminMenu.map(({ display, url, icon }, _index) => (
                  <ListItem key={display}
                    className={window.location.href.includes(url) ? "active-url url-element" : "url-element"}
                    disablePadding>
                      <Link to={url}>
                          <ListItemButton>
                              <ListItemIcon>
                                  {icon}
                              </ListItemIcon>
                              <ListItemText primary={display} />
                          </ListItemButton>
                      </Link>
                  </ListItem>
        ))}
              <Divider />
              <ListItemButton disabled>
                  <ListItemText primary={<b>U-Filter</b>} />
              </ListItemButton>

            <Link to={"/"} className={"url-element"}>
              <ListItemButton>
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText primary={"Accounts"} />
              </ListItemButton>
            </Link>

          </List>
      </Drawer>
  </>
}

const adminMenu = [
  {
    display: "Dashboard",
    url: "/admin/dashboard",
    icon: <Dashboard />
  },
  {
    display: "Users",
    url: "/admin/users",
    icon: <Person />
  },
  {
    display: "Accounts",
    url: "/admin/accounts",
    icon: <SwitchAccount />
  },

  {
    display: "Licence",
    url: "/admin/licence",
    icon: <VpnKey />
  },
  {
    display: "SMTP Email",
    url: "/admin/smtp",
    icon: <Email />
  },

]
