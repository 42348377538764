import { useState } from "react"
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle, LinearProgress
} from "@mui/material"
import { ArrowBack, CropFree } from "@mui/icons-material"
import SensitivityForm from "./SensitivityForm"
import * as React from "react"
import { unwrapErrorMessage } from "../util"
import { GET_CHANNEL_DEFAULT_VALUE } from "../constant"
import { useParams } from "react-router-dom"
import { patchBatchSensitivity } from "../newApiClient"

export default function BatchSensitivityPopup({ selectedChannels, onSet }) {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(GET_CHANNEL_DEFAULT_VALUE())
  const [waiting, setWaiting] = useState()
  const [error, setError] = useState()

  const { siteId } = useParams()
  const show = () => {
    setData(GET_CHANNEL_DEFAULT_VALUE())
    setOpen(true)
  }

  const onChange = evt => {
    const { value, name } = evt.target
    const copy = { ...data }

    copy.detection_config.class_info[name].sensitivity = value


    setData(copy)
  }

  const onChangeCB = name => (evt) => {
    const { checked } = evt.target
    const copy = { ...data }
    copy.detection_config.class_info[name].filter_motionless = checked
    setData(copy)
  }

  const hide = () => {
    setOpen(false)
    setData(GET_CHANNEL_DEFAULT_VALUE())
  }

  const save = async () => {
    setWaiting(true)
    try {
      await patchBatchSensitivity(siteId, data.detection_config.class_info, selectedChannels)
      hide()
      onSet()
    } catch (err) {
      setError(unwrapErrorMessage(err))
    } finally {
      setWaiting(false)
    }
  }

  return <>
      <Button variant={"contained"} className={"action"} onClick={show}>
          <CropFree />
          &nbsp;
          Analysis settings
      </Button>
      <Dialog open={open} onClose={hide} maxWidth={"xl"}>
          <DialogTitle>Modify detection types and sensitivity</DialogTitle>
          <DialogContent>
              {open &&
                  <SensitivityForm currentData={data}
                           onChangeCB={onChangeCB}
                           onChangeDetectionType={onChange} grid={false} />
        }
              <Button className={"formSubmit action"} variant="outlined" onClick={hide}>
                  <ArrowBack /> Back
              </Button>
              <div className={"right"}>
                  <Button variant={"contained"} className={"action delete"} onClick={save}>Save</Button>
              </div>
              {waiting && <><br /><br /><LinearProgress /></>}
              {error && <><br /><br /><Alert severity="error" onClose={() => setError(undefined)}>{error}</Alert></>}
          </DialogContent>
      </Dialog>
  </>
}
