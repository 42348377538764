/*eslint no-unused-vars: ["off", { "varsIgnorePattern": "_" , "vars": "global" }]*/
import JSUtil from './src/JSUtil';
import Random from './src/Random.class'
import {IntEnum, Enum, RawEnum} from './src/Enum.class';
import DispatcherQueue from "./src/DispatcherQueue";
import PromiseUtil from './src/PromiseUtil';
import MockUtil from './src/MockUtil';
import GeomUtil from "./src/geom.util";
import Vector from "./src/vector";
import Const from "./src/constants";
import videoColors from "./src/videoColors";

/**
 * Note: make sure you do not invoke 'window' directly in any of these scripts, as 'window' is not available in workerRuntime.
 */

require('./src/TypePolyfill');


function useCommonCSS() {
  require('./src/styles/common.css');
}

function useFaceliftCSS() {
  require('./src/styles/facelift.css');
}

function useScopedBootstrap() {
  require('./src/styles/bootstrap-scoped.min.css');
}

//todo: add dynamic font loading


export {
  JSUtil,
  GeomUtil,
  PromiseUtil,
  MockUtil,
  Vector,
  Enum,
  RawEnum,
  IntEnum,
  Random,
  DispatcherQueue,
  useCommonCSS,
  useScopedBootstrap,
  useFaceliftCSS,
  Const,
  Const as CONST,
  videoColors
};
