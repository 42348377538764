import { Alert, Button, Collapse, Divider } from "@mui/material"
import { useState } from "react"
import TextInput from "../component/TextInput"
import { loginUser } from "../newApiClient"
import store from "../store"
import { useNavigate } from "react-router-dom"
import Dispatcher from "../dispatcher"
import { NEW_API_URL } from "../constant"
import { isDev } from "../util"

export default function Login() {
    const [mail, setMail] = useState("")
    const [pw, setPw] = useState("")
    const [error, setError] = useState("")

    useNavigate()
    const login = (withProvider, evt) => {
        evt.preventDefault()
        const req = {
            provider: withProvider,
            email: mail,
            password: pw
        }

        loginUser(req)
            .then(res => {
                Dispatcher.dispatch({ contentType: "LOGGED_IN" })
                store.token = res.data.response

            })
            .catch(err => {
                if (err?.response?.status === 403) setError("Wrong password or username.")
                else setError(err ? err.message : "Cannot log in")
            })

    }
    return (
        <div className={"LoginPage"}>
            <div className={"LoginCard"}>
                <img src={"/ua_logo_notext_gradient_light.svg"} id={"loginLogo"} alt={"login image"} />
                <h1>U-Filter</h1>
                <form onSubmit={(evt) => login("LOCAL", evt)}>
                    <TextInput className="w100" label="Email" onChange={evt => setMail(evt.target.value)}
                    />
                    <br /><br />
                    <TextInput label="Password"
                               className="w100"
                               onChange={evt => setPw(evt.target.value)}
                               type={"password"}
                    />

                    <Collapse in={!!error}>
                        <br />
                        <Alert
                            variant={"filled"} severity={"error"}
                            onClose={() => setError("")}>
                            {error}
                        </Alert>
                    </Collapse>
                    <br />
                    <div className={"ta-left subtle-aContainer"}>
                        <a href={"/"}
                           onClick={() => window.alert("Please contact your administrator to reset your password.")}>
                            Forgot Password
                        </a>
                    </div>

                    {/*{!isDev() && <>*/}
                    {/*  <div className={"ta-left subtle-aContainer"} onClick={() => {*/}
                    {/*    localStorage.setItem("dev", "1");*/}
                    {/*    window.location.reload();*/}
                    {/*  }}>*/}
                    {/*    <a href={"/"}>*/}
                    {/*      I am a dev/tester*/}
                    {/*    </a>*/}
                    {/*  </div>*/}
                    {/*  <br/>*/}

                    {/*  <br/>*/}
                    {/*</>*/}
                    {/*}*/}

                    <br /> <br />
                    <Button
                        type={"submit"}
                        variant="contained" className={"action"}>
                        Sign In
                    </Button>
                </form>
                <br />
                <br />
                <Divider />

                {/*todo: re-enable Login Google*/}
                {/*<form action={NEW_API_URL + "/login"}*/}
                {/*      content={"json"}*/}
                {/*      method={"post"}>*/}
                {/*  <Button style={{margin: '20px 0 3px 0'}} variant="contained" className={"action login"} type={"submit"}>*/}

                {/*    Sign in with Google*/}
                {/*  </Button>*/}
                {/*  <input type={"hidden"} name={"provider"} value={"GOOGLE"}/>*/}
                {/*</form>*/}
                <br /><br />
                Version 1.2.2
                <br />
                {/*<Button variant="contained" className={"action"} onClick={() => login("MICROSOFT")}>*/}
                {/*  Sign in with Microsoft*/}
                {/*</Button>*/}
            </div>
            {isDev() && <>
                <code style={{ margin: "10px auto", width: "550px", display: "block", fontSize: "14px" }}>
                    UI will connect to API location: {NEW_API_URL}<br />
                    Override this by writing localStorage.setItem("apiUrl","FULL_URL_OF_API") in console.<br />
                    For local dev, like this: http://127.0.0.1:8080/<br />
                    This is a dev message, obviously.
                </code>
            </>
            }
        </div>
    )
}

