import {JSUtil as Util} from 'jsutil';
import formDataStore from "./form/FormDataStore";

Util.wrapConfirm = (text) => {
  return {confirmButtonProps: {children: text}};
};
Util.wrapCancel = (text) => {
  return {cancelButtonProps: {children: text}};
};
Util.wrapButtons = (cancel, confirm) => {
  return {
    confirmButtonProps: {children: confirm},
    cancelButtonProps: {children: cancel}
  };
};

Util.validateAlertForm = (formData, errors) => {
  const formErrors = formDataStore.errors;
  const errors0 = errors || [];

  for (const [key, value] of Object.entries(formErrors)) {
    errors0.push(value)
  }

  if (formData.inputs && (Object.keys(formData.inputs).length === 0 && formData.inputs.length === 0)) {
    errors0.push("There is no selected camera (at least one is required).");
  }

  if (formData.input && !formData.input.length) {
    errors0.push("There is no camera selected (required).");
  }

  if (formData?.http_client_config?.enabled && formData.http_client_config.url === "") {
    errors0.push("Enabled HTTP client config must have an url.")
  }

  if (formData?.http_webhook_config?.enabled && (formData.http_webhook_config.url === "" || !formData.http_webhook_config.url)) {
    errors0.push("Enabled HTTP Webhook config must have an url.")
  }

  if (formData?.email_notification_config?.enabled && !formData.email_notification_config.recipients.length) {
    errors0.push("Enabled email notification config must have at least one recipient.")
  }

  if (formData?.csm_client_config?.enabled && !formData?.csm_client_config?.email?.length) {
    errors0.push("Enabled Central Station Monitoring Support config must have an e-mail address.")
  }

  return errors0;
};

Util.convertObjectToList = (object, selectedOnly) => {
  const list = [];
  const keys = Object.keys(object);
  for (let i = 0; i < keys.length; i++) {
    if (!selectedOnly || object[keys[i]]._UI_selected) {
      list.push(object[keys[i]]);
    }
  }
  return list;
}

Util.convertFromListToObject = list => {
  const object = {};
  if (!list) {
    return object;
  }

  if (typeof list === "string") {
    list = JSON.parse(list)
  }

  if (list.length === undefined) {
    return list;
  }

  for (let i = 0; i < list.length; i++) {
    object[list[i].camera_id] = list[i];
  }

  return object;
}

Util.confirmNavigation = () => window.confirm("When navigating away, current solution changes will be lost.\nPress OK to confirm.")

export default Util;
