import React, { useEffect, useRef, useState } from "react"
import { Alert, Button, Dialog, DialogActions, DialogContent, Slider, Typography } from "@mui/material"
import Shape from "../classes/Shape"
import { DEFAULT_SIZE_FILTER } from "../constant"

let minObj, maxObj;

export default function RangeSelector({
                                        onChange,
                                        width,
                                        height,
                                        image,
                                        detectionSize,
                                        isSet
                                      }) {

  const lowerLimit = DEFAULT_SIZE_FILTER.min;
  const upperLimit = Math.min(DEFAULT_SIZE_FILTER.max, height);

  const showHorizontalHints = false;
  const [show, setShow] = useState(false);
  const canvas = useRef();
  const imageCv = useRef()
  detectionSize = detectionSize || {
    min: DEFAULT_SIZE_FILTER.min,
    max: Math.min(DEFAULT_SIZE_FILTER.max, height)
  };

  let dragTarget = null;
  let startX = null;
  let startY = null;
  let imgObj;

  useEffect(() => {
    if (show) {
      setInitialCanvas();
    }
    minObj = new Shape(Shape.POS_PERCENTAGE.min, canvas, detectionSize?.min, Shape.CONFIG.min, width, height, showHorizontalHints);
    maxObj = new Shape(Shape.POS_PERCENTAGE.max, canvas, detectionSize?.max, Shape.CONFIG.max, width, height, showHorizontalHints);
  }, [show])

  const handleSizeChange = (event, newValue) => {
    minObj.size = newValue[0];
    maxObj.size = Math.min(newValue[1], upperLimit);
    draw();
  }

  const resetSize = () => {
    onChange({min: lowerLimit, max: upperLimit})
  }

  const save = () => {
    onChange({min: minObj.size, max: maxObj.size})
    handleClose();
  }

  const recenter = () => {
    minObj.initializePosition()
    maxObj.initializePosition()
    draw();
  }

  const handleOpen = async () => {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const setInitialCanvas = (numOfTries = 1) => {
    const imageCvElem = imageCv.current;
    const dragCvElem = canvas.current;
    if (!imageCvElem || !dragCvElem) {
      if (numOfTries < 5) {
        numOfTries++;
        setTimeout(() => setInitialCanvas(numOfTries), 100);
      }
      return;
    }
    setInitialImage();
  }

  const setInitialImage = (newSnapshot = image) => {
    const imageCvElem = imageCv.current;
    const dragCvElem = canvas.current;
    const ctx = imageCvElem.getContext("2d");
    imgObj = new Image();

    imgObj.onload = function () {
      const ratio = imageCvElem.width / imgObj.width;
      const height = imgObj.height * ratio;
      imageCvElem.height = height;
      dragCvElem.height = height;
      ctx.drawImage(imgObj, 0, 0, imgObj.width, imgObj.height, 0, 0, imageCvElem.width, height);
      recenter();
    };
    draw();
    imgObj.src = "data:image/png;base64," + newSnapshot;
  }

  const draw = () => {
    const imageElem = imageCv.current;
    const canvasElem = canvas.current;
    if (!canvasElem || !imageElem) {
      return;
    }

    const ctx = canvasElem.getContext("2d");
    ctx.clearRect(0, 0, canvasElem.width, canvasElem.height);

    maxObj.draw(showHorizontalHints);
    minObj.draw(showHorizontalHints);

  }

  const handleMouseDown = e => {
    startX = Number(e.nativeEvent.offsetX - canvas.current.clientLeft);
    startY = Number(e.nativeEvent.offsetY - canvas.current.clientTop);
    if (minObj.isInHitbox(startX, startY)) {
      dragTarget = minObj
    } else if (maxObj.isInHitbox(startX, startY)) {
      dragTarget = maxObj
    }
  }
  const handleMouseMove = e => {
    if (!dragTarget) {
      return;
    }
    const mouseX = Number(e.nativeEvent.offsetX - canvas.current.clientLeft);
    const mouseY = Number(e.nativeEvent.offsetY - canvas.current.clientTop);
    const dx = mouseX - startX;
    const dy = mouseY - startY;
    startX = mouseX;
    startY = mouseY;
    dragTarget.x += dx;
    dragTarget.y += dy;
    draw();
  }
  const handleMouseUp = _ => {
    dragTarget = null;
  }
  const handleMouseOut = e => {
    handleMouseUp(e);
  }

  const Btn = () => {
    const missingLimits = lowerLimit === null || upperLimit === null;
    return (<React.Fragment>
        &nbsp;
        <Button onClick={handleOpen} // icon={faLineHeight}
                disabled={missingLimits}
                variant={"contained"}
                className={"action"}>Set object size
        </Button>
        &nbsp;
        <Button onClick={resetSize} // icon={faLineHeight}
                disabled={missingLimits || !isSet}
                variant={"contained"}
                className={"action delete"}>Restore default
        </Button>
        {missingLimits && <React.Fragment>
            <br /><br />
            <Alert variant={"filled"} severity={"error"}>
                There is no information about default detection sizes, please try to reload the page.</Alert>
        </React.Fragment>}
      </React.Fragment>
    )
  }

  if (!show) {
    return <Btn/>
  }

  return <React.Fragment>
      <Button />
      <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={show}
            className={"fullDialog multilineBottom"}>
          <DialogContent>
              <Typography variant={"h5"} className={"montserrat-bold"}>
                  Set smallest and largest object height {showHorizontalHints && "and width"}
              </Typography>

              <div className={"rangeSelectorWrapper grabCursor"}>
                  <canvas id={"RangeSelectorImage"}
                  ref={imageCv}
                  {...Shape.CANVAS_DIM}
          />
                  <canvas id={"RangeSelectorCanvas"}
                  ref={canvas}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseOut={handleMouseOut}
                  {...Shape.CANVAS_DIM}
          />
              </div>

          </DialogContent>
          <DialogActions id={"objectSizeAction"}>
              <p>Drag <i>min</i> and <i>max</i> size indicators anywhere on the picture.
                  Use the slider below to set sizes.
              </p>

              <span className={"rangeSelectorFooter"}>
                  <span className={"rangeSelectorButtons"}>
                      {/*<Tooltip title="Restore position of indicators">*/}
                      {/*<IconButton className={"cta"} onClick={recenter}>*/}
                      {/*<DuotoneIcon icon={faCrosshairs}/>*/}
                      {/*</IconButton>*/}
                      {/*</Tooltip>*/}
                  </span>
                  <span className={"rangeSelectorSlider"}>
                      <Slider
              defaultValue={[detectionSize.min, detectionSize.max]}
              onChange={handleSizeChange}
              valueLabelDisplay="auto"
              min={lowerLimit}
              max={upperLimit}
            />
                  </span>
                  <span className={"rangeSelectorButtons"}>
                      <Button onClick={save} variant={"contained"} className={"action"}>
                          Set sizes
                      </Button>
            &nbsp;
                      <Button onClick={handleClose} variant={"outlined"} className={"action"}>
                          Discard
                      </Button>
                  </span>
              </span>
          </DialogActions>
      </Dialog>
  </React.Fragment>
}
