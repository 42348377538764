import enums from "./enums"
import {
  DirectionsBike,
  DirectionsBoat,
  DirectionsBus,
  DirectionsCar,
  DirectionsWalk,
  Flight,
  LocalShipping,
  Train,
  TwoWheeler
} from "@mui/icons-material"
import { MAX_DIMENSION, SMTP_USER_DELIMITER } from "./constant"
import store from "./store"

export function unwrapErrorMessage(error) {

  if (!error.response) {
    return error.message || "Network error"
  }

  if (error.response.data && typeof error.response.data === "string") {
    return error.response.data
  }

  return error.response.statusText || error.message
}

export function copy(obj) {
  if (!obj) return null

  return JSON.parse(JSON.stringify(obj))
}

export function getObjectLabel(value) {
  switch (value) {
    case enums.DETECTION_TYPE.CAR:
      return "Car"
    case enums.DETECTION_TYPE.PERSON:
      return "Person"
    case enums.DETECTION_TYPE.BUS:
      return "Bus"
    case enums.DETECTION_TYPE.TRUCK:
      return "Truck"
    case enums.DETECTION_TYPE.BOAT:
      return "Boat"
    case enums.DETECTION_TYPE.BICYCLE:
      return "Bike"
    case enums.DETECTION_TYPE.MOTORCYCLE:
      return "Motorbike"
    case enums.DETECTION_TYPE.TRAIN:
      return "Train"
    case enums.DETECTION_TYPE.AIRPLANE:
      return "Airplane"
  }
}

export function getObjectIcon(value) {
  switch (value) {
    case enums.DETECTION_TYPE.CAR:
      return <DirectionsCar key={value} />
    case enums.DETECTION_TYPE.PERSON:
      return <DirectionsWalk key={value} />
    case enums.DETECTION_TYPE.BUS:
      return <DirectionsBus key={value} />
    case enums.DETECTION_TYPE.TRUCK:
      return <LocalShipping key={value} />
    case enums.DETECTION_TYPE.BOAT:
      return <DirectionsBoat key={value} />
    case enums.DETECTION_TYPE.BICYCLE:
      return <DirectionsBike key={value} />
    case enums.DETECTION_TYPE.MOTORCYCLE:
      return <TwoWheeler key={value} />
    case enums.DETECTION_TYPE.TRAIN:
      return <Train key={value} />
    case enums.DETECTION_TYPE.AIRPLANE:
      return <Flight key={value} />
  }
}

/**
 * @param image
 * @param _maxDim
 * @returns {{src: string, width: number, height: number}}
 */
export function resizeImageToMax(image, _maxDim = MAX_DIMENSION) {
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")

  const targetPortraitWidth = 1080
  const targetPortraitHeight = 1920
  const targetLandscapeWidth = 1920
  const targetLandscapeHeight = 1080

  let originalWidth = image.width
  let originalHeight = image.height

  // Determine orientation
  let isLandscape = originalWidth > originalHeight

  let targetWidth = isLandscape ? targetLandscapeWidth : targetPortraitWidth
  let targetHeight = isLandscape ? targetLandscapeHeight : targetPortraitHeight

  let aspectRatio = originalWidth / originalHeight

  // Calculate new dimensions to maintain aspect ratio
  let newWidth = Math.min(targetWidth, originalWidth)
  let newHeight = newWidth / aspectRatio

  if (newHeight > targetHeight) {
    newHeight = targetHeight
    newWidth = newHeight * aspectRatio
  }
  canvas.width = newWidth
  canvas.height = newHeight
  ctx.drawImage(image, 0, 0, newWidth, newHeight)

  return {
    src: canvas.toDataURL(),
    height: Math.round(newHeight),
    width: Math.round(newWidth)
  }
}


export const getSMTP = (tid) => {
  if (!store.mail_server) {
    console.error("No mail server found.")
    return "??"
  }
  return [
    tid,
    "@",
    store.mail_server.mail_server
  ]
}


export const isDev = () => {
  return localStorage.dev === "1"
}
export const formEvtToJSON = (evt) => {
  return Object.fromEntries((new FormData(evt.target)).entries())
}
export const inPath = (path) => {
  return window.location.pathname.includes(path)
}
export const formatSmtpUser = (account, site) => account + SMTP_USER_DELIMITER + site
