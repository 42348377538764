import React, {useContext, useState} from "react";
import {
  getReasonPhrase,

} from 'http-status-codes';

import formDataStore from "../FormDataStore";
import OptionalObject from "./OptionalObject";
import {Alert} from "@mui/material";
import MtrContext from "../../MtrContext";
import {CtaButton} from "../../ui/temporary/CtaButton";
import {JSUtil} from "jsutil";
import Constant from "../../constant";


const Interactive = () => {
  const [sending, setSending] = useState(false);
  const [response, setResponse] = useState();
  const [responseText, setResponseText] = useState();
  const [severity, setSeverity] = useState();
  const context = useContext(MtrContext);

  const close = () => {
    setResponse();
    setResponseText("");
  }
  const submit = () => {
    setSending(true);
    context.mtrApiClient.testIntegration(formDataStore.data)
      .then(r => {
        setSeverity(r.success ? "success" : "warning");
        setResponse(getReasonPhrase(r.response_status_code));
        setResponseText(r.response_body);
      }).catch(err => {
      setSeverity("error");
      setResponse(JSUtil.unwrapErrorMessage(err));
      setResponseText("");
    }).finally(() => {
      setSending(false);
    });
  }
  let canSend = (formDataStore.data.input ? formDataStore.data.input.length : formDataStore.data.inputs.length);

  return <span>
    {canSend ? <span>
      <CtaButton size={"small"} onClick={submit}
                 disabled={sending}>{sending ? "sending..." : "send test event"}</CtaButton>
      </span> :
      <span>{Constant.ModularText.HttpInputCannotSendTestEvent}</span>
    }
    {response && <React.Fragment>
      <br/><br/>
      <Alert severity={severity} variant={"filled"} onClose={close}>
        {response}
        {responseText &&
          <span><br/><a className={"white-a"} onClick={() => alert('response:\n' + responseText)}>show full response</a></span>}
      </Alert>

    </React.Fragment>
    }

  </span>
};


export default function HttpClientConfigWrapper(props) {
  return <OptionalObject {...props} noWrap Interactive={<Interactive/>}/>

}
