import React, {useState, useEffect} from "react";
import formDataStore from "../FormDataStore";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import TextField from '@mui/material/TextField/';
import {CtaButton} from "../../ui/temporary/CtaButton";
import Constant from "../../constant";
import Dispatcher from "../../dispatcher";

const EMPTY_KV = {key: "", value: ""};

export default function ListOfKeyValue({id, propertyElement, path}) {
  const [list, setList] = useState(convertFromObj(formDataStore.getByPath(path)));
  const [errorMessage, setErrorMessage] = React.useState(null);

  useEffect(() => {
    const dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED || payload.contentType === Constant.FLUX.FORM_DATA_CHANGED) {
        setList(convertFromObj(formDataStore.getByPath(path)))
      }
    })
    return () => {
      Dispatcher.unregister(dispatcher);
    };
  }, [])

  const validate = newValue => {
    const keys = {}
    for (let i = 0; i < newValue.length; i++) {
      if (newValue[i].key === "" && newValue[i].value !== "") {
        throw new Error("Header key is required.");
      }
      if (newValue[i].key !== "" && Object.keys(keys).includes(newValue[i].key)) {
        throw new Error(`Repeated keys are not allowed: ${newValue[i].key}`)
      }
      keys[newValue[i].key] = newValue[i].value;

    }

    // duplicate keys?
  }

  const saveValue = newValue => {
    setList(newValue);
    try {
      validate(newValue);
      formDataStore.removeError(path);
      formDataStore.saveToPath(path, convertToObj(newValue));
    } catch (err) {
      formDataStore.addError(path, err.message);
      Dispatcher.dispatch({contentType: Constant.FLUX.VALIDATE_FORM})
      // if (true) {
      //   formDataStore.saveToPath(path, convertToObj(newValue)); // todo: potential breaking change
      //   /**
      //    * @description before this, the MTR ui validated all values, but kept an inconsistent state.
      //    * For example: if you delete a value from a required string, the UI will show a warning
      //    * but the internal state will stay the same.
      //    * This is bad because in U-Filter the validation also occurs on the api side.
      //    */
      // }
      setErrorMessage(err.message);
    }
  }

  const onChangeElem = (idx, obj) => {
    const copy = [...list];
    copy[idx] = obj;
    saveValue(copy);
  }

  const addLine = () => {
    const copy = [...list];
    copy.push(EMPTY_KV);
    setList(copy);
  }

  const deleteLine = idx => {
    const copy = [...list];
    copy.splice(idx, 1);
    saveValue(copy);
  }

  return <div className={"marginLeft05em"}>
    <p>{propertyElement?.title}</p>
    {list.map((elem, idx) => <Line idx={idx}
                                   key={idx}
                                   data={list[idx]}
                                   onChange={onChangeElem}
                                   deleteEnabled={list.length > 1}
                                   deleteLine={deleteLine}/>)}
                                   <br/>
    <CtaButton onClick={addLine}>+ Add</CtaButton>
  </div>
}

function convertFromObj(obj) {
  if (!obj || !Object.keys(obj).length) {
    return [EMPTY_KV];
  }
  return Object.keys(obj).map(key => {return {key: key, value: obj[key]}})
}

function convertToObj(list) {
  const obj = {}
  for (let i = 0; i < list.length; i++) {
    const elem = list[i];
    if (elem.key !== "") {
      obj[elem.key] = elem.value;
    }
  }
  return obj;
}

function Line({idx, data, onChange, deleteLine, deleteEnabled}) {
  const [error, setError] = useState(data.key === "");

  useEffect(() => {
    setError(data.key === "")
  }, [data])

  const onFormChange = (evt, name) => {
    let key, value;
    if (name === "key") {
      key = evt.target.value;
      value = data.value;
    } else {
      key = data.key;
      value = evt.target.value;
    }
    onChange(idx, {key, value});
  }

  return (
    <div>
      <br/>
      <TextField
        type="text"
        value={data.key || ""}
        onChange={evt => onFormChange(evt, 'key')}
        placeholder={"Key (required)"}
        variant={"standard"}
        error={error}
      />
      <TextField
        type="text"
        value={data.value || ""}
        onChange={evt => onFormChange(evt, 'value')}
        placeholder={"Value"}
        variant={"standard"}
      />
      <CtaButton onClick={() => deleteLine(idx)} disabled={!deleteEnabled}>
        <FontAwesomeIcon icon={faTrash}/>
      </CtaButton>

    </div>
  )
}
