import React from "react";
import {JSUtil} from "jsutil";
import FieldRouter from "../FieldRouter";

export default function ObjectField({propertyElement, idPrefix, path, noWrap, options, alertType, widgets}) {

  return <span className={!noWrap ? "objectFieldHolder" : ""}>{Object.keys(propertyElement.properties).map(property => {

    const childPath = JSUtil.deepCopy(path.concat(property));

    return <FieldRouter
      alertType={alertType}
      widgets={widgets}
      key={property}
      propertyKey={property}
      propertyElement={propertyElement.properties[property]}
      idPrefix={idPrefix}
      path={childPath}
      isRequired={propertyElement.required?.includes(property)}
      shortInput={options?.shortInputs?.includes(property)}
    />
  })}
    {options?.optionalSubtitle && <p>{options?.optionalSubtitle}</p> }
  </span>
}
