import React from 'react';

import EditTask from "../ui/EditTask";
import NewAlertView from './NewAlertView';
import MtrContext from "../MtrContext";

import Constant from "../constant";
import {JSUtil, PromiseUtil} from "jsutil";
import Util from "../util";
import FormRoot from "../form/FormRoot";
import NewTask from "./NewTask";
import CommonTask from "../components/CommonTask";
import formDataStore from "../form/FormDataStore";
import {ValidationErrors, ValidationErrorsUFilter} from "../form/FormUtils";

require("multi_task_runner/src/styles/alert.css");
export default class SingleTaskView extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.containerRef = React.createRef();
    this.props = props;
    this.state = {
      formData: props.initialFormData || {},
      validationErrors: []
    }


  };

  getTaskSchema() {
    const alertType = this.props.alertTypeId;
    return this.props.store.schemas[alertType]

  }


  onChange({formData}) {

    this.setState({hasChanged: true, formData: formData});
  }


  render() {

    const widgets = CommonTask.widgets;
    const alertType = this.props.alertTypeId;

    return (
      <MtrContext.Provider value={(
        {
          store: this.props.store,
          mtrApiClient: this.props.mtrApiClient,
          taskAlias: this.props.taskAlias,
          mtrLogger: this.props.mtrLogger || (() => false),
          getSnapshot: this.props.getSnapshot,
          Constant: this.props.Constant
        }
      )}>

        <FormRoot schema={this.getTaskSchema()}
                  alertType={alertType}
                  widgets={widgets}
                  idPrefix={"MTR_form_prefix"}
                  formData={this.state.formData}
                  onChange={this.props.onChange}
                  onSubmit={() => false}
                  onFailedSubmit={() => false}
                  validate={Util.validateAlertForm}
                  validationErrors={this.state.validationErrors}
                  displayName={this.state.name}
                  forceValidate={this.props.forceValidate}
                  setValidationErrors={val => {
                    if (this.props.surfaceValidationErrors) {
                      this.props.surfaceValidationErrors(val);
                    } else {
                      console.log('[MTR UI] there were validation errors, however, the parent component cannot be notified, no surfaceValidationErrors were given')
                    }
                    this.setState({validationErrors: val})
                  }}
        >


        </FormRoot>

      </MtrContext.Provider>
    )
  }

}
