import { Checkbox, Chip, FormControlLabel, Grid, Switch, TextField } from "@mui/material"
import enums from "../enums"
import Enums from "../enums"
import SmtpForm from "./SmtpForm"
import { useEffect, useState } from "react"
import { SmtpTest } from "./SmtpTest"
import { getSMTPExists, testSiteSmtp } from "../newApiClient"

const defaultSmtp = {
  encryption: "NONE"
}
export const EmailIntegrationDetails = ({ siteId, data, setData, dirty, smtpEdit, email_integration }) => {

  let customSmtp = data.email_integration_config.smtp_configuration
  const [enableCustomSmtp, setEnableCustomSmtp] = useState(!!customSmtp)
  customSmtp = customSmtp ?? defaultSmtp
  const setCustomSmtp = smtpConfiguration => {
    const newData = {
      ...data,
      email_integration_config: {
        ...data.email_integration_config
      }
    }
    if (smtpConfiguration) {
      newData.email_integration_config.smtp_configuration = smtpConfiguration
    } else {
      delete newData.email_integration_config.smtp_configuration
    }
    setData(newData)
  }

  return (<>
      <Grid item xl={6} xs={12}>
        <TextField className="largeForm"
          /*if IMMIX AND empty, we allow the value to be text*/
                   type={email_integration === Enums.INTEGRATION.IMMIX ? (!data.email_integration_config.target_address ? "text" : "email") : "email"}
                   label="Target address"
                   value={data?.email_integration_config?.target_address || ""} variant="outlined"
                   required={email_integration !== Enums.INTEGRATION.IMMIX}
                   onChange={evt => {
                     const clear = email_integration === Enums.INTEGRATION.IMMIX &&
                       evt.target.value.trim().length === 0
                     setData({
                       ...data, email_integration_config: {
                         ...data.email_integration_config,
                         target_address: clear ? null : evt.target.value
                       }
                     })
                   }}
        />
      </Grid>
      <Grid item xl={6} xs={12}>
        <FormControlLabel className="vam" control={<Checkbox
          checked={data.email_integration_config.attach_snapshot === undefined ? true : data.email_integration_config.attach_snapshot}
          onChange={evt => {
            setData({
              ...data, email_integration_config: {
                ...data.email_integration_config,
                attach_snapshot: !!evt.target.checked
              }
            })
          }}
          defaultChecked />}
                          label="Attach snapshot image" />
      </Grid>
      <Grid item xl={6} xs={12}>
        <FormControlLabel className="vam" control={
          <Switch
            checked={enableCustomSmtp}
            onChange={evt => {
              setCustomSmtp(null)
              setEnableCustomSmtp(evt.target.checked)
            }}
          />}
                          label="Use custom SMTP settings" />
      </Grid>

      <InfoAboutSMTP />

      <Grid item xl={8} xs={12}>
        {
          enableCustomSmtp &&
          <>
            <SmtpForm smtpState={customSmtp} setSmtpState={setCustomSmtp} edit={smtpEdit} />
            <SmtpTest enabled={true} dirty={dirty} apiCall={(recipients) => testSiteSmtp(siteId, recipients)} />
          </>
        }
      </Grid>
    </>
  )
}

const InfoAboutSMTP = () => {
  useEffect(() => {
    getSMTPExists()
      .then(r => setHasSmtp(r.data))
  }, [])
  const [hasSmtp, setHasSmtp] = useState(null)
  console.log(hasSmtp)
  if (hasSmtp == null) return ""
  return <span className={"smtpNotice"}>
  <Chip label={hasSmtp ? "A default SMTP server is set." : "No default SMTP server is set."} variant="outlined" />
    </span>

}

export const IntegrationDetails = ({ siteId, email_integration, data, setData, dirty, isCreating, smtpEdit }) => {
  if (email_integration === enums.INTEGRATION.BLACKHOLE) {
    return null
  }
  const props = { siteId, data, setData, isCreating, smtpEdit, email_integration }
  return <EmailIntegrationDetails dirty={isCreating || dirty} {...props} />
}
