import React, {useContext, useState} from "react";
import {
  getReasonPhrase,

} from 'http-status-codes';

import formDataStore from "../FormDataStore";
import OptionalObject from "./OptionalObject";
import {Alert, Link} from "@mui/material";
import MtrContext from "../../MtrContext";
import {CtaButton} from "../../ui/temporary/CtaButton";
import {JSUtil} from "jsutil";


const Interactive = () => {
  const [sending, setSending] = useState(false);
  const [response, setResponse] = useState();
  const [responseText, setResponseText] = useState();
  const [severity, setSeverity] = useState();
  const context = useContext(MtrContext);

  const close = () => {
    setResponse();
    setResponseText("");
  }
  const submit = () => {
    setSending(true);
    context.mtrApiClient.testBQIntegration(formDataStore.data)
      .then(r => {

        setSeverity(r.data === "" ? "success" : "warning");
        setResponse("Dataset accessed successfully");
        setResponseText("");
      }).catch(err => {
      setSeverity("error");
      setResponse(JSUtil.unwrapErrorMessage(err));
      setResponseText("");
    }).finally(() => {
      setSending(false);
    });
  }

  const canSend = !!formDataStore.data?.bigquery_table_config.dataset_name;

  return <span>
    <span>
      {canSend ? <CtaButton size={"small"} onClick={submit}
                            disabled={sending}>{sending ? "sending..." : "test dataset connection"}</CtaButton> :
        <CtaButton size={"small"} disabled>Enter a dataset name to test</CtaButton>
      }

    </span>

    {response && <React.Fragment>
      <br/><br/>
      <Alert severity={severity} variant={"filled"} onClose={close}>
        {response}
        {responseText &&
          <span><br/><a className={"white-a"} onClick={() => alert('response:\n' + responseText)}>
            show full response
          </a></span>}
      </Alert>

    </React.Fragment>
    }

  </span>
};


export default function BigQueryWrapper(props) {
  const options = {
    ...props.options,
    optionalSubtitle: <React.Fragment>Do not forget to <Link onClick={() => {
      if (window.confirm("When navigating away, current solution changes will be lost.\nPress OK to confirm.")) {
        window.location = "#/control_panel/Integration";
      }
    }}>set BigQuery credentials</Link>.<br/><br/></React.Fragment>
  };

  return <OptionalObject {...props} noWrap
                         options={{...options}}
                         Interactive={<Interactive/>}/>

}
