import React from "react";
export function Icon({path, title, type}) {
  const realType = type || '.svg';
  const fullPath = './icons/'+path+realType;
  let src;
  try {
    return <img className={"duotone-img"} src={require('./icons/' + path + realType).default} title={title}
                alt={title}/>
  } catch (err) {
    console.error(err)
    return null
  }
}
