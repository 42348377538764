import React, {useState, useEffect} from "react";
import {FormGroup, FormLabel, RadioGroup, Radio} from '@mui/material';
import FormControl from '@mui/material/FormControl/';
import FormControlLabel from '@mui/material/FormControlLabel/';
import formDataStore from "../FormDataStore";
import Dispatcher from "../../dispatcher";
import Constant from "../../constant";
import ObjectField from "../fields/ObjectField";
import Bool from "../fields/Bool";
import {JSUtil} from "jsutil";

const SELECTED_SMTP_CLIENT = "selected_smtp_client";
export default function EnumMultiplexer(props) {
  const {id, propertyElement, path, options} = props;
  const enabledField = options.enabledField;
  const enabledPath = JSUtil.deepCopy(path);
  const selectedPath = path.concat(SELECTED_SMTP_CLIENT);
  enabledPath.push(enabledField);
  const selectedFromPath = formDataStore.getByPath(selectedPath);

  const [on, setOn] = React.useState(formDataStore.getByPath(path));
  const [enabled, setEnabled] = useState(formDataStore.getByPath(enabledPath));
  const [selected, setSelected] = useState(selectedFromPath);
  const selectedMap = new Map(Object.keys(propertyElement.properties).map(key => [propertyElement.properties[key].title, key]));

  useEffect(() => {
    const dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED || payload.contentType === Constant.FLUX.FORM_DATA_CHANGED) {
        const currentValue = formDataStore.getByPath(path);
        setOn(currentValue)
      }
    })
    return () => {
      Dispatcher.unregister(dispatcher);
    };
  }, [])


  const onChangeRadio = (key, name) => {
    setSelected(name);
    try {
      formDataStore.removeError(selectedPath);
      formDataStore.saveToPath(selectedPath, name);
    } catch (err) {
      formDataStore.addError(selectedPath, err.message);
    }
  }

  useEffect(() => {
    const dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_CHANGED || payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED) {
        const currentEnabled = formDataStore.getByPath(enabledPath);
        if (currentEnabled !== enabled) {
          setEnabled(currentEnabled);
        }
        const currentSelected = formDataStore.getByPath(selectedPath);
        if (currentSelected !== selected) {
          setSelected(currentSelected);
        }
      }
    })
    return () => Dispatcher.unregister(dispatcher);
  }, [setEnabled, enabled, selected, setSelected])


  const selectedValue = selectedMap.get(selected)
  const propList = Object.keys(props.propertyElement.properties).sort();

  if (enabled) {
    return (
      <div>
        <FormGroup className={"marginLeft05em objectFieldHolder"}>
          <Bool {...props} path={enabledPath}
                propertyElement={props.propertyElement.properties[enabledField]}/>

          <FormControl component="fieldset" fullWidth>
            <FormLabel>Select which integration to use.</FormLabel>

            <RadioGroup value={selectedValue}>
              <div>
                {
                  propList.map((key, idx) => {
                      const el = props.propertyElement.properties[key];
                      if (key === enabledField) return "";
                      if (key === SELECTED_SMTP_CLIENT) return "";

                      return <span className={"flex flexSelector"} key={key}>
                        <FormControlLabel
                          control={<Radio value={key} color={"primary"}
                                          onChange={() => onChangeRadio(key, el.title)}
                                          name={key}/>}
                          label={el.title}
                        />
                      </span>
                    }
                  )
                }
              </div>
            </RadioGroup>
            <br/>
            {
              Array.from(selectedMap).map(([key, valueRealpath]) => {
                if (!key || !valueRealpath) return "";
                if (selected !== key) return "";

                const filteredProps = JSUtil.deepCopy(props.propertyElement.properties[valueRealpath]);
                if (!filteredProps) return "";

                const childProps = JSUtil.deepCopy(props);
                const childPath = JSUtil.deepCopy(path.concat(valueRealpath));
                childProps.propertyElement = filteredProps;
                childProps.path = childPath;
                delete childProps.options.optionalSubtitle

                return <ObjectField {...childProps} path={childPath}/>;
              })
            }

            {options?.optionalSubtitle && <div><br/>{options.optionalSubtitle}</div>}
          </FormControl>
        </FormGroup>
      </div>)
  } else {
    return <React.Fragment>
      <Bool {...props} path={enabledPath}
            propertyElement={props.propertyElement.properties[enabledField]}/>
    </React.Fragment>
  }


}
