import store from "../store"
import { Alert } from "@mui/material"

export default function Security({ children, role, roleNot, required }) {

  const { user } = store

  role = role ?? []
  roleNot = roleNot ?? []
  if (!user) {
    console.error("No user no cry")
  }


  const canDisplay = (
    user.roles.includes(role) &&
    !user.roles.includes(roleNot)
  )


  if (!canDisplay) {
    if (required) {
      return <Alert variant={"filled"} className={"shortAlert fullAlert"} severity={"error"}>
        This user does not have access to this page.
      </Alert>
    }
    return null
  }

  return children
}
