import React, {useEffect} from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel
} from '@mui/material';
import formDataStore from "../FormDataStore";
import Dispatcher from "../../dispatcher";
import Constant from "../../constant";
import {CtaButton} from "../../ui/temporary/CtaButton";
import Util from "../../util";

export default function EnumSelectCheckbox({
                                             isRequired, id, propertyElement, path, value, onChange = () => false
                                           }) {
  const [selected, setSelected] = React.useState(value || formDataStore.getByPath(path) || []);
  const [errorMessage, setErrorMessage] = React.useState(null)

  const REQUIRED_CHECKBOX_MESSAGE = `Required checkbox is empty`;
  const MINITEMS_MESSAGE = `${propertyElement.title} requires minimum ${propertyElement.minItems} elements checked`;
  const MAXITEMS_MESSAGE = `${propertyElement.title} requires maximum ${propertyElement.maxItems} elements checked`;

  useEffect(() => {

    const dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED || payload.contentType === Constant.FLUX.FORM_DATA_CHANGED) {
        const currentValue = formDataStore.getByPath(path);
        if (currentValue) {
          setSelected(currentValue)
        }
      }
    })
    return () => {
      Dispatcher.unregister(dispatcher);
    };
  }, [])

  useEffect(() => {

    if (value) {
      setSelected(value);
    } else {
      setSelected([]);
    }
  }, [value])

  const onChange0 = (el, forceRemove = false, forceAdd = false) => {

    let newValue = Util.deepCopy(selected);
    if (selected.includes(el) && !forceAdd) {
      newValue.splice(selected.indexOf(el), 1);
    } else if (!selected.includes(el) && !forceRemove) {
      newValue.push(el);
    }

    validateChange(newValue);
  }

  const validateChange = newValue => {
    let hasError = false;
    formDataStore.removeError(path);
    setErrorMessage(null);

    if (isRequired && newValue.length === 0) {
      formDataStore.addError(path, REQUIRED_CHECKBOX_MESSAGE);
      hasError = true;
      setErrorMessage(REQUIRED_CHECKBOX_MESSAGE);
    } else if (newValue.length < propertyElement?.minItems) {
      hasError = true;
      formDataStore.addError(path, MINITEMS_MESSAGE);
      setErrorMessage(MINITEMS_MESSAGE);
    } else if (newValue.length > propertyElement?.maxItems) {
      hasError = true;
      formDataStore.addError(path, MAXITEMS_MESSAGE);
      setErrorMessage(MAXITEMS_MESSAGE);
    }

    setSelected(newValue);
    onChange(newValue, hasError);

    try {
      formDataStore.saveToPath(path, newValue);
      formDataStore.removeError(path);
    } catch (err) {
      formDataStore.addError(path, err.message)
      setErrorMessage(err.message);
    }
  }
  const clearAll = () => {
    validateChange([]);
  }

  return (
    <React.Fragment>
      {propertyElement.minItems && <FormLabel component="legend">
        Select at least {propertyElement.minItems} item(s).
        <CtaButton className={"marginLeft-15"} size={"small"} onClick={clearAll}>Clear all</CtaButton></FormLabel>}
      <div className={"enumSelector-root"}>
        <FormControl component="fieldset" fullWidth error={errorMessage}>

          <FormGroup>
            <div className={"flex-container"}>
              {
                propertyElement.items.enum.map((el, idx) =>
                  <div className={"flex flexSelector"} key={idx}>
                    <FormControlLabel
                      control={<Checkbox color={"primary"} checked={selected.includes(el)}
                                         onChange={() => onChange0(el)}
                                         name={el}/>}
                      label={propertyElement.items.label ? propertyElement.items.label[idx] : el}
                    />
                  </div>
                )
              }
            </div>
          </FormGroup>
          {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}

        </FormControl>
      </div>

    </React.Fragment>
  )
}
