import Page from "../../component/Page"
import { DataGrid } from "@mui/x-data-grid"
import { getAccounts, getUsersInAccount } from "../../adminApiClient"
import { useEffect, useState } from "react"
import { Fab, LinearProgress } from "@mui/material"
import { Link, Outlet, useNavigate } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import Dispatcher from "../../dispatcher"

const columns = [
    {
        field: "id",
        headerName: "ID",
        width: 50
    },
    {
        field: "name",
        headerName: "Name",
        width: 150
    },
    {
        field: "tid",
        headerName: "SMTP Prefix",
        width: 130

    },
    // {
    //   field: 'adminNotes',
    //   headerName: 'Admin Note',
    //   width: 100,
    // },
    {
        field: "user",
        headerName: "Users",
        width: 300,
        renderCell: function rc(props) {
            const { value } = props
            if (!value) return "Loading.."
            if (value.length === 0)
                return <Link onClick={event => event.stopPropagation()}
                             to={`/admin/accounts/${props.row.id}/link`}
                             className={"userLink"}>
                    No users
                </Link>

            return <Link onClick={event => event.stopPropagation()}
                         to={`/admin/accounts/${props.row.id}/link`}
                         className={"userLink"}>
                {value.length}
                {" users : "}
                {value.join(",")}</Link>
        }

    }
]


export default function Accounts() {
    function selectItem({ id }) {
        navigate(`${id}`)
    }

    const navigate = useNavigate()
    const [accounts, setAccounts] = useState(false)
    const [usersByAccounts, setUsersByAccounts] = useState([])

    useEffect(() => {
        let req = getAccounts()
        req.then(value => {
            setAccounts(value)
        })

        const d = Dispatcher.register(payload => {
            if (payload.contentType === "GENERIC_RELOAD_PARENT") {
                getAccounts().then(value => {
                    setAccounts(value)
                })
            }
        })
        return () => {
            Dispatcher.unregister(d)
        }
    }, [])

    useEffect(() => {
        if (!accounts || accounts.length === 0) return
        Promise.all(
            accounts.map(account => getUsersInAccount(account.id))
        ).then(res => {
            setUsersByAccounts(res)
        })

    }, [accounts, setAccounts])


    const mergedAccountAndUserList = accounts && accounts?.map((account, i) => {
        return {
            ...account,
            user: usersByAccounts[i]?.map(user => user.display_name || user.email)
        }
    })

    return <Page title={"Account Management"}>
        <br />
        <Link to={"/admin/accounts/create"}>
            <Fab variant="extended"
                 size="medium" color="primary" id={"Accounts-AddFab"}>
                <AddIcon sx={{ mr: 1 }} />
                Create Account
            </Fab>
        </Link>

        <div id={"Accounts-DataGrid"}>
            {accounts === false ? <LinearProgress /> :
              <DataGrid
                    disableVirtualization
                    autoHeight
                    rows={mergedAccountAndUserList}
                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[20, 100, 500]}
                    onRowClick={selectItem}
                />
            }
        </div>
        <div className={"Account-Outlet"}>
            <Outlet context={[accounts]} />
        </div>
    </Page>
}
