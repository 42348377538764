import React from "react";

export default function FormInput({children, description, title, isRequired}) {
  return (
    <div className="form-group field field-string">
      {title && <label className="control-label">{title}
        {isRequired && <span className="required">*</span>}
      </label>
      }

      <p className="field-description">{description}</p>
      {children}
    </div>);
}
