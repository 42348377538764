import { Alert, Button, Checkbox, FormControlLabel, LinearProgress } from "@mui/material"
import { FileUpload } from "@mui/icons-material"
import { useState } from "react"
import { unwrapErrorMessage } from "../util"

import { useParams } from "react-router-dom"
import { postTestImage } from "../newApiClient"
import {NX_INTEGRATION_NAME} from "../constant";

// eslint-disable-function no-unused-vars
export default function TestInside({ popup = false, channel }) {
  const altResultImage = "Annotated result image"
  const [uploadFile, setUploadFile] = useState(null)
  const [downFile, setDownFile] = useState(null)
  const [waiting, setWaiting] = useState(false)
  const [errors, setErrors] = useState([])
  const [_openModal, setOpenModal] = useState(false)
  const [sendTo, setSendTo] = useState(false)
  let { channelId } = useParams()

  channelId = channel ?? channelId

  const parseResponses = (resp) => {
    const intKeys = [["smtp_response", "SMTP"], ["webhook_response", "webhook"], ["nx_response", NX_INTEGRATION_NAME]]
    return intKeys.flatMap(([intKey, intName]) => {
      if (intKey in resp && resp[intKey]["remote_response_status"] >= 300)
        return [`${intName} error: ${"remote_response_message" in resp[intKey] ? resp[intKey]["remote_response_message"] :"Unknown error."}`]
      else
        return []
    })
  }

  const uploadFileAction = async () => {
    setWaiting(true)
    setErrors([])
    setDownFile(null)
    try {
      const resData = await postTestImage(channelId, uploadFile, sendTo)
      const reader = new FileReader()
      reader.readAsText(resData)
      reader.onloadend = () => {
        const parsed = JSON.parse(reader.result)
        setDownFile(parsed.base64_encoded_annotated_image)
        if ("integration_sending_responses" in parsed) {
          setErrors(parseResponses(parsed.integration_sending_responses))
        }
        setWaiting(false)
      }
      reader.onerror = err => {
        setWaiting(false)
        setErrors([unwrapErrorMessage(err)])
      }
    } catch (err) {
      setWaiting(false)
      setErrors([unwrapErrorMessage(err)])
    }
  }

  const resetFileInput = evt => {
    evt.target.value = null
  }

  const onInputFileChanged = evt => {
    setUploadFile(evt.target.files[0])
  }
  const showFull = () => {
    const img = `<img src=${downFile}  alt=${altResultImage}>`
    popup = window.open()
    popup.document.write(img)
  }

  const Result = () => {
    if (!downFile) {
      return null
    }

    return <>
      <br /><br /> <br />
      <img className={"tinyImg "} src={downFile} alt={altResultImage}/>
      <br />
      <Button onClick={showFull} size="small" className={"action"} variant={"outlined"}>Open image in new tab</Button>
      <br />
    </>

  }

  return (<>
    <div className={"configHelp_"}>
      <>
        <FormControlLabel control={<Checkbox checked={sendTo} onChange={() => setSendTo(now => !now)} />}
                          label="Send the result of the analysis to integration system as well, if any are set up." />
        <br /> <br />
        <Button
          className={"formSubmit action"}
          color={"primary"}
          component="label"
          variant="outlined">
          Select image
          <input hidden type="file"
                 accept="image/jpeg"
                 onClick={resetFileInput}
                 onChange={onInputFileChanged}
          />

        </Button>

        <Button className={"formSubmit action"}
                variant="contained"
                disabled={!uploadFile}
                onClick={uploadFileAction}>
          <FileUpload /> Upload</Button>

        <br /><br />
        Maximum image upload size is 3840px × 3840px.

        <Result />

        {
          waiting && <div className={"progressBar"}><LinearProgress /></div>
        }
        {
          <>
            {
              errors.map((err) => (
                <>
                  <br/>
                  <Alert severity="error" onClose={() => setErrors( [])}>
                    {err}
                  </Alert>
                </>
              ))
            }
          </>
        }
      </>
    </div>
  </>)
}
