import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom"
import { Alert, Button, Divider, Paper, Typography } from "@mui/material"
import TextInput from "../../component/TextInput"
import { confirmDialogCtrlSkippable } from "../../component/temp/ConfirmDialog"
import { useEffect, useState } from "react"
import { deleteAccount, postAccount, putAccount } from "../../adminApiClient"
import Dispatcher from "../../dispatcher"
import unwrapErrorFromApi from "../../component/UnwrapErrorApi"
import AutoId from "../../component/AutoId"

const emptyAccount = {
  name: "",
  tid: ""
}

const resolveAccount = (accounts, accountIdNum) => {
  return accounts && accounts?.find(el => el.id === accountIdNum) || emptyAccount
}
export default function SingleAccount({ createAccount }) {
  const [accounts] = useOutletContext()
  let { accountId } = useParams()
  const navigate = useNavigate()
  const accountIdNum = Number(accountId)

  const [currentAccount, setCurrentAccount] = useState(resolveAccount(accounts, accountIdNum))

  useEffect(() => {
    setCurrentAccount(resolveAccount(accounts, accountIdNum))
  }, [accountId])

  // todo: show error when unknown id is entered

  const returnAndUpdate = () => {
    Dispatcher.dispatch({ contentType: "GENERIC_RELOAD_PARENT" })
    navigate("/admin/accounts")
  }

  if (!accounts) return null


  const setValue = name => evt => {
    setCurrentAccount(state => {
      return { ...state, [name]: evt.target.value }
    })
  }

  if (currentAccount === undefined) {
    if (createAccount) {

    } else {
      return <Paper className={"rightDrawer"}>
        <br />
        <div className={"sizeloadedBtns"}>
          <Button variant={"outlined"} className="wide" color="primary">
            Cancel
          </Button>
        </div>
        <br />
        <Alert severity={"error"}>
          Cannot find Account. Please try again.
        </Alert>
      </Paper>
    }
  }

  const deleteAccountFC = async evt => {
    const confirmed = await confirmDialogCtrlSkippable({
      message: <>This action will delete all account information, including assigned cameras and their
        settings.<br />
        This action cannot be undone.</>,
      title: "Delete Account?"
    }, evt)
    if (!confirmed) return
    deleteAccount(accountIdNum)
      .then(() => {
        returnAndUpdate()
      })
      .catch(unwrapErrorFromApi)
    return true
  }

  const saveOrCreate = () => {

    if (createAccount) {
      postAccount(currentAccount)
        .then(_ => {
          returnAndUpdate()
        })
        .catch(unwrapErrorFromApi)

    } else {
      putAccount(accountId, currentAccount)
        .then(_ => {
          returnAndUpdate()
        })
        .catch(unwrapErrorFromApi)
    }
  }

  return <>
    <Paper className={"rightDrawer"}>
      <Typography variant={"h5"} className={"line-clamp-3"}>
        {!createAccount ? <>
            Editing Account {currentAccount.name || "Unnamed"}
          </> :
          <>Create Account</>
        }
      </Typography>
      <br />
      <div className={"sizeloadedBtns"}>
        <Button variant={"contained"} onClick={saveOrCreate}>
          Save
        </Button>
        <Link to={"/admin/accounts"} className={"noa"}>
          <Button variant={"outlined"} color="primary">
            Cancel
          </Button>
        </Link>
      </div>

      <br /><br />
      {!createAccount && <>
        <TextInput label="Account ID" disabled size={"small"}
                   value={accountId} />
        <br /><br />
      </>
      }

      <TextInput label="Name *" onChange={setValue("name")}
                 value={currentAccount.name} />
      <br /><br />

      {/*<TextInput label="Administrator Notes" multiline maxRows={5}*/}
      {/*           defaultValue={currentAccount.adminNotes || " "}/>*/}
      {/*<br/><br/>*/}


      <AutoId label="Unique ID *" onChange={setValue("tid")}
              category={"account"}
              value={currentAccount.tid} />

      <br />
      <div className={"p"}>
        This ID will be used to prefix the SMTP username.
      </div>

      <br />
      {/*<br/><br/>*/}

      {/*{*/}
      {/*  !createAccount && <>*/}
      {/*    {currentAccount?.disabledAt &&*/}
      {/*      <><TextInput label="Account Disabled At" disabled size={"small"}*/}
      {/*                   value={currentAccount.disabledAt}/>*/}
      {/*        <br/><br/>*/}
      {/*      </>*/}
      {/*    }*/}

      {/*    <TextInput label="Account Created At" disabled size={"small"}*/}
      {/*               value={currentAccount.createdAt}/>*/}

      {/*    <br/>*/}
      {/*    <br/>*/}
      {/*  </>*/}
      {/*}*/}


      {/*<FormControlLabel*/}
      {/*  value="end"*/}
      {/*  control={<Checkbox/>}*/}
      {/*  label="Enable Account"*/}
      {/*/>*/}

      {/*<br/> <br/>*/}

      <Divider />
      <br />
      <div className={"sizeloadedBtns"}>
        {!createAccount ? <>
            <Link to={`link`} className={"noa"}>
              <Button variant={"outlined"}
                      className={"wide"} color={"primary"}>
                Edit associated Users
              </Button>
            </Link>

            <Button variant={"outlined"} onClick={deleteAccountFC}
                    className={"wide"} color={"error"}>
              Delete Account
            </Button></> :
          <>
            Users can be added to the account after it is created.
          </>
        }
      </div>

    </Paper>
  </>
}
