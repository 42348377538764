class PromiseQueue {

  constructor(errorHandler = console.log) {
    this.promise = Promise.resolve();
    this.errorHandler = errorHandler;
  }

  push(promiseFn) {
    this.promise = this.promise.then(() => promiseFn()).catch(err => this.errorHandler(err))
  }
}

export default {
  PromiseQueue,
  makeCancelable: (promise) => { // https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
      promise.then(
        val => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
        error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
      );
    });

    return {
      promise: wrappedPromise,
      cancel() {
        hasCanceled_ = true;
      },
    };
  },

  /*
  usage:
  await PromiseUtil.sleep(Constant.getSnapshotIntervalMs);
   */
  sleep: async millisec =>  { // https://stackoverflow.com/questions/52184291/async-await-with-setinterval
    return await new Promise(resolve => setTimeout(resolve, millisec));
  }
};
