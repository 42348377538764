import React, {useEffect, useState} from "react";
import formDataStore from "./FormDataStore";
import Constant from "../constant";
import FormElement from "./FormElement"
import Dispatcher from "../dispatcher";
import {JSUtil} from "jsutil";
import Util from "../util";

function fillFormData(formData, alertType, schema) {
  if (formData && !JSUtil.isObjectEqual(formData, {})) {
    formDataStore.data = formData;
  } else {
    formDataStore.data = getDefaultValues(schema, alertType);
  }
}

export default function FormRoot({
                                   alertType,
                                   schema,
                                   widgets,
                                   idPrefix,
                                   formData,
                                   onSubmit,
                                   displayName,
                                   children,
                                   onChange,
                                   validate,
                                   validationErrors,
                                   setValidationErrors,
                                   onFailedSubmit,
                                   taskId = null,
                                   forceValidate
                                 }) {

  const [currentData, setCurrentData] = useState(formDataStore.data);

  useEffect(() => {
    fillFormData(formData, alertType, schema);
    setValidationErrors(validate(formDataStore.data));
  }, [formData])

  useEffect(() => {
    formDataStore.displayName = displayName;
  }, [displayName])

  useEffect(() => {
    setValidationErrors(validate(formDataStore.data));
  }, [forceValidate])

  useEffect(() => {
    const dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_CHANGED) {
        setCurrentData(formDataStore.data);
        onChange({formData: formDataStore.data});
        setValidationErrors(validate(formDataStore.data));
      } else if (payload.contentType === Constant.FLUX.VALIDATE_FORM) {
        setValidationErrors(validate(formDataStore.data));
      }
    })
    formDataStore.alertType = alertType;
    return () => {
      Dispatcher.unregister(dispatcher);
      formDataStore.reset();
    };

  }, [])

  const onSubmit0 = (evt) => {
    evt.preventDefault();
    if (formDataStore.canSubmit && !validationErrors.length) {
      onSubmit({formData: formDataStore.data});
    } else {
      onFailedSubmit();
    }
  }


  if (formDataStore.data) {
    return (
      <React.Fragment>
        <form onSubmit={onSubmit0} autoComplete="off">
          {children}

          {Object.keys(schema.properties).map(propertyKey => {
            return (
              <div key={propertyKey}>
                <FormElement key={propertyKey}
                             propertyKey={propertyKey}
                             propertyElement={schema.properties[propertyKey]}
                             isRequired={schema.required?.includes(propertyKey)}
                             idPrefix={idPrefix}
                             value={currentData ? currentData[propertyKey] : ""}
                             widgets={widgets}
                             alertType={alertType}
                             taskId={taskId}
                >
                  {propertyKey}
                </FormElement>
              </div>
            )
          })}
        </form>
      </React.Fragment>
    )
  } else {
    return null;
  }

}

function getDefaultValues(schema, alertType) {
  const storeObj = getDefaultValues0(schema, alertType, 0)
  return storeObj;
}

function getDefaultValues0(schema, alertType) {
  const storeObj = {};

  if (!schema.properties) return;
  for (const [key, value] of Object.entries(schema.properties)) {
    if (value.default) {
      storeObj[key] = value.default;
    } else if (Constant.FormWidgets[alertType] && Constant.FormWidgets[alertType][key] &&
      Constant.FormWidgets[alertType][key].default) {
      const widgetInfo = Constant.FormWidgets[alertType][key];
      storeObj[key] = widgetInfo.default || null;
    } else {
      switch (value.type) {
        case "number":
        case "integer": {
          storeObj[key] = value.default !== undefined ? value.default : (value.minimum !== undefined ? value.minimum : null);
          break;
        }
        case "string": {
          const enumValue = value.enum?.length > 0 ? value.enum[0] : null;
          storeObj[key] = value.default || enumValue;
          break;
        }
        case "boolean": {
          storeObj[key] = value.default || false;
          break;
        }
        case "object": {
          const children = getDefaultValues0(schema.properties[key], alertType);
          storeObj[key] = children
          break;
        }
        case "array": {
          storeObj[key] = [];
          break;
        }
        default: {
          console.log(key, value.type)
        }
        // TODO handle other types if needed
      }
    }
  }
  return storeObj;
}

/* TODO FOR REFACTOR:
- required properties in objects
- elements in object are not revertable - hacked
 */
