import React, {useState, useEffect} from 'react';
import formDataStore from "../FormDataStore";
import {JSUtil} from "jsutil";
import {FormGroup, FormControl, TextField} from "@mui/material";
import Dispatcher from "../../dispatcher";
import Constant from "../../constant";

export default function HourMinute({value, propertyElement, parentPath, isRequired, path}) {
  const [time, setTime] = useState(value || formDataStore.getByPath(path));

  useEffect(() => {
    const dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_CHANGED || payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED) {
        setTime(formDataStore.getByPath(path))
      }
    })
    return () => Dispatcher.unregister(dispatcher);
  }, [])

  const onChange = newValue => {
    formDataStore.saveToPath(path, newValue)
  }

  const blur = e => {
    if (!e.target.value) {
      return;
    }
    onChange(JSUtil.formatHtmTimeToObj(e.target.value));
  }

  return <FormGroup className={"HourMinute"}>
    <FormControl variant="outlined" className={"formControl"}>
          <TextField
            value={JSUtil.formatTimeObjToHtml(time)}
            id="time"
            label="Alarm clock"
            type="time"
            onBlur={blur}
            onChange={e => {
              setTime(JSUtil.formatHtmTimeToObj(e.target.value))
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 60, // 1 min
            }}
            sx={{ width: 150 }}
          />
    </FormControl>
  </FormGroup>

}
