import SchemaTransform from "./SchemaTransformer";
import refParser from "@apidevtools/json-schema-ref-parser";
import formDataStore from "./form/FormDataStore";
import {JSUtil, PromiseUtil} from "jsutil";
import Dispatcher from "./dispatcher";
import Constant from "./constant";

const axios = require('axios');

/**
 * we are sorting primarily by display name, but if two alarms have
 * the same name (localeCompare returns 0), we sort them by their unique ID.
 */
const taskNameCmp = (a, b) => {
  return a.display_name.localeCompare(b.display_name) || a.id.localeCompare(b.id);
}
const axios_force_text_header = {
  headers: {'content-type': 'application/json'}
};

class MtrApiClient {

  static deleteUiSelected(properties) {
    const copy = JSUtil.deepCopy(properties);

    if (copy.inputs) {
      for (let j = 0; j < copy.inputs.length; j++) {
        delete copy.inputs[j]._UI_selected;
        delete copy.inputs[j].isSnapshotLoading;
        delete copy.inputs[j].errorMessage;
      }
    }

    if (copy.input) {
      delete copy.input._UI_selected;
      delete copy.input.errorMessage;
      delete copy.input.isSnapshotLoading;
    }
    return copy;
  }

  static addUiSelected(data) {
    const tasks = JSUtil.deepCopy(data);
    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].properties?.inputs) {
        for (let j = 0; j < tasks[i].properties.inputs.length; j++) {
          tasks[i].properties.inputs[j]._UI_selected = true;
        }
      }

      if (tasks[i].properties?.input) {
        tasks[i].properties.input._UI_selected = true;
      }
    }
    return tasks;
  }

  constructor(apiUrl, store) {
    this.apiUrl = apiUrl;
    this.store = store;
    this.getCameras().then(() => this.camerasFetched = true)
    this.getArch().then(() => null);
  }

  async getCameras() {
    const lambda = async () => {
      const response = await axios.get(`${this.apiUrl}/camera`);
      this.store.cameras = response.data;
      return true;
    }

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async getArch() {
    const lambda = async () => {
      const response = await axios.get(`${this.apiUrl}/system/architecture`);
      this.store.architecture = response.data || "UNKNOWN";
      window._mtr_platform = response.data;
      return true;
    }

    try {
      return await lambda();
    } catch (err) {
      this.store.architecture = "UNKNOWN";
      window._mtr_platform = "NOT_IMPLEMENTED";
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async getTaskTypes() {
    const lambda = async () => {
      const response = await axios.get(`${this.apiUrl}/task/types`);
      this.store.alertTypes = response.data.taskTypes;
      this.store.categories = response.data.categories;
      return true;
    }

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async getCameraListByTaskTypes(type, taskId) {
    const params = {};
    if (taskId) {
      params.task_id = taskId;
    }

    const lambda = async () => {
      const res = await axios.get(`${this.apiUrl}/camera/taskType/${type}`, {params});
      return res.data;
    }

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async getCameraById(cameraId) {
    const lambda = async () => {
      const res = await axios.get(`${this.apiUrl}/camera/${cameraId}`);
      return res.data;
    }

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async getTaskSchema(taskId) {
    const lambda = async () => {
      const response = await axios.get(`${this.apiUrl}/task/type/${taskId}/schema`);
      let schema = await refParser.dereference(response.data);
      this.store.schemas[taskId] = SchemaTransform.convertJsonSchema(schema, this.store);
      return true;
    }

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async getTaskDetails(id) {
    const lambda = async () => {
      const response = await axios.get(`${this.apiUrl}/task/${id}`);
      return response.data;
    }

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async getAllTasks() {
    const lambda = async () => {
      const response = await axios.get(`${this.apiUrl}/task`);
      const tasks = MtrApiClient.addUiSelected(response.data).sort(taskNameCmp);
      if (!JSUtil.isObjectEqual(tasks, this.store.tasks)) {
        this.store.tasks = tasks;
        Dispatcher.dispatch({
          actionType: Constant.FLUX.SOLUTIONS_CHANGED
        })
      }
      return true;
    }

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async getAllSchemas() {
    const lambda = async () => Promise.all(
      this.store.alertTypes.map(type => this.getTaskSchema(type.type))
    )

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async postTask(properties, display_name, active, type, schedule_id) {
    const lambda = async () => {
      const copy = MtrApiClient.deleteUiSelected(properties);
      const response = await axios.post(`${this.apiUrl}/task`, {
        properties: copy, type, display_name, active, schedule_id: schedule_id || null
      });
      Dispatcher.dispatch({actionType: Constant.FLUX.REFRESH_ALARM_LIST})
      return response.data;
    }

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async deleteTask(id) {
    const lambda = async () => {
      const response = await axios.delete(`${this.apiUrl}/task/${id}`);
      Dispatcher.dispatch({actionType: Constant.FLUX.REFRESH_ALARM_LIST})
      return response.data;
    }

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async putTask(id, properties, display_name, active, type, schedule_id) {
    const lambda = async () => {
      const copy = MtrApiClient.deleteUiSelected(properties);
      const response = await axios.put(`${this.apiUrl}/task/${id}`, {
        properties: copy, display_name, active, type, schedule_id: schedule_id || null
      });
      Dispatcher.dispatch({actionType: Constant.FLUX.REFRESH_ALARM_LIST})
      return response.data;
    }

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async activateAllTasks(shouldBeActive) {
    const lambda = async () => {
      await axios.put(`${this.apiUrl}/task/switch/${shouldBeActive.toString()}`)
      Dispatcher.dispatch({actionType: Constant.FLUX.REFRESH_ALARM_LIST})
    }

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async sendNxTestEvent(display_name, camera_ids) {
    try {
      const res = await axios.post(`${this.apiUrl}/system/nx/test`, {display_name, camera_ids})
      return res.data;
    } catch (err) {
      MtrApiClient.handleError(err);
    }
  }

  async testIntegration({http_client_config, input, inputs}) {
    const client = JSUtil.deepCopy(http_client_config);
    delete client.enabled;

    let cameraId;
    if (input) {
      cameraId = input[0].camera_id
    } else {
      cameraId = inputs[0].camera_id
    }

    const lambda = async () => {
      const response = await axios.post(`${this.apiUrl}/task/integration/test`,
        {
          ...client,
          task_type: formDataStore.alertType,
          camera_id: cameraId
        });
      return response.data;
    }

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async testBQIntegration(formData) {
    let {dataset_name} = formData.bigquery_table_config;

    const lambda = async () => await axios.post(`${this.apiUrl}/system/bigquery/test`, dataset_name, axios_force_text_header);

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }
  }

  async calculateResource(id, configuration) {
    let conf;

    if (configuration && configuration.properties) {
      conf = {...configuration, properties: MtrApiClient.deleteUiSelected(configuration.properties)}
    } else {
      conf = null;
    }

    const lambda = async () => {
      const response = await axios.post(`${this.apiUrl}/resource/calculation`, {
        task_id: id,
        task_config: conf
      })
      if (JSON.stringify(response.data) !== JSON.stringify(this.store.resource)) {
        this.store.resource = response.data;
        Dispatcher.dispatch({
          actionType: Constant.FLUX.RESOURCE_CHANGED,
          resource: response.data
        })
      }
      return response.data;
    }

    try {
      return await lambda();
    } catch (err) {
      return await MtrApiClient.handleError(err, lambda)
    }


  }

  async getCameraAssignments() {
    return await axios.get(`${this.apiUrl}/task/camera_assignments`);
  }

  static async handleError(error, lambda) {
    /* copy of ualarm HandleError */
    let cnt = 0;
    if (error.request?.status === 409 && lambda && typeof lambda === "function") {
      let e = error;
      while (cnt <= Constant.api409tryAgainTimes && error.request?.status === 409) {
        await PromiseUtil.sleep(Constant.api409tryAgainTimeout)
        try {
          cnt++;
          return await lambda();
        } catch (err) {
          e = err
        }
      }
      throw e;
    } else {
      throw error;
    }
  }

}

export default MtrApiClient;
