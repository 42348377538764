// noinspection JSUnusedLocalSymbols

import "./App.css"
import { Alert, Button } from "@mui/material"
import React, { useEffect } from "react"
import Login from "./page/Login"
import store from "./store"
import {
  createBrowserRouter,
  createRoutesFromElements,
  Link,
  Route,
  RouterProvider,
  useRouteError
} from "react-router-dom"
import AdminRoot from "./page/admin/AdminRoot"
import Users from "./page/admin/Users"
import Accounts from "./page/admin/Accounts"
import SingleAccount from "./page/admin/SingleAccount"
import CreateAccount from "./page/admin/CreateAccount"
import CreateUser from "./page/admin/CreateUser"
import SingleUser from "./page/admin/SingleUser"
import LinkAccountUser from "./page/admin/LinkAccountUser"
import Container from "react-modal-promise"
import { getMailServer } from "./newApiClient"
import { useCommonCSS } from "jsutil"
import Site from "./component/Site"
import SiteList from "./page/SiteList"
import ChannelList from "./component/ChannelList"
import Channel from "./component/Channel"
import { loader, PageNotFound } from "./Routes"
import HeaderContainer from "./HeaderContainer"
import AccountList from "./page/AccountList"
import Account from "./component/Account"
import AppWrapper from "./page/AppWrapper"
import AppIndex from "./AppIndex"
import Licence from "./page/admin/Licence"
import PWChange from "./page/user/PWChange"
import UserRoot from "./page/user/UserRoot"
import AdminSmtpConfiguration from "./component/AdminSmtpConfiguration"
import Dashboard from "./page/admin/Dashboard"

// eslint-disable-next-line
useCommonCSS()

function App() {

  useEffect(() => {
    getMailServer()
      .then(res => {
        store.mail_server = res
      })
  }, [])

  return <>
    <Container />
    <RouterProvider router={routes} />
  </>
}

const MissingItem = ({ item }) => {
  //eslint-disable-next-line
  let error = useRouteError()
  switch (item) {
    case "site" :
      return (<Alert variant={"filled"} className={"shortAlert fullAlert"} severity={"error"}>
        Cannot find Site. Does it exist, or do you have access to it?
      </Alert>)
  }

  return <>
    Unspecified Error.
  </>
}

const routes = createBrowserRouter(createRoutesFromElements(<Route path={"/"}
                                                                   element={<AppWrapper />}
                                                                   errorElement={<ErrorBoundary />}>

  <Route index element={<AppIndex />} />
  <Route path={"login"} element={<Login />} />
  <Route path={"/admin"} element={<AdminRoot user={store.user} />}>

    <Route path={"users"} element={<Users user={store.user} />}>
      <Route path={"create"} element={<CreateUser user={store.user} />} />
      <Route path={":userId"} element={<SingleUser user={store.user} />} />
      <Route path={":userId/link"} element={<LinkAccountUser />} />
    </Route>
    <Route path={"accounts"} element={<Accounts store={store} user={store.user} />}>
      <Route path={"create"} element={<CreateAccount user={store.user} />} />
      <Route path={":accountId"} element={<SingleAccount user={store.user} />} />
      <Route path={":accountId/link"} element={<LinkAccountUser />} />
    </Route>
    <Route path={"licence"} index element={<Licence store={store} user={store.user} />} />
    <Route path={"dashboard"} index element={<Dashboard store={store} user={store.user} />} />
    <Route path={"smtp"} index element={<AdminSmtpConfiguration />} />
    <Route path="*" element={<PageNotFound />} />
  </Route>

  <Route path={"/user"} element={<HeaderContainer />}>
    <Route index element={<UserRoot />} />
    <Route path={"password"} element={<PWChange />} />
  </Route>

  <Route path={"/acc"} element={<HeaderContainer />}
         loader={loader.accounts} id={"accounts"}>
    <Route index element={<AccountList />} />
    <Route path={":accountId"} id={"acc"}>
      <Route index element={<Account />} />
      <Route path={"sites"}>
        <Route path={""} element={<SiteList />} />
        <Route path={":siteId"}
               errorElement={<MissingItem item={"site"} />}
               loader={loader.site} id={"site"}>
          <Route index element={<Site />} />
          {/*<Route path={"smtp"} element={<Site />}/>*/}
          <Route path={"channels"}>
            <Route index element={<ChannelList />} />
            <Route path={"new"} element={<Channel isCreating />} />
            <Route path={":channelId"} loader={loader.channel} id={"channel"} element={<Channel />}>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Route>

  </Route>
  <Route path="*" element={<PageNotFound full />} />
</Route>))


function ErrorBoundary() {
  let error = useRouteError()
  console.error("Error Boundary Caught Error", error)

  return <>
    <Alert variant={"filled"} className={"shortAlert fullAlert"} severity={"error"}>
      An application error occurred. Please reload U-Filter, or try to log in again.
    </Alert>
    <br />
    <Link to={"/"} className={"noa"}>
      <Button>
        Login Screen
      </Button>
    </Link>
  </>
}

export default App
