import React from 'react'

import ContentRoot from "./ui/ContentRoot";
import {useCommonCSS, JSUtil} from "jsutil";

import MtrApiClient from "./MtrApiClient";
import {createStore} from "./store";
import Dispatcher from "./dispatcher";
import Constant from "./constant";
import SCUtils from "./form/SCUIUtils";
import {BrowserRouter, HashRouter} from "react-router-dom";
import SingleTaskViewRoot from "./SingleTaskViewRoot";

require('multi_task_runner/src/styles/style-override.css');
require('multi_task_runner/src/styles/tab-style.css');
require('multi_task_runner/src/styles/double-dialog.css');
useCommonCSS();
export {SingleTaskViewRoot};



/**
 * Entrypoint for Multi Task Runner UI Library React Component
 * @param {string}   apiUrl Url of Multi Task Runner API.
 * @param {string}   taskAlias Alias display name of the tasks.
 * @param {function} onStatusChange Callback for status change.
 * @param {promise}  getCameraDomainTypes fn for getting camera domain types.
 * @param {promise}  getTaskDomainTypes   fn for getting task domain types.
 * @param {promise}  getPassLineTypes     fn for getting pass line types
 * @param {promise}  getSnapshot          fn for getting snapshots to a camera
 * @param {promise}  schedules            getting list of available schedules
 *
 * @param {function} [logger] attached logger from parent
 * @param {*}        reloadToken any token to trigger a reload.
 *
 * @param @deprecated {boolean} [useNativePopups=false] defaults to false; shows native popups
 * @return {React.Component}
 * @constructor
 */
export default class MultiTaskRunner extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    const {
      apiUrl,
      taskAlias,
      onStatusChange,
      getCameraDomainTypes,
      getTaskDomainTypes,
      logger,
      getPassLineTypes,
      cameraList,
      getSnapshot,
      schedules,
      onDeleteTask,
      getMissingSnapshots,
      getMissingThumbnails,
      tasks,
      Suite,
      Constant
    } = props;


    /**
     * Static props that should not be updated even when they are changed from the parent.
     */
    const store = createStore();
    store.Suite = Suite;
    this.passProps = {
      mtrApiClient: new MtrApiClient(apiUrl, store),
      store: store,
      taskAlias: taskAlias || "Task",
      onStatusChange,
      getCameraDomainTypes,
      getTaskDomainTypes,
      getPassLineTypes: async () => getPassLineTypes || (() => []),
      getCameraList: cameraList,
      getMissingThumbnails,
      getSchedules: schedules,
      getSnapshot,
      getMissingSnapshots,
      tasks,
      Constant,
      mtrLogger: logger || (() => false),
      /**@deprecated useNativePopups: useNativePopups || false*/
    };

    this.onDeleteTask = onDeleteTask;

    if (logger) {
      logger('[MTR UI Created]:' + JSON.stringify(props));
    }
  }


  componentDidMount() {
    this.passProps.getMissingSnapshots();
    window.addEventListener("hashchange", MultiTaskRunner.onHashChange);

    this.deleteDispatcher = Dispatcher.register(payload => {
      if (payload.actionType === Constant.FLUX.SOLUTIONS_CHANGED && payload.deletedTaskId && this.onDeleteTask) {
        this.onDeleteTask([payload.deletedTaskId]);
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", MultiTaskRunner.onHashChange);

    Dispatcher.unregister(this.deleteDispatcher);
  }

  static onHashChange = (evt) => {
    Dispatcher.dispatch({
      contentType: Constant.FLUX.URL_CHANGED,
      url: JSUtil.parseHashLocation(evt.newURL)
    });
    SCUtils.Close();
  };

  render() {
    return <HashRouter basename={"solution"}>
      <ContentRoot  {...this.passProps} reloadToken={this.props.reloadToken}/>
    </HashRouter>
  }
}
