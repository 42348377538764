import {IconButton, InputAdornment, Button} from "@mui/material";
import {ContentCopy, Done} from "@mui/icons-material";
import {useState} from "react";

function CopyWrapper({copiedFeedback}) {
    return copiedFeedback ? <Done/> : <ContentCopy/>
}

export default function CopyBtn({value, adornment = true}) {
    const [copiedFeedback, setCopiedFeedback] = useState(false);
    const fallback = () => {
        const textArea = document.createElement("textarea");
        textArea.value = value;
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        textArea.setSelectionRange(0, 99999);
        try {
            document.execCommand('copy');
        } catch (err) {
            console.log("Unable top copy:", err)
        }
    }

    const copy = evt => {
        if (evt?.cancelable) {
            evt.stopPropagation()
        }

        setCopiedFeedback(true);

        navigator.clipboard.writeText(value).then(() => {
        }, fallback);

        setTimeout(() => setCopiedFeedback(false), 1200)

    }

    if (!adornment) return <Button variant={"outlined"} size={"small"}
                                   title={"copy to clipboard"}
                                   className={`copyButton action ${copiedFeedback ? "active-cpy" : ""}`} onClick={copy}>
        <CopyWrapper copiedFeedback={copiedFeedback} />
    </Button>

    return (
        <InputAdornment position="end" title={"copy to clipboard"}>
            <IconButton edge="end" color="primary"
                        onClick={copy} className={`copyButton ${copiedFeedback ? "active-cpy" : ""}`}>
                <CopyWrapper copiedFeedback={copiedFeedback} />
            </IconButton>
        </InputAdornment>
    )
}

