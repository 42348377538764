import { Navigate, useLocation, useNavigate } from "react-router-dom"

export default function AppIndex() {
  const location = useLocation()
  const _navigate = useNavigate()
  if (location.pathname === "/" || location.pathname === "") {
    return <Navigate to={"/acc"} />
  }
  return null
}
