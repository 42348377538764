import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Typography
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import ClearIcon from "@mui/icons-material/Clear"
import {
    getAccounts,
    getAccountsInUser,
    getUsers,
    getUsersInAccount,
    putAccountsInUser,
    putUsersInAccount
} from "../../adminApiClient"
import { confirmDialog } from "../../component/temp/ConfirmDialog"
import Dispatcher from "../../dispatcher"

export default function LinkAccountUser() {
    const [selected, setSelected] = useState([])
    const [filter, setFilter] = useState("")
    const [filterables, setFilterables] = useState([])
    const navigate = useNavigate()
    let { userId, accountId } = useParams()

    /** boolean, true if we are linking a user **/
    let linkUser
    /** the current user or account id **/
    let selfId
    /** get every available calls **/
    let getEveryAssignment
    /** current user or account connections , api call**/
    let getCurrentAssigment
    /** set current user or account connections, api call **/
    let putCurrentAssigment

    if (userId) { // setting a single user's accounts
        [linkUser,
            selfId,
            getEveryAssignment,
            getCurrentAssigment,
            putCurrentAssigment] = [
            true,
            Number(userId),
            getAccounts,
            getAccountsInUser,
            putAccountsInUser]
    } else { // setting a single account's users
        [linkUser,
            selfId,
            getEveryAssignment,
            getCurrentAssigment,
            putCurrentAssigment] = [
            false,
            Number(accountId),
            getUsers,
            getUsersInAccount,
            putUsersInAccount]
    }

    const toggleItem = id => evt => {
        if (evt.target.checked) {
            setSelected(selected => selected.concat(id))
        } else {
            setSelected(selected => selected.filter(el => el !== id))
        }
    }

    useEffect(() => {
        selectInitial()
    }, [])
    const selectInitial = () => {
        getCurrentAssigment(selfId)
            .then(res => setSelected(res.map(res => res.id)))

        getEveryAssignment()
            .then(res => setFilterables(res))
    }


    const _toggleAll = () => {
        setSelected(selected => filterables.map(el => el.id).filter(el => !selected.includes(el)))
    }
    const _selectAll = () => {
        setSelected(filterables.map(el => el.id))
    }
    const _selectNone = () => {
        setSelected([])
    }

    const save = () => {
        putCurrentAssigment(selfId, selected)
            .then(() => {
                Dispatcher.dispatch({ contentType: "GENERIC_RELOAD_PARENT" })
                navigate("..")
            })
            .catch(() => {
                confirmDialog({ title: "Error", message: "Could not save edited content.", noCancel: true })
            })
    }

    return <>
        <Paper className={"rightDrawer"}>
            <div className={"rightDrawer-sticky"}>
                <Typography variant={"h5"}>{
                    linkUser ? <>
                        Assigning Users to selected account.
                    </> : <>
                        Assigning Accounts to selected user.
                    </>
                }</Typography>
                <br />
                <div className={"sizeloadedBtns"}>
                    <Button variant={"contained"} onClick={save}>
                        Save
                    </Button>
                    {/*<Link to={"/admin/accounts"} className={"noa"} onClick={selectInitial}> /!*todo:fix bad redit*!/*/}
                    {/*  <Button variant={"outlined"} color="primary">*/}
                    {/*    Cancel*/}
                    {/*  </Button>*/}
                    {/*</Link>*/}
                    <Button variant={"outlined"} color="primary" onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </div>

            </div>

            <p className={"explainer"}>
                Added users will be able to see channels associated to the account,
                create sites and edit channels. <br />
            </p>

            {/*<div className={"sizeloadedBtns thirds"}>*/}
            {/*  <Button onClick={selectAll}*/}
            {/*          variant={"outlined"} color={"success"} size={"small"}>*/}
            {/*    All*/}
            {/*  </Button>*/}
            {/*  <Button onClick={selectNone}*/}
            {/*          variant={"outlined"} color={"success"} size={"small"}>*/}
            {/*    None*/}
            {/*  </Button>*/}
            {/*  <Button onClick={toggleAll}*/}
            {/*          variant={"outlined"} color={"success"} size={"small"}>*/}
            {/*    Toggle*/}
            {/*  </Button>*/}
            {/*</div>*/}
            <br />


            <div>
                {linkUser ? "Accounts" : "Users"}

                <br /><br />
                <FormControl variant="outlined" label={"Filter..."} style={{ width: "100%" }}>
                    <InputLabel label={"Filter..."} size={"small"}>Filter...</InputLabel>
                    <OutlinedInput label={"Filter..."} onChange={evt => setFilter(evt.target.value)}
                                   className={"textInput"} value={filter} size={"small"}
                                   endAdornment={filter &&
                                       <InputAdornment position="end">
                                           <IconButton
                                               onClick={() => setFilter("")}
                                               edge="end">
                                               <ClearIcon />
                                           </IconButton>
                                       </InputAdornment>}
                    />

                </FormControl>
            </div>
            <br />
            <div className={"seperatedList"}>
                {filterables
                    .filter(el => filter === "" || el.name.toLowerCase().includes(filter.toLowerCase()))
                    .map(el =>
                        <FormControlLabel
                            value="end"
                            key={el.id}
                            control={<Checkbox
                                checked={selected.includes(el.id)}
                                onClick={toggleItem(el.id)} />}
                            label={el.name || el.email}
                        />
                    )}
            </div>
            <br />

        </Paper>
    </>
}
/***
 *                            endAdornment={
 *                                <InputAdornment position="end">
 *                                    <IconButton
 *                                        onClick={() => setFilter("")}
 *                                        edge="end"
 *                                    >
 *                                        <ClearIcon/>
 *                                    </IconButton>
 *                                </InputAdornment>}
 */
