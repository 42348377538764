import React, {useContext, useEffect, useState} from 'react'
import DualListBox from 'react-dual-listbox'
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome";
import MtrContext from "../../MtrContext";
import {Button, Dialog, DialogActions, DialogContent, InputLabel, Alert} from '@mui/material';
import Dispatcher from "../../dispatcher";
import Constant from "../../constant";
import formDataStore from "../FormDataStore";
import FetchingIndicator from "../../ui/temporary/FetchingIndicator";
import {RawEnum} from 'jsutil';

require('react-dual-listbox/lib/react-dual-listbox.css');

let assignments = {};
let camera = null;
let currentTaskId = null;

const STATUS = new RawEnum("WAITING_FOR_CAMERA", "FETCHING_DATA", "READY", "EMPTY");
export default function CustomDualListBox(props) {

  const context = useContext(MtrContext);

  const {value, path, propertyElement} = props;

  const [selected, setSelected] = useState(formDataStore.getByPath(props.path) || []);
  const [parsedSolutions, setParsedSolutions] = useState([]);
  const [status, setStatus] = useState(STATUS.FETCHING_DATA);

  const currentCount = selected?.length;
  const didEverFetch = true;
  const controlRef = React.createRef();

  const getCamera = () => formDataStore?.data?.input || {};
  const parseSolutions = assignments => {

    const input = getCamera();


    if (input?.length > 1) {
      console.error("[MTR] video annotation is not prepared for multiple cameras");
      return [];
    }
    if (input?.length === 0) {
      setStatus(STATUS.WAITING_FOR_CAMERA);
      return [];
    }

    if (JSON.stringify(input) === "{}") {
      setStatus(STATUS.FETCHING_DATA);
      return [];
    }

    const currentAssigment = assignments[input[0].camera_id];

    if (input[0].camera_id !== camera && camera != null) {
      // todo: fix an issue in mtr ui api where props.taskId is not actually the last selected ID.
      const realTaskId = window.location.hash.split('taskId=')[1];

      if (realTaskId !== currentTaskId) {
        setSelected(formDataStore.getByPath(props.path));
        onChange(formDataStore.getByPath(props.path));
        currentTaskId = realTaskId;
      } else {
        setSelected([]);
        onChange([]);
      }

    }

    camera = input[0].camera_id;

    if (!currentAssigment?.length) { // 0 or undefined
      setStatus(STATUS.EMPTY);
      return [];
    }

    setStatus(STATUS.READY);

    return currentAssigment?.map(el => {
      return {
        value: el.id,
        label: el.display_name + " (" + el.type.toLowerCase().replace(/_/g, " ") + ") "
      }
    }) || [];
  };

  useEffect(() => {
    const dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED || payload.contentType === Constant.FLUX.FORM_DATA_CHANGED) {
        const currentValue = formDataStore.getByPath(path) || propertyElement.default;
        formDataStore.saveWhenDefault(path, propertyElement);

        setParsedSolutions(parseSolutions(assignments));
        setSelected(formDataStore.getByPath(props.path))
      }
    })

    context.mtrApiClient.getCameraAssignments().then(response => {
      assignments = response.data.assignments;
      setParsedSolutions(parseSolutions(assignments));
    })

    return () => {
      Dispatcher.unregister(dispatcher);
    };
  }, [])

  const onChange = function (newValue) {
    formDataStore.saveToPath(path, newValue);
  };

  const onChangeWrapper = (currentSelected, setSelected, onChangeDelegate) => {

    return (nextSelected) => {
      // deprecated, only to be used when max number of solutions is added

      // let newlyAdded = nextSelected.filter(d => !currentSelected.includes(d));
      // const alreadyAdded = nextSelected.filter(d => currentSelected.includes(d));
      //
      // newlyAdded = newlyAdded.slice(0, MAX_SELECTED - currentSelected.length);
      // nextSelected = newlyAdded.concat(alreadyAdded);

      setSelected(nextSelected);
      onChangeDelegate(nextSelected);
    }
  }

  if (status === STATUS.WAITING_FOR_CAMERA) {
    return (<React.Fragment>
      <br/>
      To add annotated RTSP, please select the camera first.
    </React.Fragment>)
  }

  return (
    <MtrContext.Consumer>
      {
        ({store}) => {
          const parsedCameraDomainTypes = parsedSolutions;
          if (didEverFetch) {
            return (<React.Fragment>
              <InputLabel>{props.propertyElement.description}</InputLabel>
              <div className={`dualListBox ${status === STATUS.EMPTY ? "really-empty" : ""}`}>
                <div className='dualListBox-header'>
                  &nbsp;
                  <span>
                  {currentCount} selected / {parsedCameraDomainTypes.length} all
                    {currentCount > 5 && <i> | It is not recommended to display more than 5 solutions.</i>}
              </span>
                </div>

                <div
                  className={currentCount === 0 ? 'dualListBox-none' : ''}>
                  <DualListBox
                    ref={controlRef}
                    options={parsedCameraDomainTypes}
                    showOrderButtons
                    canFilter={parsedCameraDomainTypes?.length > 8}
                    selected={selected}
                    preserveSelectOrder
                    onChange={onChangeWrapper(selected, setSelected, onChange, false)}
                    icons={{
                      moveLeft: <Icon icon="angle-left"/>,
                      moveAllLeft: <Icon icon="angle-double-left"/>,
                      moveAllRight: <Icon icon="angle-double-right"/>,
                      moveRight: <Icon icon="angle-right"/>,
                      moveDown: <Icon icon="angle-down"/>,
                      moveUp: <Icon icon="angle-up"/>,
                      moveTop: <Icon icon="angle-double-up"/>,
                      moveBottom: <Icon icon="angle-double-down"/>,
                    }}
                  />
                </div>

              </div>
            </React.Fragment>)
          } else {
            return <React.Fragment>
              <FetchingIndicator isFetching text={"Getting Solution types for this camera..."}/>

            </React.Fragment>
          }
        }
      }
    </MtrContext.Consumer>
  )
}

