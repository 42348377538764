import React from "react";
import {Button} from "@mui/material";
import DuotoneIcon from "./DuotoneIcon";

/**
 TODO: THIS IS JUST A COPY OF U-ALARM-s version of this component. DO NOT EDIT THIS
 */

export function CtaButton({className,primary,icon,bordered,...rest}) {
  const cName = (icon?"btnIcon ":"")+(primary?"main ":"")+(bordered?"bordered ":"")+" cta "+className
  return <Button className={cName}
                 color={"primary"}
                 startIcon={icon && <DuotoneIcon icon={icon}/>}
                 variant={"contained"} {...rest}>
    {rest.children || "Save"}
  </Button>

}
