import React, { useEffect, useState } from "react"
import { Alert, Button, Checkbox, FormControlLabel } from "@mui/material"

import Cover from "./Cover"
import {
  deleteSmtpConfiguration,
  getSmtpConfiguration,
  putSmtpConfiguration,
  testGlobalSmtpConfiguration
} from "../adminApiClient"
import SmtpForm from "./SmtpForm"
import unwrapErrorFromApi from "./UnwrapErrorApi"
import { SmtpTest } from "./SmtpTest"

export default function AdminSmtpConfiguration() {
  const [smtp, setSmtp] = useState({ encryption: "NONE" })
  const [editMode, setEditMode] = useState(false)
  const [saved, setSaved] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [enabled, setEnabled] = useState(false)

  const onChange = (stmpState) => {
    setSaved(false)
    setDirty(true)
    setSmtp(stmpState)

  }

  const changeEnabled = (e) => {
    setSaved(false)
    setEnabled(e.target.checked)
    setDirty(true)
  }

  const getData = async () => {
    try {
      const data = await getSmtpConfiguration()
      setEditMode(!!data)
      setSmtp(current => ({ ...current, ...data }))
      setDirty(false)
      return data
    } catch (err) {
      unwrapErrorFromApi(err)
    }
  }

  useEffect(() => {
    getData().then(value => setEnabled(!!value))
  }, [])

  const deleteData = async () => {
    await deleteSmtpConfiguration()
    setDirty(false)
    await getData()
  }

  const saveData = async (evt) => {
    evt.preventDefault()
    try {
      if (enabled) {
        await putSmtpConfiguration(smtp).then(getData)
      } else {
        await deleteData()
      }
      setSaved(true)
      setDirty(false)
    } catch (err) {
      unwrapErrorFromApi(err)
    }
  }

  return (
      <div className={"pageContainer mw800"}>

          <Cover title={"SMTP Server Configuration"}>
              {saved && <Alert variant={"filled"} severity="success">Settings saved.</Alert>}
              <form onSubmit={saveData}>
                  <FormControlLabel
            control={<Checkbox checked={enabled}
                               color="primary"
                               onChange={changeEnabled}
                               name="enabled" />}
            label="Enabled"
          />
                  <br /> <br />
                  <SmtpForm smtpState={smtp} setSmtpState={onChange} enabled={enabled} edit={editMode} />
                  <br />
                  <div className={"withVPadding"}>
                      <Button variant={"contained"} className={"formSubmit action"} type={"submit"}>
                          Save SMTP
                      </Button>
                  </div>
              </form>

          </Cover>

          <SmtpTest enabled={enabled} dirty={dirty} apiCall={testGlobalSmtpConfiguration} />
          <br />
          <br />
      </div>
  )
}
