import React, {useEffect, useState} from "react";
import {ListItem, ListItemText, Switch, CircularProgress, TextField, Tooltip, InputAdornment} from "@mui/material";
import AlarmStateIcon from "./AlarmStateIcon";
import Dispatcher from "../dispatcher";
import Constant from "../constant";
import AlarmTypeIcon from "./AlarmTypeIcon";
import Util from "../util";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark, faQuestionCircle} from "@fortawesome/pro-regular-svg-icons";

function isAnyActive(store) {
  return store.tasks?.filter(elem => !Constant.HIDE_DISARM_ALARM.includes(elem.type)).some(elem => elem.active);
}

export default function AlarmList({selectedId, store, onSelect, taskAlias, mtrApiClient}) {
  const [tasks, setTasks] = useState(store.tasks);
  const [switchTask, setSwitchTask] = useState(null);
  const [anyActive, setAnyActive] = useState(isAnyActive(store))
  const [filterText, setFilterText] = useState("")

  useEffect(() => {
    const dispatcher = Dispatcher.register(payload => {
      if (payload.actionType === Constant.FLUX.SOLUTIONS_CHANGED) {
        setTasks(store.tasks);
        setAnyActive(store);
      }
    })
    return () => Dispatcher.unregister(dispatcher);
  })

  const handleActivateAnAlarm = async (evt, el) => {
    const toBeChecked = evt.target.checked;
    const details = await mtrApiClient.getTaskDetails(el.id);
    setSwitchTask(el.id);
    mtrApiClient
      .putTask(el.id, details.properties, el.display_name, toBeChecked, el.type, details.schedule_id)
      .finally(_ => {
        setSwitchTask(null)
      })
    ;
  }

  const handleActivateAllAlarms = async evt => {
    const message = "All solutions will be " + (evt.target.checked ? "armed" : "disarmed.") + "\nPress OK to" +
      " proceed."
    if (!window.confirm(message)) return;
    mtrApiClient.activateAllTasks(evt.target.checked);
  }

  const ListElem = el => {
    return <ListItem key={el.id} className={"normal " + el.status}
                     selected={el.id === selectedId}
                     onClick={() => onSelect(el.id)}
                     dataTestId={"SolutionListElement"}>
      <AlarmTypeIcon type={el.type}/>
      <ListItemText primary={el.display_name} className={"marginLeft-15"}/>
      <AlarmStateIcon className={"leftMargin-10"} state={el.status}/>
      {!Constant.HIDE_DISARM_ALARM.includes(el.type) && (
        switchTask === el.id ?
          <div className={"loadingSwitch"}>
            <CircularProgress className="smallCircProgress"/>
          </div>
          : <Switch onChange={evt => handleActivateAnAlarm(evt, el)}
                    disabled={switchTask !== null}
                    checked={el.active}
                    name={`toggleActivate${el.id}`}
                    inputProps={{onClick: event => event.stopPropagation()}}
                    color="primary"/>
      )}

    </ListItem>
  }

  const GlobalToggle = () => {
    return <ListItem key={"TOGGLE"} className={"switch"}>
      <ListItemText primary={"Arm/Disarm all"}/>
      <Switch onChange={evt => handleActivateAllAlarms(evt)}
              checked={isAnyActive(store)}
              name={"toggleActivateAll"}
              color="primary"/>
    </ListItem>
  }

  const matchComplex = (textJSON, search) => {
    const text = JSON.stringify(textJSON);
    return text
      .toLowerCase()
      .replace(/['",{}_]/g, "")
      .includes(search)
  }
  const matchAll = (textJSON) => {
    if (!filterText.includes(":")) return false;
    return filterText
      .toLowerCase()
      .split(" ")
      .map(text => matchComplex(textJSON, text))
      .every(ID => ID)
  }


  return tasks?.length ? (
      <React.Fragment>
        <GlobalToggle/>

        <br/>
        <br/>
        <TextField
          size={"small"}
          className={"leftMargin-10"}
          value={filterText}
          variant={"outlined"}
          onChange={e => setFilterText(e.target.value)}
          placeholder={`Filter in ${tasks?.length} solutions...`}
          InputProps={{
            startAdornment:
              <Tooltip className="cozyTooltip" title={<React.Fragment>
                Advanced search terms:<br/><br/>
                <u>type:intrusion</u> (or other solution type)<br/><br/>
                <u>status:error</u> (or inactive, running, freeze) for current status<br/><br/>
                <u>active:true</u> (or false for whether a solution is armed)<br/>
              </React.Fragment>}>
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faQuestionCircle}/>
                </InputAdornment>
              </Tooltip>,
            endAdornment: filterText.length ? <InputAdornment
              className={"pointer"}
              position="end" onClick={() => setFilterText("")}>
              <FontAwesomeIcon icon={faXmark} />
            </InputAdornment> : null
          }}

        />


        {
          tasks
            .filter(task => task.display_name.toLowerCase().includes(filterText) || matchAll(task))
            .map(ListElem)
        }


      </React.Fragment>) :
    <div className="center-text-bold empty-list-item" dataTestId={"NoSolutions"}>No {taskAlias}s found</div>;

}

