import React from 'react';

import MtrContext from "../../MtrContext";
/**
 TODO: THIS IS JUST A COPY OF U-ALARM-s version of this component. DO NOT EDIT THIS
 */

export function LearnMore({href, children, raw, inline = false, docUrl}) {
  if (href && docUrl) console.error("[LearnMore Componenet] please use either href of docUrl");


  return <MtrContext.Consumer>
    {
      ({store,Constant}) => {

        const finalUrl = href ? href : `${store.Suite.current.docUrlPrefix}${store.Suite.current.version}/${store.Suite.current.docUrlMiddle}/${docUrl}`;
        return <a href={finalUrl}
           className={"learnMore " + (raw ? "" : "styled") + (inline ? " inline " : " block ")}
           target="_blank" rel="noreferrer">
          {children || "Learn more..."}
        </a>
      }
    }
  </MtrContext.Consumer>

}
