import { NEW_API_URL } from "./constant"
import axios from "axios"

axios.defaults.withCredentials = true

const axiosInst = axios.create({
    baseURL: NEW_API_URL,
    withCredentials: true

})
const CONFIG = {
    baseURL: NEW_API_URL,
    withCredentials: true
}
export { axiosInst }

export async function getUserInfo() {
    return await axiosInst.get(`users_current`)
}

export async function getUsersAccount() {
    const res = await axiosInst.get(`users_current/accounts`)
    return res.data
}

export async function changeCurrentUserPw(data) {
    const res = await axiosInst.put(`users_current/change_password`, data)
    return res.data
}

export async function getAccounts() {
    const res = await axiosInst.get(`accounts`)
    return res.data
}

export async function getSitesInAccount(accountId) {
    const res = await axiosInst.get(`accounts/${accountId}/sites`)
    return res.data
}

export async function getSingleSite(siteId) {
    const res = await axiosInst.get(`sites/${siteId}`)
    return res.data
}


export async function modifySite(siteId, newData) {
    const res = await axiosInst.put(`sites/${siteId}`, sanitiseNulls(newData))
    return res.data
}

export async function createSite(accountId, newData) {
    const res = await axiosInst.post(`accounts/${accountId}/sites`, sanitiseNulls(newData))
    return res.data
}

export async function testSiteWebhook(site_id, webhookConfig) {
    const res = await axiosInst.post(`sites/${site_id}/test_webhook`, sanitiseNulls(webhookConfig))
    return res.data
}

export async function testSiteSmtp(site_id, recipients) {
    const res = await axiosInst.post(`sites/${site_id}/test_smtp`, { recipients })
    return res.data
}


export async function deleteSite(siteId) {
    const res = await axiosInst.delete(`sites/${siteId}`)
    return res.data
}

export async function patchBatchSensitivity(siteId, class_info, channel_ids) {
    const res = await axiosInst.patch(`sites/${siteId}/channels`,
        {
            class_info,
            channel_ids
        })
    return res.data
}


/** CHANNEL **/

export async function getChannelsInSite(siteId) {
    const res = await axiosInst.get(`sites/${siteId}/channels`)
    return res.data
}

export async function getChannelsInSiteWithStats(siteId) {
    const res = await axiosInst.get(`sites/${siteId}/channels?collect_stats=true`)
    return res.data
}

export async function createChannelToSite(siteId, data) {
    const res = await axiosInst.post(`sites/${siteId}/channels`, data)
    return res.data
}

export async function getSingleChannel(channelId) {
    const res = await axiosInst.get(`channels/${channelId}`)
    return res.data
}

export async function modifySingleChannel(channelId, channelData) {
    const res = await axiosInst.put(`channels/${channelId}`, sanitiseNulls(channelData))
    return res.data
}

export async function deleteChannel(id) {
    const res = await axiosInst.delete(`channels/${id}`)
    return res.data
}


export async function deleteEveryChannelFromSite(siteId) {
    const res = await axiosInst.delete(`sites/${siteId}/channels`, [])
    return res.data
}

export async function deleteChannelsFromSite(siteId, deleteItems = []) {
    console.log(deleteItems)
    const res = await axiosInst.delete(`sites/${siteId}/channels`, { data: deleteItems })
    return res.data
}

export async function getMailServer() {
    const res = await axiosInst.get(`mail_server`)
    return res.data
}

/**
 * logins
 * @param loginData
 * @returns {Promise<*>}
 */

export async function loginUser(loginData) {
    return await axiosInst.post(`login`, loginData)
}

export async function logoutUser() {
    await axiosInst.post("logout", {})
    window.location.href = "/"
}

/**
 * test image thingies
 */

export async function getChannelImage(channelId) {
    const res = await axiosInst.get(`channels/${channelId}/setup_image`,
        {
            responseType: "arraybuffer"
        }
    )
    return Buffer.from(res.data).toString("base64")
}

export async function deleteChannelImage(channelId) {
    const res = await axiosInst.delete(`channels/${channelId}/setup_image`)
    return res.data
}

export async function postChannelImage(channelId, file) {
    const res = await axiosInst.post(`channels/${channelId}/setup_image`, file, {
        responseType: "blob", headers: {
            "Content-Type": "image/jpeg"
        }
    })
    return res.data
}

export async function postTestImage(channelId, file, sendEvent = false) {
    try {
        const res = await axios.post(`channels/${channelId}/test_image?send_event=${sendEvent}`, file, {
            ...CONFIG, responseType: "blob", headers: {
                "Content-Type": "image/jpeg"
            }
        })
        return res.data
    } catch (err) {
        err.response.data = await err.response.data.text()
        throw err
    }
}

export async function getId(category) {
    const res = await axiosInst.get(`count/${category}`)
    return res.data.count
}

export async function getSMTPExists() {
    return await axiosInst.get(`smtp_configuration/exists`)
}

const sanitiseNulls = (obj) => {
    Object.keys(obj)
        .forEach(key => {
            if (obj[key] === null) {
                delete obj[key]
            }
        })
    return obj
}
export { sanitiseNulls }
