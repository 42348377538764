import { Outlet, useLocation } from "react-router-dom"
import { getUserInfo as NEW_getUserInfo } from "../newApiClient"
import { unwrapErrorMessage } from "../util"
import { Box, LinearProgress } from "@mui/material"
import { useEffect, useState } from "react"
import store from "../store"
import Login from "./Login"
import Dispatcher from "../dispatcher"
import { SCUI_LOCATION } from "../constant"

export default function AppWrapper() {
  const [userLoggedIn, setUserLoggedIn] = useState(undefined)
  const [_error, setError] = useState()

  const location = useLocation()

  const getUserInfo = () => {
    NEW_getUserInfo()
      .then(res => {
        console.log("new user info is:", res.data, res.status)
        if (res.status === 200) {
          setUserLoggedIn(true)
          store.user = res.data
        } else if (res.status === 204) {
          setUserLoggedIn(false)
        } else {
          console.error("user info status is unknown", res)
        }
      })
      .catch(err => {
        setError(unwrapErrorMessage(err))
        setUserLoggedIn(false)
      })
  }

  useEffect(() => {
    getUserInfo()
    const d = Dispatcher.register(payload => {
      if (payload.contentType === "LOGGED_IN") {
        getUserInfo()
      }
    })
    return () => {
      Dispatcher.unregister(d)
    }
  }, [])


  useEffect(() => {
    if (store.token?.user) {

    }
  }, [location])

  if (userLoggedIn === false) {
    return <Login />
  }

  if (userLoggedIn === undefined) {
    return <Box sx={{ width: "100%" }}><LinearProgress /></Box>
  }

  return (<>
    <Outlet />
    <iframe id={"scuiIframe"}
            src={SCUI_LOCATION}
            name={"scuiIframeName"}
            title={"Stream Configurator"}
            loading={"lazy"} />
  </>)
}
