import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TextField
} from "@mui/material"
import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { unwrapErrorMessage } from "../util"
import { DataGrid } from "@mui/x-data-grid"
import { InfoOutlined } from "@mui/icons-material"
import { useRouteLoaderData } from "react-router-dom"
import { getChannelsInSiteWithStats } from "../newApiClient"

export default function StatTable({ siteId, close, site }) {
  const [nameFilter, setNameFilter] = useState("")
  const [channels, setChannels] = useState()
  const [waiting, setWaiting] = useState(false)
  const [error, setError] = useState()
  const [filteredChannels, setFilteredChannels] = useState([])
  const [sum, setSum] = useState()
  const timeoutRef = useRef()
  const SEARCH_TIMEOUT_MS = 300

  //eslint-disable-next-line
  const channelData = useRouteLoaderData("account")

  const getStats = async () => {
    setWaiting(true)
    try {
      const s = await getChannelsInSiteWithStats(siteId)
      setChannels(s)
      setSum(calculateSummary(s))
      setFilteredChannels(s)
    } catch (err) {
      setError(unwrapErrorMessage(err))
    } finally {
      setWaiting(false)
    }
  }

  useEffect(() => {
    getStats().then(_ => {
    })
  }, [])


  const filterChannels = text => {
    if (text === "") {
      setFilteredChannels(channels)
    } else {
      setFilteredChannels(channels.filter(elem => elem.name.toLowerCase().includes(text.toLowerCase())))
    }
  }

  const onChangeSearchText = evt => {
    const text = evt.target.value
    setNameFilter(text)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
    timeoutRef.current = setTimeout(() => {
      filterChannels(text)
    }, SEARCH_TIMEOUT_MS)
  }

  return <Dialog open={true} onClose={close} maxWidth={"lg"}>
    <DialogTitle>Usage of {site.name}</DialogTitle>
    <div className={"paddingH24"}>
      <TextField label="Filter" variant="outlined"
                 fullWidth value={nameFilter} onChange={onChangeSearchText} />
    </div>
    <DialogContent>
      <InnerContent waiting={waiting} error={error} filteredChannels={filteredChannels} sum={sum} />
    </DialogContent>
    <DialogActions>
      <Button variant={"outlined"} className={"action"} onClick={close}>Close</Button>
    </DialogActions>
  </Dialog>
}

const renderHeader = header => {
  const title = "positive / total"
  return <>
        <span title={title}>
            {header.colDef.headerName}
        </span>
    &nbsp;
    <span className={"infoIcon"} title={title}><InfoOutlined /></span>
  </>
}

// const COLUMNS = [
//   {field: 'name', headerName: 'Channel', flex: 1},
//   {
//     field: '7day_positive', headerName: 'Last 7 days', sortable: false, renderCell: renderLast7, width: 120,
//     renderHeader, disableColumnMenu: true, align: "center"
//   },
//   {
//     field: '30day_positive', headerName: 'Last 30 days', sortable: false, renderCell: renderLast30, width: 130,
//     renderHeader, disableColumnMenu: true, align: "center"
//   },
//   {
//     field: 'last_incoming', headerName: 'Last incoming', width: 150, renderCell: renderLast,
//     sortable: false, align: "center"
//   },
// ]

const COLUMNS = [
  { field: "name", headerName: "Channel", flex: 1 },
  {
    field: "1week", headerName: "Last 7 days", sortable: false, renderCell: renderLast7, width: 120,
    renderHeader, disableColumnMenu: true, align: "center"
  },
  {
    field: "1month", headerName: "Last 30 days", sortable: false, renderCell: renderLast30, width: 130,
    renderHeader, disableColumnMenu: true, align: "center"
  }
  // {
  //   field: 'last_incoming', headerName: 'Last incoming', width: 150, renderCell: renderLast,
  //   sortable: false, align: "center"
  // },
]

function InnerContent({ waiting, error, filteredChannels, sum }) {
  if (waiting) {
    return <div className={"progressBar small"}>
      <LinearProgress />
    </div>
  } else if (error) {
    return <Box sx={{ width: 700 }}><Alert severity="error">{error}</Alert></Box>
  } else {
    return <><Box sx={{ height: 350, width: 700 }}>
      <DataGrid
        rows={filteredChannels}
        columns={COLUMNS}
        pagination
        rowsPerPageOptions={[5, 10, 50, 100]}
        disableSelectionOnClick={true}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }]
          }
        }}
      />
    </Box>
      <Table>
        <TableBody>
          <TableCell><b>Summary</b></TableCell>
          <TableCell><RenderLastN stat={sum} positiveKey={"positive_7day"} allKey={"all_7day"} /></TableCell>
          <TableCell><RenderLastN stat={sum} positiveKey={"positive_30day"}
                                  allKey={"all_30day"} /></TableCell>
          {/*<TableCell><RenderLastTs epoch={sum.last}/></TableCell>*/}
        </TableBody>
      </Table>
    </>
  }
}

function renderLast7(cell) {
  return <RenderLastN stat={cell.row.stat} positiveKey={"1week"} allKey={"7day_all"} />
}

function renderLast30(cell) {
  return <RenderLastN stat={cell.row.stat} positiveKey={"1month"} allKey={"30day_all"} />
}

const itemTypes = [
  "COOLDOWN",
  "FIRST_OVERLOAD_COOLDOWN",
  "OVERLOAD_COOLDOWN",
  "NEGATIVE_EVENT",
  "EVENT"
]
const positiveEventType = "EVENT"


function RenderLastN({ stat, positiveKey }) {
  const currObj = stat && stat[positiveKey]
  if (!currObj) {
    return "No stats."
  }
  const currKeys = Object.keys(currObj)

  if (currKeys.length === 0) {
    return "No data."
  }

  const all = itemTypes
    .map(type => currObj[type])
    .reduce((partialSum, a) => partialSum + a, 0)

  const header = currKeys
    .map(
      (key, _) => `${key.toLowerCase().replace(/_/g, " ")} : ${currObj[key]}`
    ).join("\n")

  console.log(currKeys, all, currObj)
  return <span title={header}>
        {currObj[positiveEventType]} / total {all}
    </span>
}

function _renderLast(cell) {
  const pos = cell.row.stat
  if (!pos || !pos.last_incoming_timestamp) return "-"
  const epoch = pos.last_incoming_timestamp
  return <RenderLastTs epoch={epoch} />
}

function RenderLastTs({ epoch }) {
  if (!epoch) {
    return "-"
  }
  const d = new Date(epoch * 1000)
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours().toString().padStart(2, "0")}:${d.getMinutes()}`
}

function calculateSummary(s) {
  return s.reduce((acc, elem) => {
    return {
      all_7day: acc.all_7day + elem.stat["7day_all"],
      all_30day: acc.all_30day + elem.stat["30day_all"],
      positive_7day: acc.positive_7day + elem.stat["7day_positive"],
      positive_30day: acc.positive_30day + elem.stat["30day_positive"],
      last: elem.stat.last_incoming_timestamp && elem.stat.last_incoming_timestamp > acc.last ?
        elem.stat.last_incoming_timestamp : acc.last
    }
  }, { all_7day: 0, all_30day: 0, positive_7day: 0, positive_30day: 0, last: null })
}
