import {
    Button,
    Checkbox, Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Paper, Radio,
    RadioGroup,
    Typography
} from "@mui/material"
import TextInput from "../../component/TextInput"
import { useState } from "react"
import Dispatcher from "../../dispatcher"
import { postUser } from "../../adminApiClient"
import { Link, useNavigate } from "react-router-dom"
import unwrapErrorFromApi from "../../component/UnwrapErrorApi"

export default function CreateUser() {
    const [type, setType] = useState("LOCAL")
    const [currentUser, setCurrentUser] = useState({})
    const [isAdmin, setIsAdmin] = useState(false)
    const navigate = useNavigate()
    const radioChange = evt => {
        setType(evt.target.value)
        setValueBase("password")("")
    }

    const setValue = name => evt => {
        setCurrentUser(state => {
            return { ...state, [name]: evt.target.value }
        })
    }
    const setValueBase = name => value => {
        setCurrentUser(state => {
            return { ...state, [name]: value }
        })
    }

    const returnAndUpdate = () => {
        Dispatcher.dispatch({ contentType: "GENERIC_RELOAD_PARENT" })
        navigate("/admin/users")
    }

    const apiCreateUser = evt => {
        const query = {
            ...currentUser,
            provider: type,
            roles: isAdmin ? ["ADMIN", "CONFIGURATOR"] : ["CONFIGURATOR"]
        }
        evt.preventDefault()

        if (query.provider === "GOOGLE") {
            delete query.password
        }
        postUser(query)
            .then(returnAndUpdate)
            .catch(unwrapErrorFromApi)

    }

    return <>
        <Paper className={"rightDrawer"}>
            <Typography variant={"h5"}>New user</Typography>
            <br />
            <form onSubmit={apiCreateUser}>
                <div className={"sizeloadedBtns"}>
                    <Button variant={"contained"} type={"submit"} onClick={apiCreateUser}>
                        Save
                    </Button>
                    <Link to={"/admin/users"} className={"noa"}>
                        <Button variant={"outlined"} color="primary">
                            Cancel
                        </Button>
                    </Link>
                </div>
                <br /><br />

                <TextInput label="Email Address *" onChange={setValue("email")}
                           value={currentUser.email || ""} type={"email"} />

                <br /><br />
                <TextInput label="Username *" onChange={setValue("name")}
                           value={currentUser.name || ""} />

                <br />
                <br />
                <FormControl>
                    <FormLabel>User Authentication</FormLabel>

                    <RadioGroup
                        onChange={radioChange}
                        className={"denseGroup"} defaultValue={"LOCAL"}>
                        <FormControlLabel value="GOOGLE" disabled
                                          title={"Google OpenID is unavailable in this version."}
                                          control={<Radio />} label="Google OpenID" />
                        {/*<FormControlLabel value="MICROSOFT"*/}
                        {/*                  control={<Radio/>} label="Microsoft OpenID"/>*/}
                        <FormControlLabel value="LOCAL"
                                          control={<Radio />} label="Local user" />
                    </RadioGroup>
                </FormControl>

                <br /><br />
                <Collapse in={type === "MICROSOFT"}>
                    <p>
                        An an invitation link will be generated for the user.
                    </p>
                </Collapse>

                <Collapse in={type === "GOOGLE"}>
                    {/*<TextInput label="User ID *" onChange={setValue("name")}*/}
                    {/*           value={currentUser.name || ""}/>*/}

                </Collapse>

                <Collapse in={type === "LOCAL"}>
                    <TextInput label="Initial Password *" onChange={setValue("password")}
                               value={currentUser.password || ""} type={"password"} />

                    {/*<p>*/}
                    {/*  The password and activation link will be sent to the user's email address.*/}
                    {/*</p>*/}

                </Collapse>

                <br />

                <Divider />
                <br />
                <FormControlLabel
                    value="end"
                    control={<Checkbox onChange={() => setIsAdmin(state => !state)} checked={isAdmin} />}
                    label="Administrator User"
                />


                <br />
                <br />
                <Divider />

                <p>
                    Accounts can be assigned to the account after it is created.
                </p>
            </form>
        </Paper>

    </>
}
