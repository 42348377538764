import { Grid } from "@mui/material"

export default function Cover({
                                children, title, standalone, table, className
                              }) {
  return <div className={"cover " +
    (table ? "table " : "") +
    (standalone ? "standalone " : "" + className)}>
      {title && <CoverTitle>{title}</CoverTitle>}
      {children}
  </div>
}

function CoverGrid(props) {
  return <Grid item {...props}>
      <Cover children={props.children}
           title={props.title} />
  </Grid>
}

export function CoverHeader({ children }) {
  return <div className={"coverHeader"}>
      {children}
  </div>
}

export function CoverItem({ children }) {
  return <div className={"coverItem"}>
      {children}
  </div>
}

function CoverTitle({ children }) {
  return <h4>
      {children}
  </h4>
}

export { CoverTitle, CoverGrid }
