export class RawEnum {
  /**
   * @description returns an object with key-enum pairs, implementing a regular ENUM class.
   *              The Proxy handler disables the object's default setters and getters.
   *              After constructing an enum instance, it cannot be modified
   * @example new Enum("red","green","blue");
   * @example new Enum(arrayOfLeaders);
   * @param enumArr : [string]|* An array of strings from which the Enums will be constructed.
   * @return {{string}|*} key and the enum's description will be identical
   */
  constructor(enumArr) {
    if (!Array.isArray(enumArr)) {
      enumArr = [...arguments];
    }

    const handler = {
      get(target, name) {
        if (typeof target[name] != "undefined") {
          return target[name];
        }

        // this prevents react component detector from trying to parse this class as a React Component
        if (name === "$$typeof") {
          return false;
        }

        throw new Error(`No such enumerator: ${name}`);
      },
      set() {
        throw new Error("Cannot add/update properties on an Enum instance after it is defined");
      }
    };
    return this.generate(enumArr, handler);
  }

  generate(enumArr, handler) {
    let obj = {};
    enumArr.forEach(element => obj[element] = this.wrapElement(element));
    return Object.freeze(new Proxy(obj, handler));
  }

  wrapElement(element) {
    return element
  }
}

export class IntEnum extends RawEnum {
  /**
   * @description Uses Numbers 0. as enum values
   * @example new Enum("red","green","blue");
   * @return {Readonly<{}>}
   */
  generate(enumArr, handler) {
    let obj = {};
    enumArr.forEach((element, i) => obj[element] = this.wrapElement(i));

    return Object.freeze(new Proxy(obj, handler));
  }
}

export class Enum extends RawEnum {
  wrapElement(element) {
    return Symbol(element);
  }
}
