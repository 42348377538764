// copied from mtr ui

import Slave from "./scui-slave"

const SCUtils = {
  isShown: false,

  Open: (detectionFilter, cameraId, snapshot, getSnapshot, contentType) => {
    if (SCUtils.isShown) return

    SCUtils.isShown = true
    //window.location.href += `&scui=true`;
    document.body.classList.add("scuiShown")

    Slave.openSlaveIFrame(detectionFilter, cameraId, snapshot, getSnapshot, contentType)
  },
  Close: () => {
    document.body.classList.remove("scuiShown")
    SCUtils.isShown = false

  },
  ReceiveData: (payload, filterObjects) => {
    const { config_data, cameraId } = payload
    const copy = copy(window.SCUIWrapperValue)

    filterObjects.forEach(obj => {
      copy[cameraId].filter[obj] = config_data[obj] || []
    })

    return copy
  },
  emptyDetFilter: (sensitivity, alertTypeConfig, filterObjects) => {
    const filter = {
      confidence_sensitivity: sensitivity.default,
      detection_size: {
        min: alertTypeConfig.detection_size.default_min,
        max: alertTypeConfig.detection_size.default_max
      }
    }
    for (let i = 0; i < filterObjects.length; i++) {
      filter[filterObjects[i]] = []
    }
    return filter
  },


  invariant: () => {
    const displayed = document.body.classList.contains("scuiShown")
    const stored = SCUtils.isShown
    const shown = stored
    //Util.parseHashLocation(window.location.href).query?.["scui"]?.length > 0;
    if (displayed && stored && shown) return true
    if (!displayed && !stored && !shown) return false

    console.error("Invariant Violation", { displayed, stored, shown })
    return undefined
  }

}


export default SCUtils
