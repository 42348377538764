import React, {useEffect} from "react";
import {FormControl, Select, InputLabel} from '@mui/material';
import formDataStore from "../FormDataStore";
import Dispatcher from "../../dispatcher";
import Constant from "../../constant";

export default function EnumSelectOnlyOne({isRequired, id, propertyElement, path, value, onChange = () => {}}) {
  const [selected, setSelected] = React.useState(value || formDataStore.getByPath(path));
  const [errorMessage, setErrorMessage] = React.useState(null)

  useEffect(() => {
    const dispatcher = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.FORM_DATA_UPLOADED || payload.contentType === Constant.FLUX.FORM_DATA_CHANGED) {
        const newVal = formDataStore.getByPath(path);
        if (newVal) {
          setSelected(newVal);
        }
      }
    })

    return () => {
      Dispatcher.unregister(dispatcher);
    };
  }, [])


  const onChange0 = evt => {
    setSelected(evt.target.value);
    onChange(evt.target.value, false);

    try {
      setErrorMessage(null);
      formDataStore.removeError(path);
      formDataStore.saveToPath(path, evt.target.value);
    } catch (err) {
      formDataStore.addError(path, err.message)
      setErrorMessage(err.message);
    }
  }

  return (
    <React.Fragment>
      <FormControl component="fieldset" variant="outlined" fullWidth>
        <InputLabel>{propertyElement.description}</InputLabel>
        <Select
          value={selected}
          onChange={onChange0}
          native
          label={propertyElement.description}
        >
          {
            propertyElement.enum.map(el =>
              <option key={el} value={el}>{el}</option>
            )
          }
        </Select>
      </FormControl>

    </React.Fragment>
  )
}
