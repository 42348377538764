const HITBOX_MARGIN_PX = 40

export default class Shape {

  constructor(defaultPercentage, canvas, size, settings, imageWidth, imageHeight, showHorizontalHints) {
    this.defaultXPercentage = defaultPercentage.x;
    this.defaultYPercentage = defaultPercentage.y;
    this.canvas = canvas;
    this.x = null; // local coordinates
    this.y = null; // local coordinates
    this.size = size; // global coordinates
    this.settings = settings;
    this.imageWidth = imageWidth;
    this.imageHeight = imageHeight;
    this.showHorizontalHints = showHorizontalHints;
  }

  initializePosition() {
    this.x = this.defaultXPercentage * this.canvas.current.width * 0.01;
    this.y = this.defaultYPercentage * this.canvas.current.height * 0.01;
  }

  toLocal(global) {
    const ratio = this.canvas.current.width / this.imageWidth;
    return global * ratio;
  }

  toGlobal(local) {
    const ratio = this.canvas.current.width / this.imageWidth;
    return local / ratio;
  }


  draw() {

    const canvasElem = this.canvas.current;
    let ctx = canvasElem.getContext("2d");
    const localSize = this.toLocal(this.size);
    const localSizeHalf = localSize / 2;
    const strokeSize = 1;
    const strokeStyle = "rgba(0,0,0,0.7)"
    const {x, y} = this;
    const arrowSize = Math.min(20, localSize * 0.3)

    // drawing outline
    ctx.fillStyle = strokeStyle;
    ctx.beginPath();
    ctx.moveTo(x, y - localSizeHalf - strokeSize);
    ctx.lineTo(x + 0.3 * arrowSize + 2 * strokeSize, y - localSizeHalf + arrowSize + strokeSize);
    ctx.lineTo(x - 0.3 * arrowSize - 2 * strokeSize, y - localSizeHalf + arrowSize + strokeSize);
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(x, y + localSizeHalf + strokeSize);
    ctx.lineTo(x + 0.3 * arrowSize + 2 * strokeSize, y + localSizeHalf - arrowSize - strokeSize);
    ctx.lineTo(x - 0.3 * arrowSize - 2 * strokeSize, y + localSizeHalf - arrowSize - strokeSize);
    ctx.fill();

    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = 2 + 2 * strokeSize;

    ctx.beginPath();
    ctx.moveTo(x - this.settings.width - strokeSize, y - localSizeHalf);
    ctx.lineTo(x + this.settings.width + strokeSize, y - localSizeHalf);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x - this.settings.width - strokeSize, y + localSizeHalf);
    ctx.lineTo(x + this.settings.width + strokeSize, y + localSizeHalf);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x, y - localSizeHalf);
    ctx.lineTo(x, y + localSizeHalf);
    ctx.stroke();


    // drawing out inside colored
    ctx.strokeStyle = this.settings.color;
    ctx.lineWidth = 2;

    ctx.beginPath();
    ctx.moveTo(x - this.settings.width, y - localSizeHalf);
    ctx.lineTo(x + this.settings.width, y - localSizeHalf);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x - this.settings.width, y + localSizeHalf);
    ctx.lineTo(x + this.settings.width, y + localSizeHalf);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(this.x, this.y - localSizeHalf);
    ctx.lineTo(this.x, this.y + localSizeHalf);
    ctx.stroke();

    ctx.beginPath();
    ctx.fillStyle = this.settings.color;
    ctx.moveTo(this.x, this.y - localSizeHalf);
    ctx.lineTo(this.x + 0.3 * arrowSize, this.y - localSizeHalf + arrowSize);
    ctx.lineTo(this.x - 0.3 * arrowSize, this.y - localSizeHalf + arrowSize);
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(this.x, this.y + localSizeHalf);
    ctx.lineTo(this.x + 0.3 * arrowSize, this.y + localSizeHalf - arrowSize);
    ctx.lineTo(this.x - 0.3 * arrowSize, this.y + localSizeHalf - arrowSize);
    ctx.fill();

    if (this.showHorizontalHints) {
      ctx = canvasElem.getContext("2d");
      ctx.fillStyle = strokeStyle;
      ctx.strokeStyle = strokeStyle;
      ctx.lineWidth = 1;

      ctx.beginPath();
      ctx.moveTo(x - strokeSize, y + localSizeHalf);
      ctx.lineTo(x + arrowSize + strokeSize, y + 0.3 * arrowSize + 2 * strokeSize + localSizeHalf);
      ctx.lineTo(x + arrowSize + strokeSize, y - 0.3 * arrowSize - 2 * strokeSize + localSizeHalf);
      ctx.fill();

      ctx.beginPath();
      ctx.moveTo(x + localSize + strokeSize, y + localSizeHalf);
      ctx.lineTo(x - arrowSize - strokeSize + localSize, y + 0.3 * arrowSize + 2 * strokeSize + localSizeHalf);
      ctx.lineTo(x - arrowSize - strokeSize + localSize, y - 0.3 * arrowSize - 2 * strokeSize + localSizeHalf);
      ctx.fill();

      ctx.strokeStyle = strokeStyle;
      ctx.lineWidth = 2 + 2 * strokeSize;

      ctx.beginPath();
      ctx.moveTo(x, y - strokeSize + localSizeHalf);
      ctx.lineTo(x, y + this.settings.width + strokeSize + localSizeHalf);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(x + localSize, y - this.settings.width - strokeSize + localSizeHalf);
      ctx.lineTo(x + localSize, y + this.settings.width + strokeSize + localSizeHalf);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(x, y + localSizeHalf);
      ctx.lineTo(x + localSize, y + localSizeHalf);
      ctx.stroke();

      // drawing out inside colored
      ctx.strokeStyle = this.settings.color;
      ctx.lineWidth = 2;

      ctx.beginPath();
      ctx.moveTo(x, y + localSizeHalf);
      ctx.lineTo(x, y + localSizeHalf + this.settings.width);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(x + localSize, y + localSizeHalf - this.settings.width);
      ctx.lineTo(x + localSize, y + localSizeHalf + this.settings.width);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(this.x, this.y + localSizeHalf);
      ctx.lineTo(this.x + localSize, this.y + localSizeHalf);
      ctx.stroke();


      ctx.fillStyle = this.settings.color;
      ctx.beginPath();
      ctx.moveTo(x, y + localSizeHalf);
      ctx.lineTo(x + arrowSize, y + 0.3 * arrowSize + localSizeHalf);
      ctx.lineTo(x + arrowSize, y - 0.3 * arrowSize + localSizeHalf);
      ctx.fill();

      ctx.beginPath();
      ctx.moveTo(x + localSize, y + localSizeHalf);
      ctx.lineTo(x - arrowSize + localSize, y + 0.3 * arrowSize + localSizeHalf);
      ctx.lineTo(x - arrowSize + localSize, y - 0.3 * arrowSize + localSizeHalf);
      ctx.fill();

    }

    //drawing out texts
    ctx.font = '16px sans-serif';
    ctx.fillStyle = this.settings.color;
    ctx.strokeStyle = strokeStyle;
    ctx.strokeText(this.settings.label, this.x + 10, this.y - localSizeHalf + 15);
    ctx.fillText(this.settings.label, this.x + 10, this.y - localSizeHalf + 15);

  }

  isInHitbox = (x, y) => {
    const localSize = this.toLocal(this.size);
    if (x < this.x - localSize * 0.2 - HITBOX_MARGIN_PX) {
      return false;
    }
    if (y < this.y - localSize * 0.2 - HITBOX_MARGIN_PX) {
      return false;
    }
    if (x > this.x + localSize + HITBOX_MARGIN_PX) {
      return false;
    }
    if (y > this.y + localSize + HITBOX_MARGIN_PX) {
      return false;
    }

    return true;
  }

  static get POS_PERCENTAGE() {
    return {
      min: {x: 20, y: 50},
      max: {x: 50, y: 50}
    }
  }

  static get CONFIG() {
    return {
      min: {
            color: "#00ffff",
        width: 12,
        label: "min"
      },
      max: {
        color: "#ff5a21",
        width: 25,
        label: "max"
      }
    }
  }

  static get CANVAS_DIM() {
    return {
      width: 0.6 * Math.max(window.innerWidth, window.innerHeight),
      height: 0.6 * Math.min(window.innerWidth, window.innerHeight)
    }
  }
}
