import {
  Paper,
  Table,
  TableBody,
  TableCell,
  Alert,
  TableContainer,
  TableRow,
  Button
} from "@mui/material"
import store from "../store"
import { Build } from "@mui/icons-material"
import { Navigate, useNavigate, useRouteLoaderData } from "react-router-dom"
import Security from "../component/Security"
import WrappedLink from "../component/WrappedLink"
import Enums from "../enums"
import Cover from "../component/Cover"

export default function AccountList() {

  const accounts = useRouteLoaderData("accounts")
  const navigate = useNavigate()


  if (accounts.length === 1) {
    return <Navigate to={`./${accounts[0].id}/sites`} replace />
  }
  console.log(accounts, accounts.length, "<<")

  return <>

      <h3 className={"noTopPad"}>Accounts</h3>
      <br /><br />
      {accounts?.length ?
          <Cover standalone table>
              <TableContainer>
                  <Table>
                      <TableBody>
                          {accounts.map(acc => (
                              <TableRow key={acc.id} className={"pointer hoverColor"}
                          onClick={() => navigate(`./${acc.id}/sites`)}>
                                  <TableCell component="td" scope="row">
                                      <span className={"limitWidth"}>
                                          Account {acc.name}
                                      </span>
                                  </TableCell>
                                  <TableCell className={"alignRight"}>
                                      <Button className={"action floatLeft"} variant={"outlined"} disableElevation
                            onClick={evt => {
                              navigate(`./${acc.id}`)
                              evt.preventDefault()
                              evt.stopPropagation()
                            }}
                    >
                                          <Build />&nbsp;Account Details
                                      </Button>
                                  </TableCell>
                              </TableRow>
              ))}
                      </TableBody>
                  </Table>
              </TableContainer>
          </Cover>
      : accounts?.length === 0 ?
          <div>
            <Alert variant={"filled"} className={"shortAlert"} severity={"warning"}>
              No accounts are assigned to this user
            </Alert>
            <br />
            <Security role={Enums.ROLES.ADMIN}>
              <WrappedLink to={`/admin/users/${store?.user.id}/link`} admin>
                Edit user association
              </WrappedLink>
            </Security>
            <br />
          </div>
        :
          <div>
            <Alert variant={"filled"} className={"shortAlert"} severity={"error"}>Could not accounts.</Alert>
            <br />
          </div>
    }
      <br />
      <Security roleNot={Enums.ROLES.ADMIN}>
          <Paper className={"normalPaper"}>
              Accounts can be created and modified by administrators.
          </Paper>
      </Security>
  </>
}
