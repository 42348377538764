import Header from "./component/Header"
import { Outlet } from "react-router-dom"
import { Box } from "@mui/material"
import Toolbar from "./Toolbar"

export default function HeaderContainer() {
  return <>
    <Header next />
    <div className={"rootWrapper"} id={"UserspaceRoot"}>
      <Box>

        <Toolbar />
        <Outlet />
      </Box>
    </div>
  </>
}
