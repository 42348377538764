import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 TODO: THIS IS JUST A COPY OF U-ALARM-s version of this component. DO NOT EDIT THIS
 */

export default function DuotoneIcon({icon}) {
  const style = {
    "--fa-primary-color": "var(--primary)",
    "--fa-secondary-color": "var(--u-black-lighter)",
    "--fa-primary-opacity": 1,
    "--fa-secondary-opacity": 1
  };
  return (
    <div className="App">
      <FontAwesomeIcon icon={icon} style={style} />
    </div>
  );
}
