import React from "react";
import {v4} from 'uuid';
import String from "../fields/String";
import Util from "../../util";

export default function TechnicalName({path, propertyElement}) {
  const copy = Util.deepCopy(propertyElement);
  copy.default = v4();

  return <String path={path} isRequired={true} propertyElement={copy}/>
}
