import { NEW_API_URL } from "./constant"
import axios from "axios"
import { RawEnum } from "jsutil"
import { sanitiseNulls } from "./newApiClient"

const _CONFIG = { withCredentials: true }
const _PROVIDERS = new RawEnum("GOOGLE", "MICROSOFT", "LOCAL")
const axiosInst = axios.create({
    baseURL: NEW_API_URL,
    withCredentials: true
})

export async function getUsers() {
    const res = await axiosInst.get(`users`)
    return res.data
}

export async function postUser(userData) {
    const res = await axiosInst.post(`users`, sanitiseNulls(userData))
    return res.data
}

export async function deleteUser(userID) {
    const res = await axiosInst.delete(`users/${userID}`)
    return res.data
}

export async function getAccounts() {
    const res = await axiosInst.get("accounts")
    return res.data
}

export async function deleteAccount(accountId) {
    const res = await axiosInst.delete(`accounts/${accountId}`)
    return res.data
}

export async function postAccount(newData) {
    const res = await axiosInst.post("accounts", newData)
    return res.data
}

export async function putAccount(accountId, newData) {
    const res = await axiosInst.put(`accounts/${accountId}`, newData)
    return res.data
}

export async function getUsersInAccount(accountId) {
    const res = await axiosInst.get(`accounts/${accountId}/users`)
    return res.data
}

export async function putUsersInAccount(accountId, data) {
    const res = await axiosInst.put(`accounts/${accountId}/users`, data)
    return res.data
}

//

export async function getAccountsInUser(userId) {
    const res = await axiosInst.get(`users/${userId}/accounts`)
    return res.data
}

export async function putAccountsInUser(userId, data) {
    const res = await axiosInst.put(`users/${userId}/accounts`, data)
    return res.data
}

export async function patchPw(userId, newPw) {
    const res = await axiosInst.patch(`users/${userId}`, newPw)
    return res.data
}

export async function modifyUser(userId, newData) {
    const res = await axiosInst.put(`users/${userId}`, newData)
    return res.data
}

/**
 * LICENCE
 */

export async function getHwId() {
    const res = await axiosInst.get("licence/hardware_id")
    return res.data
}

export async function getLicence() {
    const res = await axiosInst.get("licence")
    return res.data
}

export async function putLicence(licenceText) {
    const res = await axiosInst.put("licence", JSON.parse(licenceText))
    return res.data
}

/**
 * SMTP
 */

export async function getSmtpConfiguration() {
    const res = await axiosInst.get("smtp_configuration")
    return res.status === 200 ? res.data : null
}

export async function putSmtpConfiguration(smtpConfiguration) {
    const res = await axiosInst.put("smtp_configuration", smtpConfiguration)
    return res.data
}

export async function deleteSmtpConfiguration() {
    const res = await axiosInst.delete("smtp_configuration")
    return res.data
}

export async function testGlobalSmtpConfiguration(recipients) {
    const res = await axiosInst.post("smtp_configuration/test", { recipients })
    return res.data
}

export async function toggleNegativeEvent(siteId, newValue) {
  const res = await axiosInst.patch(`sites/${siteId}`, { allow_forward_negative_event: newValue })
  return res.data
}

export async function getNegativeEventSites() {
  const res = await axiosInst.get(`sites/negative_event_forwarding_allowed`)
  return res.data
}


