import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { getId } from "../newApiClient"

export default function AutoId(props) {
  const [theId, setTheId] = useState(-1)
  const className = props.className + " textInput"

  useEffect(() => {
      if (!props.value) {

        getId(props.category || "uncategorised")
          .then(res => {
            setTheId(props.category + "_" + res)
            props?.onChange({ target: { value: props.category + "_" + res } })
          })
          .catch(e => {
            console.log(e)
            setTheId("unresolved_" + Math.random())
            props?.onChange({ target: { value: "unresolved_" + Math.random() } })
          })
      }
    }
    ,
    []
  )


  const rippleOnChange = (evt) => {
    props?.onChange(evt)
    setTheId(-2)
  }
  if (theId === -1 && !props.value) {
    return <div className={"autoIdLoad"}>
      <TextField    {...props} variant="outlined" disabled value={"Loading ID's"} />
    </div>
  }
  return <TextField variant="outlined"
                    {...props}
                    onChange={rippleOnChange}
                    value={props.value}
                    InputProps={{
                      endAdornment: <span className={"autogen"}>
                          {
                            theId < 0 ? "" : "GENERATED"
                          }
                      </span>
                    }}
                    className={className} />
}
