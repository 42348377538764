import React from 'react';
import SchemaTransform from "../SchemaTransformer";
import CommonTask from "../components/CommonTask";
import FormRoot from "../form/FormRoot";
import Util from "../util";
import Task from "./Task";
import {CtaButton} from "./temporary/CtaButton";
import Constant from '../constant'

class NewTask extends Task {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isSubmitting: false,
      name: 'Unnamed',
      scheduleId: "",
      isActive: true,
      validationErrors: [],
      triedToSubmit: false,
    };
    this.calculationTimeout = null;
  }

  componentWillUnmount() {
    if (this.calculationTimeout !== null) {
      clearTimeout(this.calculationTimeout);
      this.calculationTimeout = null;
    }
  }

  formHeaderChanged = () => true;

  MainTitle = () => `New`;

  SecondaryTitle = () => this.props.type[1];

  DeleteButton = () => null;

  CancelButton = () => <CtaButton
    className={"leftMargin-20"}
    onClick={() => {
      this.props.onCancel();
      this.context.mtrApiClient.calculateResource();
    }
    }
    disabled={this.state.isSubmitting}
  >
    Cancel
  </CtaButton>

  SaveButton = () => <CommonTask.SaveButton newTask={"true"}
                                            isSubmitting={this.state.isSubmitting}

  />

  getTaskSchema = () => {
    const alertType = this.props.type[0];
    return this.context.store.schemas[alertType]
  }

  Form = ({children}) => {
    const widgets = CommonTask.widgets;
    const alertType = this.props.type[0];

    const onChange = ({formData}) => {
      this.setState({hasChanged: true, formData: formData});

      if (this.calculationTimeout !== null) {
        clearTimeout(this.calculationTimeout);
        this.calculationTimeout = null;
      }

      this.calculationTimeout = setTimeout(() => {
        this.context.mtrApiClient.calculateResource(null, {
          active: this.state.isActive,
          display_name: this.state.name,
          type: alertType,
          schedule_id: this.state.scheduleId,
          properties: SchemaTransform.domainTypeStrToJSON(formData)
        });
      }, Constant.CALCULATION_TIMEOUT_MS)
    };

    const onFailedSubmit = () => {
      this.setState({triedToSubmit: true})
    }

    const onSubmit = ({formData}, type) => {
      const parsedFormData = SchemaTransform.domainTypeStrToJSON(formData);
      const updatedFormData = {...formData};
      this.setState(
{
        formData: updatedFormData,
        isSubmitting: true,
        error: null
      });
      CommonTask.scrollToTop();
      this.context.mtrApiClient.postTask(parsedFormData, this.state.name, this.state.isActive, alertType, this.state.scheduleId)
        .then(() => {
        this.context.mtrApiClient.calculateResource();
          this.setState({isSubmitting: false});
          this.props.onSubmit();
        }
      ).catch(e => {
          console.log(e);
          this.setState({
            error: Util.unwrapErrorMessage(e),
            isSubmitting: false
          });
        }
      );
    };

    return (
      <FormRoot schema={this.getTaskSchema()}
                alertType={this.props.type[0]}
                widgets={widgets}
                idPrefix={"MTR_form_prefix"}
                formData={this.state.formData}
                onChange={onChange}
                onSubmit={onSubmit}
                onFailedSubmit={onFailedSubmit}
                validate={Util.validateAlertForm}
                validationErrors={this.state.validationErrors}
                displayName={this.state.name}
                setValidationErrors={val => this.setState({validationErrors: val})}
      >
        {children}
      </FormRoot>
    )
  }

}

export default NewTask;
