import React from 'react';
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome";
import Util from '../util';
import SchemaTransform from "../SchemaTransformer";
import Task from "./Task";
import CommonTask from "../components/CommonTask";
import FormRoot from "../form/FormRoot";
import Dispatcher from "../dispatcher";
import Constant from "../constant";
import {CtaButton} from "./temporary/CtaButton";

class EditTask extends Task {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isDeleting: false,
      validationErrors: []
    };

    this.calculationTimeout = null;
  }

  componentWillUnmount() {
    if (this.calculationTimeout !== null) {
      clearTimeout(this.calculationTimeout);
      this.calculationTimeout = null;
    }
  }

  componentDidMount() {
    this.updateInfo();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.id !== prevProps.id || JSON.stringify(this.props.taskInfo) !== JSON.stringify(prevProps.taskInfo)) {
      this.updateInfo();
    }
  }

  updateInfo = async () => {
    const details = await this.context.mtrApiClient.getTaskDetails(this.props.id);
    const formData = Util.deepCopy(details.properties)
    if (formData?.input) {
      formData.input = [formData.input]
    }

    this.setState({
      name: details.display_name,
      isActive: details.active,
      scheduleId: details.schedule_id || "",
      hasChanged: false,
      formData
    });
  }

  revert = () => {
    this.props.setMessage("");
    this.setState({error: false});
    this.props.onChange(false);
    this.updateInfo();
  };

  deleteTask = async () => {
    const {taskAlias, store, mtrApiClient} = this.context;
    const confirmed = window.confirm(`Are you sure you want to delete this ${taskAlias}? This action cannot be undone`);

    if (confirmed) {
      this.setState({isDeleting: true})
      try {
        await mtrApiClient.deleteTask(this.props.id)
        store.tasks = store.tasks.filter(task => task.id !== this.props.id);

        Dispatcher.dispatch({
          actionType: Constant.FLUX.SOLUTIONS_CHANGED,
          deletedTaskId: this.props.id
        })

        this.setState({isDeleting: false})
        this.props.onChange(false);
        this.props.onDelete();

        this.props.setMessage(`${this.props.taskInfo.type_display_name} and its associated Events are deleted successfully.`);
      } catch (e) {
        this.setState({
          isDeleting: false,
          error: Util.unwrapErrorMessage(e)
        })
      }

    }
  };

  formHeaderChanged = () => {
    this.props.onChange(true);
    this.setState({hasChanged: true});
  }

  MainTitle = () => this.props.taskInfo.display_name;

  SecondaryTitle = () => this.props.taskInfo.type_display_name;

  DeleteButton = () => <CtaButton
    title={"Delete Task"}
    className={"float-rightButton"}
    onClick={async () => {
      await this.deleteTask();
      await this.context.mtrApiClient.calculateResource();
    }
    }
    disabled={this.state.isSubmitting || this.state.isDeleting}>
    <Icon icon={'trash'} className={"rightMargin-10"}/>
    {this.state.isDeleting ? 'Deleting...' : 'Delete'}
  </CtaButton>

  CancelButton = () => <CtaButton
    className={"leftMargin-10"}
    disabled={!this.state.hasChanged}
    title={!this.state.hasChanged && "No changes were made to the Alarm."}
    onClick={() => {
      this.revert()
      this.context.mtrApiClient.calculateResource();
    }
    }>
    Revert
  </CtaButton>

  SaveButton = () => <CommonTask.SaveButton hasChanged={this.state.hasChanged}
                                            disabled={this.state.validationErrors.length}
                                            isSubmitting={this.state.isSubmitting}/>

  getTaskSchema = () => this.context.store.schemas[this.props.taskInfo.type];

  Form = ({children}) => {
    const alertType = this.props.taskInfo.type
    const widgets = CommonTask.widgets;

    const onChange = ({formData}) => {
      this.props.onChange(true);
      this.setState({
        hasChanged: true,
        formData: formData
      });

      if (this.calculationTimeout !== null) {
        clearTimeout(this.calculationTimeout);
        this.calculationTimeout = null;
      }

      this.calculationTimeout = setTimeout(() => {
        this.context.mtrApiClient.calculateResource(this.props.id, {
          active: this.state.isActive,
          display_name: this.state.name,
          type: this.props.taskInfo.type,
          schedule_id: this.state.scheduleId,
          properties: SchemaTransform.domainTypeStrToJSON(formData)
        });
      }, Constant.CALCULATION_TIMEOUT_MS)


    };

    const onFailedSubmit = () => {
      this.setState({triedToSubmit: true})
    }

    const onSubmit = ({formData}, type) => {
      this.props.setMessage();
      const parsedFormData = SchemaTransform.domainTypeStrToJSON(formData);

      CommonTask.scrollToTop();

      this.setState({
        isSubmitting: true,
        error: null
      });
      this.context.mtrApiClient.putTask(
        this.props.id,
        parsedFormData,
        this.state.name,
        this.state.isActive,
        this.props.taskInfo.type,
        this.state.scheduleId
      ).then(() => {
        this.context.mtrApiClient.calculateResource();
          this.setState({isSubmitting: false, hasChanged: false});
          this.props.onChange(false);
          this.props.setMessage("Alarm successfully modified.");
        }
      ).catch(e => {
          console.error(e)
          this.setState({error: Util.unwrapErrorMessage(e), isSubmitting: false, hasChanged: true});
        }
      );
    };

    return (
      <FormRoot schema={this.getTaskSchema()}
                widgets={widgets}
                alertType={alertType}
                idPrefix={"MTR_form_prefix"}
                formData={this.state.formData}
                onChange={onChange}
                onSubmit={onSubmit}
                onFailedSubmit={onFailedSubmit}
                validate={Util.validateAlertForm}
                validationErrors={this.state.validationErrors}
                setValidationErrors={val => this.setState({validationErrors: val})}
                taskId={this.props.id}
                displayName={this.state.name}
      >
        {children}
      </FormRoot>
    )
  }

}

export default EditTask;
