export default class MtrApiClient {
  constructor(apiUrl, store) {
    this.apiUrl = apiUrl;
    this.store = store;
    console.log('[MTR UI] Mock Client created');
  }

  getCameraListByTaskTypes() {
    return [];
  }

  testIntegration(content) {
  }
}
