import React, {useState, useEffect} from "react";
import {ButtonGroup, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select,
  ImageList, ImageListItem, ImageListItemBar} from '@mui/material';
import {CtaButton} from "../ui/temporary/CtaButton";
import Dispatcher from "../dispatcher";
import Constant from "../constant";

export default function AddCamera({addCamera, activeCameras, alreadyAddedCameras, allowMultipleCamera,
                                    allowDuplicatedCamera, store}) {
  const DUMMY_THUMBNAIL = "./dummy.png"
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(activeCameras?.length ? activeCameras[0].id : null);
  const [selectedCamera, setSelectedCamera] = useState(activeCameras?.length ? activeCameras[0] : null);
  const [oldIdx, setOldIdx] = useState(null);

  const getAllowedCameras = () => {
    if (!allowMultipleCamera && alreadyAddedCameras.length > 0 && oldIdx === null) {
      return [];
    }
    return activeCameras;
  }

  const [allowedCameras, setAllowedCameras] = useState(getAllowedCameras())

  useEffect(() => {
    const d = Dispatcher.register(payload => {
      if (payload.contentType === Constant.FLUX.CHANGE_CAMERA) {
        if (payload.oldCamera) {
          setSelectedCamera(payload.oldCamera);
          setSelected(payload.oldCamera.id);
        }
        setOldIdx(payload.oldIdx);
        handleClickOpen();
      }
    });
    return () => Dispatcher.unregister(d);
  }, [])

  useEffect(() => {
    setAllowedCameras(getAllowedCameras())
  }, [alreadyAddedCameras, oldIdx])

  useEffect(() => {
    if (allowedCameras?.length) {
      setSelected(allowedCameras[0].id)
    }
  }, [allowedCameras])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOldIdx(null);
  };

  const add = () => {
    addCamera(selected, oldIdx);
    handleClose();
  }

  if (!allowedCameras || allowedCameras.length === 0) {
    return null;
  }

  const getCameraImageSrc = elem => {
    if (elem && store.thumbnails && store.thumbnails[elem.id]) {
      return `data:image/png;base64,${store.thumbnails[elem.id]}`
    }
    return DUMMY_THUMBNAIL;
  }

  const selectCamera = cam => {
    setSelected(cam);
    setSelectedCamera(activeCameras.filter(elem => elem.id === cam)[0])
  }

  const getTitle = () => {
    if (oldIdx === null) {
      return "Add a new camera to the solution"
    } else {
      return "Change the camera of the solution"
    }
  }

  return (
    <div className={"cameraListContainer"}>

      <CtaButton onClick={handleClickOpen} dataTestId={"NewSolutionAddCameraButton"}>Add Camera</CtaButton>

      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={"largeDialog"}
              maxWidth={"md"} fullWidth={true}>
        <DialogTitle id="simple-dialog-title">{getTitle()}</DialogTitle>
        <DialogContent>

          <FormControl component="fieldset" variant="outlined" fullWidth>
            <InputLabel>Select camera</InputLabel>
            <Select
              value={selected}
              onChange={evt => selectCamera(evt.target.value)}
              displayEmpty
              label={"Select camera"}
            >
              {
                allowedCameras.map(el =>
                  <MenuItem key={el.id} value={el.id}>{el.display_name}</MenuItem>
                )
              }
            </Select>
          </FormControl>

          <br/><br/>

          <img src={getCameraImageSrc(selectedCamera)} className={"roiImage"} alt="Camera Thumbnail" width={"320px"}/>

          <br/><br/>

          <div className={"imageListWrapper"}>
            <ImageList className={"imageList"} cols={5} rowHeight={110}>
              {activeCameras.map((item) => (
                <ImageListItem className={"pointer"} key={item.id} onClick={() => selectCamera(item.id)}>
                  <img src={getCameraImageSrc(item)} alt={item.display_name} />
                  <ImageListItemBar title={item.display_name} className={"imageListElemTitle"}/>
                </ImageListItem>
              ))}
            </ImageList>
          </div>

          <br/>

          {(oldIdx !== null) && <React.Fragment>
            <p>If the camera is changed, current settings will be lost.</p>
            <br/>
          </React.Fragment>}

          <ButtonGroup variant={"contained"} disableElevation>
            <CtaButton primary onClick={add} dataTestId={"NewSolutionAddCameraAddButton"}>
              {oldIdx === null ? "Add" : "Change"}</CtaButton>
            <CtaButton onClick={handleClose}>Cancel</CtaButton>
          </ButtonGroup>

          <br/><br/>
        </DialogContent>
      </Dialog>

      <br/><br/>
    </div>
  )
}
