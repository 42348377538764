import Page from "../../component/Page"
import Cover, { CoverGrid } from "../../component/Cover"
import { useEffect, useState } from "react"
import { getHwId, getLicence, putLicence } from "../../adminApiClient"
import WrappedLink from "../../component/WrappedLink"
import { Link } from "@mui/icons-material"
import { Alert, Button, Grid, LinearProgress, TextField } from "@mui/material"
import unwrapErrorFromApi from "../../component/UnwrapErrorApi"
import { confirmDialog } from "../../component/temp/ConfirmDialog"
import Security from "../../component/Security"

export default function Licence() {
    const [hwId, setHwId] = useState(false)
    const [licenceDetails, setLicenceDetails] = useState(false)
    const [licenceText, setLicenceText] = useState("")
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        getHwId()
            .then(res => setHwId(res))
        getLicence()
            .then(res => setLicenceDetails(res))
    }

    const addLicence = () => {
        putLicence(licenceText)
            .then(() =>
                confirmDialog({
                    title: "Licence Added",
                    message: "Licence successfully activated",
                    noCancel: true
                })
            )
            .catch(unwrapErrorFromApi)
            .finally(fetchData)

    }

    if (!licenceDetails && !hwId) {
        return <div className={"progressBar"}><LinearProgress /></div>
    }
    return <><Page title={"Licence"}>
        <br />

        <Grid container className={"pageContainer"}>

            <Grid item xs={6}>
                {
                    licenceDetails.licence_valid ?
                        <Alert severity={"success"}
                               className={"alignGridItem"}
                               variant={"filled"}
                        >Licence active and valid</Alert> :
                        <Alert
                            severity={"error"}
                            className={"alignGridItem"}
                            variant={"filled"}
                        >
                            Licence is not activated, invalid or expired. Check validation description for more details.
                        </Alert>
                }
                <Cover title={"Licence Data (Hardware Key)"}>
                    <pre>
                        {hwId}
                    </pre>
                    <br />

                    <WrappedLink
                        icon={<Link />}
                        target={"_blank"}
                        to={"https://activation.ultinous.com"}
                        dark admin>
                        Get Licence File
                    </WrappedLink>

                    <Security dev>
                        <br />
                        <br />
                        <WrappedLink
                            icon={<Link />}
                            target={"_blank"}
                            to={"https://activation.dev.ultinous.com"}
                            dark admin>
                            Get File (Dev ULPS)
                        </WrappedLink>
                    </Security>

                </Cover>
            </Grid>
            <CoverGrid xs={6} title={"Activate Licence"}>
                <TextField
                    onChange={(evt) => setLicenceText(evt.target.value)}
                    value={licenceText}
                    label={"Contents of licence file"}
                    className={"fullWidth"}
                />
                <br />
                <br />
                <Button variant={"contained"}
                        onClick={addLicence}
                        disabled={!licenceText}
                        className={"wide"} color={"primary"}>
                    Activate
                </Button>
            </CoverGrid>

            <CoverGrid lg={6} md={8} title={"Licence Details"}>
                <table className={"minimalTable"}>
                    <tbody>
                    {
                        Object.keys(licenceDetails)
                            .map(key => {
                                return <tr key={key}>
                                    <td>{key.replace(/_/g, " ")}</td>
                                    <td>{
                                        renderCell(licenceDetails[key]) || "Nothing"}</td>
                                </tr>
                            })
                    }
                    </tbody>
                </table>
                <br />

            </CoverGrid>

        </Grid>
    </Page>
    </>
}

const renderCell = (content) => {
    if (content === true) return "Yes"
    return content
}
