import { Button, Paper } from "@mui/material"
import { Link } from "react-router-dom"
import { OpenInNew } from "@mui/icons-material"

export default function WrappedLink({ children, icon, to, admin, dark, target }) {
  return <span className={"wrappedLink " +
    (dark ? "wrappedLink-dark " : "") +
    (admin ? "wrappedLink-admin " : "")}>
      <Link to={to} target={target}>
          <Paper>
              <Button startIcon={icon || <OpenInNew />}>
                  {children}
              </Button>
          </Paper>
      </Link>
  </span>
}
