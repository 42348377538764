import { Alert, Button, Grid, TextField, Typography } from "@mui/material"
import { Delete, Save } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { copy, getSMTP, unwrapErrorMessage } from "../util"
import { DEFAULT_SIZE_FILTER, TECHNICAL_NAME_PATTERN, TECHNICAL_NAME_REGEX } from "../constant"
import CopyBtn from "./CopyBtn"
import store from "../store"
import Dispatcher from "../dispatcher"
import LatLonInput from "./LatLonInput"
import SensitivityForm from "./SensitivityForm"
import TestInside from "./TestInside"
import AdditionalFilters from "./AdditionalFilters"
import HelpPopup from "./HelpPopup"
import { useNavigate, useParams, useRouteLoaderData, useSearchParams } from "react-router-dom"
import { createChannelToSite, deleteChannel, modifySingleChannel } from "../newApiClient"
import Cover, { CoverHeader, CoverItem } from "./Cover"
import { confirmDialog } from "./temp/ConfirmDialog"
import AutoId from "./AutoId"
import unwrapErrorFromApi from "./UnwrapErrorApi"

export const CHANNEL_DEFAULT_VALUE = {
  detection_config: {
    class_info: {
      car: { sensitivity: "MEDIUM", filter_motionless: false },
      person: { sensitivity: "MEDIUM", filter_motionless: false },
      truck: { sensitivity: "MEDIUM", filter_motionless: false },
      bus: { sensitivity: "OFF", filter_motionless: false },
      motorcycle: { sensitivity: "OFF", filter_motionless: false },
      bicycle: { sensitivity: "OFF", filter_motionless: false },
      train: { sensitivity: "OFF", filter_motionless: false },
      boat: { sensitivity: "OFF", filter_motionless: false },
      airplane: { sensitivity: "OFF", filter_motionless: false }
    },
    rois: {
      positive_areas: [],
      negative_areas: []
    },
    size_filter: {
      min: 32,
      max: 1000
    }
  }
}

export default function Channel({ state, dispatch, isCreating }) {
  const channel = useRouteLoaderData("channel")
  const channelData = isCreating ? CHANNEL_DEFAULT_VALUE : useRouteLoaderData("channel")
  const _site = useRouteLoaderData("site")

  const [waiting, setWaiting] = useState(false)
  const [imageDimFromPreviousUpload, setImageDimFromPreviousUpload] = useState(false)
  const [error, setError] = useState(null)
  //eslint-disable-next-line
  const { accountId, siteId, channelId } = useParams()

  const [currentData, setCurrentData] = useState(copy(channelData))
  const [original, setOriginal] = useState(copy(channelData))
  const [technicalNameError, setTechnicalNameError] = useState(false)

  const navigate = useNavigate()

  let [searchParams, _setSearchParams] = useSearchParams()
  const isNvr = original.nvr_id ?? searchParams.get("nvr") === ""

  const _detsizeMaybeFromPreviousImageUpload = () => {
    if (channelData.detection_config.size) return false
    return imageDimFromPreviousUpload
  }


  useEffect(() => {
    setOriginal(copy(channelData))
  }, [state])

  useEffect(() => {
    if (!isCreating) {
      setCurrentData(copy(original))
    }
  }, [original])

  useEffect(() => {
    setOriginal(copy(channelData))
    const d = Dispatcher.register(payload => {
      if (payload.contentType === "REFRESH_DONE") {
        setOriginal(copy(channelData))
      }
    })
    return () => {
      Dispatcher.unregister(d)
    }
  }, [])

  if (!currentData) {
    return <>no current data</>
  }

  const callDeleteChannel = async () => {
    if (!window.confirm("Selected channel will be deleted permanently.\nPress OK to confirm.")) {
      return
    }
    setWaiting(true)
    try {
      await deleteChannel(channelId)
      await confirmDialog({ title: "Channel Deleted.", noCancel: true })
      navigate("..")
    } catch (err) {
      setError(unwrapErrorMessage(err))
    } finally {
      setWaiting(false)
    }
  }

  const saveOrCreateChannel = async evt => {
    evt.preventDefault()
    setError(null)
    setWaiting(true)
    const request = { ...currentData }
    if (request.position && (request.position.longitude === null || request.position.latitude == null)) {
      delete request.position
    }
    try {
      if (isCreating) {
        await createChannelToSite(siteId, request)
        //navigate(`../${newChannel.id}`); todo: better navigate
        navigate("..")

      } else {
        let markForReload = false

        await modifySingleChannel(channelId, request)
        await confirmDialog({ title: "Changes Saved.", noCancel: true })
        setOriginal(currentData)
        setCurrentData(currentData)
        if (markForReload) {
          window.location.reload() //todo actual version<;
        }

      }
    } catch (err) {
      unwrapErrorFromApi(err)
      setError(unwrapErrorMessage(err))
    } finally {
      setWaiting(false)
    }
  }

  const onChangeDetectionType = (evt) => {
    const { value, name } = evt.target
    const copy = { ...currentData }
    copy.detection_config.class_info[name].sensitivity = value
    setCurrentData(copy)
  }


  const onChangeCB = name => (evt) => {
    const { checked } = evt.target
    const copy = { ...currentData }
    copy.detection_config.class_info[name].filter_motionless = checked
    setCurrentData(copy)
  }

  const onChangeTechnicalName = evt => {
    const lower = evt.target.value.toLowerCase()
    setCurrentData({ ...currentData, tid: lower })
    const isOk = TECHNICAL_NAME_REGEX.test(lower)
    setTechnicalNameError(lower && !isOk)
  }

  const onChangeROIs = newRois => {
    const detConfig = { ...currentData.detection_config, rois: newRois }
    setCurrentData({ ...currentData, detection_config: detConfig })
  }

  const onChangeDetectionSizeFilter = newSize => {

    const detConfig = { ...currentData.detection_config, size_filter: newSize }
    setCurrentData({ ...currentData, detection_config: detConfig })
  }
  const onChangeDim = newDim => {
    const detConfig = { ...currentData.detection_config, size: newDim }
    setCurrentData({ ...currentData, detection_config: detConfig })
  }
  const onChange = prop => evt => {
    setCurrentData(currentData => ({
      ...currentData,
      [prop]: evt.target.value
    }))
  }

  const onBack = () => {
    navigate("..")
  }

  const Title = () => {
    if (isCreating) {
      if (isNvr) {
        return "New NVR channel"
      } else {
        return "New channel"
      }

    } else {
      return `Channel: ${original.name}`
    }
  }
  const forceSaveOriginalForm = async ({height,width, newRois, newSizeFilter}) => {
    original.detection_config.size = {height:height, width:width}
    original.detection_config.size_filter = newSizeFilter ? newSizeFilter : DEFAULT_SIZE_FILTER
    original.detection_config.rois = newRois
    await modifySingleChannel(channelId, original)
    setCurrentData(original)
  }

  return (
    <>

      <Typography variant="h5" sx={{ my: 2 }}>
        <Typography variant={"h3"} className={"smallTitle"}>
          <Title />
        </Typography>

      </Typography>

      <Cover standalone>

        <form onSubmit={saveOrCreateChannel}>
          <CoverHeader>
            <Button className={"formSubmit action"} variant="contained" type={"submit"}>
              <Save /> Save
            </Button>


            <div className={"right"}>
              <HelpPopup title={"Help"} channel={currentData} left={false} />
              {!isCreating && (
                <Button className={"formSubmit action delete icon"} variant="contained"
                        onClick={callDeleteChannel}>
                  <Delete /> Delete
                </Button>
              )}
            </div>
          </CoverHeader>

          <br /> <br />
          <div className={"formHolder"}>
            <Grid container spacing={1}>
              <Grid item sm={12} xs={12}>
                <TextField
                  className="largeForm" type="text" label="Name" required
                  onChange={evt => setCurrentData({ ...currentData, name: evt.target.value })}
                  value={currentData?.name || ""} variant="outlined"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  className="largeForm" type="text" label="External ID"
                  onChange={evt => setCurrentData({ ...currentData, external_id: evt.target.value })}
                  value={currentData?.external_id || ""} variant="outlined"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <AutoId
                  category={"channel"}
                  className={"largeForm " + (!isCreating ? "readonly" : "")}
                  type="text" label="Unique ID (do not use special characters)"
                  required
                  error={technicalNameError}
                  InputProps={{
                    endAdornment: <CopyBtn value={currentData?.tid || ""} />
                  }}
                  inputProps={{
                    pattern: TECHNICAL_NAME_PATTERN,
                    readOnly: !isCreating
                  }}
                  onChange={onChangeTechnicalName}
                  value={currentData?.tid || ""} variant="outlined"
                />
              </Grid>

              {
                isNvr ? <>
                  <Grid item sm={12} xs={12}>
                    <TextField required
                      className="largeForm"
                      label="Target e-mail from NVR"
                      InputProps={{
                        endAdornment: <>@nvr.{store?.mail_server?.mail_server || "?"}</>
                      }}
                      onChange={onChange("nvr_id")}
                      value={currentData.nvr_id} variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField required
                      className="largeForm"
                      type="text"
                      multiline
                      minRows={2}
                      maxRows={4}
                      label="Channel's unique reference in email"
                      onChange={onChange("unique_reference")}
                      value={currentData.unique_reference} variant="outlined"
                    />
                  </Grid>

                </> : <>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      className="largeForm readonly"
                      type="email"
                      label="Target e-mail address (generated from Unique ID)"
                      InputProps={{
                        readOnly: true,
                        endAdornment: <CopyBtn value={getSMTP(currentData?.tid).join("")} />
                      }}
                      value={getSMTP(currentData?.tid).join("")} variant="outlined"
                    />
                  </Grid>
                </>
              }

              <Grid item sm={6} xs={12}>
                <LatLonInput value={currentData.position?.latitude}
                             latOrLon={"LAT"}
                             required={currentData.position?.longitude !== null && currentData.position?.longitude !== undefined}
                             onChange={value => setCurrentData({
                               ...currentData,
                               position: { ...currentData.position, latitude: value }
                             })} />
              </Grid>

              <Grid item sm={6} xs={12}>
                <LatLonInput value={currentData.position?.longitude}
                             latOrLon={"LON"}
                             required={currentData.position?.latitude !== null && currentData.position?.latitude !== undefined}
                             onChange={value => setCurrentData({
                               ...currentData,
                               position: { ...currentData.position, longitude: value }
                             })} />
              </Grid>

              <br />
              <br />
              <div className={"largeForm senform"}>
                <SensitivityForm currentData={currentData}
                                 onChangeDetectionType={onChangeDetectionType}
                                 onChangeCB={onChangeCB}
                />
              </div>
            </Grid>
          </div>

          {!isCreating && <>
            <br />
            <AdditionalFilters channelId={channelId}
                               detectionSize={currentData.detection_config.size_filter}
                               imageSize={currentData.detection_config.size}
                               setDim={onChangeDim}
                               forceSaveOriginalForm={forceSaveOriginalForm}
                               setDetectionSize={onChangeDetectionSizeFilter}
                               setImageDimFromPreviousUpload={setImageDimFromPreviousUpload}
                               setRois={onChangeROIs} rois={currentData.detection_config.rois} />
          </>}
        </form>

        <br />

        {waiting && <div className={""}>Processing...</div>}
        {error && <><Alert severity="error" onClose={() => setError(undefined)}>{error}</Alert><br /></>}

        {!isCreating ? <>
          <br />
          <CoverItem>
            <h4>Send Test event</h4>
            <TestInside state={state} />
          </CoverItem>
          <br />
        </> : <p>
          Further actions will be available once the channel is saved.
        </p>}
        <br />

      </Cover>
      {/*<br/>*/}
      {/*{!isCreating && <ConfigurationHelp channel={currentData} site={site}/>}*/}
      {/*<br/>*/}
      {/*<WrappedLink to={`/acc/${accountId}/sites/${siteId}`}>Edit Site</WrappedLink>*/}
      <br />&nbsp;
      <br />
      <br />
    </>
  )

}
