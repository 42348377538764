import React, {useEffect, useState} from "react";
import NewTask from "./NewTask";
import MtrContext from "../MtrContext";
import {Alert, Chip, Link, Paper, Typography} from "@mui/material";
import ResourceQuota from "../components/ResourceQuota";
import AlertTypeCard from "./AlertTypeCard";
import {Icon} from "./temporary/Icon";

function ProvidedBy() {

  return <Paper id={"functionalities"} className={"padding-15"} variant={"outlined"}>
    <span>Functionality Added by:</span>
    <Chip icon={<Icon path={"alarm"}/>}
          className={"typeIcon"}
          label={"U-Alarm"}
    />

    <Chip icon={<Icon path={"safe"}/>}
          className={"typeIcon"}
          label={"U-Safe"}
    />

  </Paper>;
}


export default function NewAlertView({onChange, onSubmit, setMessage}) {
  const [show, setShow] = useState(false);
  const [type, setType] = useState([]);
  const [submitted, setSubmitted] = useState(null);
  const addName = async (taskType, readableName) => {
    setSubmitted(null);
    onChange(true);
    setShow(true);
    setType([taskType, readableName]);
  };

  useEffect(() => {
    setMessage(null);
  }, []);


  return (
    <MtrContext.Consumer>
      {
        ({taskAlias, store}) => {
          const useBoth = store.Suite.code === "both";

          return <span>
        {
          show ?
            <NewTask
              onCancel={() => {
                setShow(false);
                onChange(false)
              }}

              onSubmit={() => {
                setShow(false);
                setSubmitted(type[1]);
              }}
              type={type}
            />
            :
            <div className={"alertsView"}>

              <br/>
              <Typography variant={"h5"}>Add new or edit existing {taskAlias}.</Typography>
              <ResourceQuota store={store} className={"float-right"}/>
              <br/>
              {
                submitted &&
                <Alert severity={"success"} className={"primaryAlert"} variant={"filled"}
                       onClose={() => setSubmitted(null)} dismissible={"true"} style={{marginTop: '25px'}}>
                  {submitted} successfully saved.
                </Alert>
              }
              {useBoth && <ProvidedBy/>}

              {
                store.categories.map(category => {
                  return (
                    <span key={category.name}>
                      <br/>
                      <Typography variant={"h6"}>{category.name}</Typography>

                      <p>{category.description}</p>
                      <div className={"alertsViewFlex"}>
                        {store.alertTypes
                          .filter(alert => alert.category === category.name)
                          .filter(alert => !(store.Suite.current.hideTasks.includes(alert.type)))
                          .map((type, i) => <AlertTypeCard
                            key={i} currentType={type} taskAlias={taskAlias}
                            useBoth={useBoth}
                            addName={addName}/>)}
                      </div>
                    </span>
                  )
                })
              }
            </div>
        }
            <br/>
            {store.Suite.current.hideTasks.length > 0 && !show &&
              <Alert id={"moreSolutionAvailable"} className={"primaryAlert"} severity="info">
                Some solutions are not shown, because the current Ultinous AI Suite doesn't provide
                that functionality.<br/><br/>
                To use a different set of solutions, please change your&nbsp;
                <Link href={"#/control_panel/Suite"}>
                  Suite
                </Link> in accordance with your Licence.
              </Alert>
            }
        </span>


        }
      }

    </MtrContext.Consumer>
  )

}
