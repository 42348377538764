import { useState } from "react"
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material"
import { InfoOutlined } from "@mui/icons-material"
import ConfigurationHelp from "./ConfigurationHelp"
import { useRouteLoaderData } from "react-router-dom"

export default function HelpPopup({ channel, left }) {
  const [open, setOpen] = useState(false)

  const site = useRouteLoaderData("site")
  const close = () => setOpen(false)

  return <>
      <Button onClick={() => setOpen(true)}
            className={"action noMinWidth icon " + (left ? " left" : "")} variant={"outlined"}
            title={"Help"}>
          <InfoOutlined />
      </Button>
      <Dialog open={open} onClose={close}>
          <DialogContent>
              <ConfigurationHelp channel={channel} site={site} accordion={false} />
          </DialogContent>
          <DialogActions>
              <Button className={"action"} variant={"outlined"} onClick={close}>Close</Button>
          </DialogActions>
      </Dialog>
  </>
}
